import React, { useState, useEffect } from "react";
import ButtonPagenate from "../Component/ButtonPagenate/ButtonPagenate";
import { IoStatsChartOutline } from "react-icons/io5";
import { CSVLink } from "react-csv";
import { FiUpload } from "react-icons/fi";
import { useSelector } from "react-redux";
import { GoArrowLeft } from "react-icons/go";
import { Store } from "../Redux/store";
import { captureTopPerforming } from "../Redux/Actions/buttonClick";
import NumberFormat from "react-number-format";
import currencyUnit from "./Claims/currencyUnit";

const FlownUnflownTicketsequv = ({ flownunflownall }) => {
  const [exportpro, setExportpro] = useState(false);
  const [gobackbut, setGobackbut] = useState(false);
  const [flowndata, setFlowndata] = useState([]);
  const [searchdata, setSearchdata] = useState("");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setFlowndata(flownunflownall);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const csvHeaders = [
    { label: "Id", key: "id" },
    { label: "Country", key: "region" },
    { label: "Flown Ticket", key: "flown_ticket" },
    { label: "Unflown Ticket", key: "unflown_ticket" },
    { label: "Uncaptured Ticket", key: "uncaptured_ticket" },
    { label: "Total Ticket", key: "total_ticket" },
  ];

  var filteredflwondata = flowndata.filter((row) => {
    if (searchdata === "") {
      return row;
    } else {
      // Convert all values to lowercase for case-insensitive search
      const values = Object.values(row).map((value) => {
        // Check if the value is a string before converting to lowercase
        return typeof value === "string" ? value.toLowerCase() : value;
      });
      // Check if any of the values (converted to lowercase) contains the search string
      return values.some((value) => {
        if (typeof value === "string") {
          return value.includes(searchdata.toLowerCase());
        }
        return false; // Ignore non-string values
      });
    }
  });

  const handleHover = () => {
    setExportpro(!exportpro);
  };

  const newPagenum = (num) => {
    setPageNumber(num);
  };

  const handleExportCSV = () => {};

  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerpage = 15; //Number of items to be displayed per page
  const pageCount = Math.ceil(flowndata.length / itemsPerpage);
  const pagesVisited = pageNumber * itemsPerpage;

  const handleCountryClick = () => {};

  const handle_Hover = () => {
    setGobackbut(!gobackbut);
  };

  const handleBackclick = () => {
    Store.dispatch(captureTopPerforming(0));
  };

  const cumulativeUSD = flowndata
    .reduce(
      (accumulator, currentValue) =>
        parseFloat(accumulator) + parseFloat(currentValue.USDequv),
      0
    )
    .toFixed(1);

  const cumulativeLocal = flowndata
    .reduce(
      (accumulator, currentValue) =>
        parseFloat(accumulator) + parseFloat(currentValue.ticketequv),
      0
    )
    .toFixed(1);

  const handleChange = (e) => {
    setSearchdata(e.target.value);
  };

  return (
    <div className="w-full h-full overflow-y-auto z-20 flex flex-col absolute top-0 gap-2 bg-[whitesmoke]">
      <div className="w-full flex flex-col gap-1">
        <div className="w-full justify-between flex items-center bg-[#e50000] p-1">
          <div className="w-full flex items-center gap-3">
            <span
              className={`p-2 border rounded-full cursor-pointer ${
                gobackbut ? "bg-[#fff]" : "bg-[#e50000]"
              }`}
              onMouseEnter={handle_Hover}
              onMouseLeave={handle_Hover}
              onClick={handleBackclick}
            >
              <GoArrowLeft size={18} color={gobackbut ? "#e50000" : "#fff"} />
            </span>
            <div className="relative flex justify-center items-center">
              <span className="flex items-center px-2 py-1 text-center cursor-pointer text-[#fff] text-[13px] font-[600]">
                Ticketed Booking Breakdown
              </span>

              <IoStatsChartOutline size={25} color="#fff" />
            </div>
          </div>

          <div className="flex items-center gap-3 ">
            <input
              onChange={handleChange}
              value={searchdata}
              type="text"
              className="border text-[13px] px-2 py-1 min-w-[200px] w-[30%] outline-none rounded-md"
              placeholder="Search any text ..."
            />

            <CSVLink data={flowndata} headers={csvHeaders}>
              <div
                className={`relative border px-2 py-1 cursor-pointer rounded-md flex justify-center items-center ${
                  exportpro ? "bg-[white]" : "bg-[#e50000]"
                }`}
                onMouseEnter={handleHover}
                onMouseLeave={handleHover}
                onClick={handleExportCSV}
              >
                <FiUpload size={18} color={exportpro ? "#e50000" : "#fff"} />
                <span
                  className={`px-2 py-1 cursor-pointer ${
                    exportpro ? "text-[#e50000]" : "text-[#fff]"
                  } text-[11px] font-[600]`}
                >
                  Export
                </span>
              </div>
            </CSVLink>
          </div>
        </div>

        <table>
          <thead>
            <tr>
              <th scope="col" className="text-[11px]">
                Id
              </th>
              <th scope="col" className="text-[11px]">
                Region
              </th>
              <th scope="col" className="text-[11px]">
                Promo Code
              </th>
              <th scope="col" className="text-[11px]">
                Ticket Number
              </th>
              <th scope="col" className="text-[11px]">
                Equivalent Local
              </th>
              <th scope="col" className="text-[11px]">
                Equivalent USD
              </th>
              <th scope="col" className="text-[11px]">
                Ticket Status
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredflwondata
              .map((tdata, index) => (
                <tr
                  className="body-tab-row cursor-pointer"
                  key={index}
                  onClick={(e) => handleCountryClick(e, tdata.id)}
                >
                  <td data-label="Index" className="text-[11px]">
                    {index + 1}
                  </td>
                  <td data-label="Region" className="text-[11px]">
                    {
                      currencyUnit.find(
                        (element) => element.region_code === tdata.region_code
                      ).name
                    }
                  </td>
                  <td data-label="Promo Code" className="text-[11px]">
                    {tdata.promo_code}
                  </td>
                  <td data-label="Ticket Number" className="text-[11px]">
                    {tdata.ticket_number}
                  </td>
                  <td data-label="Equivalent Local" className="text-[11px]">
                    <NumberFormat
                      value={tdata.ticketequv}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={
                        currencyUnit.find(
                          (element) => element.region_code === tdata.region_code
                        ).symbol + " "
                      }
                    />
                  </td>
                  <td data-label="Equivalent USD" className="text-[11px]">
                    <NumberFormat
                      value={tdata.USDequv.toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$ "}
                    />
                  </td>
                  <td data-label="Ticket Status" className="text-[11px]">
                    <div
                      className={`w-full h-full py-2 text-[white] ${
                        tdata.ticket_status === 0
                          ? "bg-[orange]"
                          : tdata.ticket_status === 1 && "bg-[green]"
                      }`}
                    >
                      {tdata.ticket_status === 0 ? "Not Flown Yet" : "Flown"}
                    </div>
                  </td>
                </tr>
              ))
              .slice(pagesVisited, pagesVisited + itemsPerpage)}

            <tr className="body-tab-rowws text-[11px]">
              <td data-label="Id">Total</td>
              <td data-label="Region"></td>
              <td data-label="Promo code"></td>
              <td data-label="Ticket number"></td>
              <td data-label="Equv. Local"></td>

              <td data-label="Equv. Sum USD">
                <NumberFormat
                  value={cumulativeUSD}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </td>

              <td data-label="Ticket Status"></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="bnw-table-pgnate-contain">
        <ButtonPagenate
          updatedPageNum={newPagenum}
          pageCount={pageCount}
          pageNumber={pageNumber}
          previousLabel="Prev"
          nextLabel="Next"
        />
      </div>
    </div>
  );
};

export default FlownUnflownTicketsequv;
