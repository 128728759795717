import React from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { Store } from "../../Redux/store";
import { captureModalOverlay } from "../../Redux/Actions/buttonClick";
import { useSelector } from "react-redux";
import { IoWarningOutline } from "react-icons/io5";

const AccessRestriction = ({ setloading }) => {
  const handleClose = () => {
    Store.dispatch(captureModalOverlay("close"));
  };

  const notice = useSelector((state) => state.buttonclick.newmodal);

  return (
    <div className="overlay-container z-10 ">
      <div className="min-w-[250px] w-full max-w-[500px] h-[250px] bg-[white] flex flex-col gap-4 p-3">
        <div className="flex w-full justify-end items-center relative mb-1">
          <IoCloseCircleOutline
            size={30}
            className="cursor-pointer"
            onClick={() => handleClose()}
          />
        </div>

        <div className="w-full flex flex-col gap-4 items-center">
          <IoWarningOutline size={60} color="orange" />
          <span className="text-[15px] font-normal text-center">
            {notice.value}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AccessRestriction;
