import React, { useState } from "react";
import Regionsjson from "../Cards/regionsjson";

const SelectDropdown = ({ selectRegion }) => {
  // State to store the selected region value
  const [selectedRegion, setSelectedRegion] = useState("");

  // Handle the change when an option is selected
  const handleSelectChange = (e) => {
    setSelectedRegion(e.target.value); // Set the selected value in state
    selectRegion(e.target.value);
  };

  return (
    <div className="text-[12px]">
      <select
        id="region-select"
        value={selectedRegion}
        onChange={handleSelectChange}
        className="h-full p-1 outline-none rounded-md"
      >
        <option value="" disabled>
          -- Select a Region --
        </option>
        {/* Loop through Regionsjson to generate options */}
        {Regionsjson.filter(
          (regnitem) => regnitem.value.length === 2 || regnitem.value === "NG"
        ).map((region) => (
          <option key={region.value} value={region.value}>
            {region.label}
          </option>
        ))}
      </select>
      {/* Display the selected region value
      {selectedRegion && (
        <p>
          Selected Region: <strong>{selectedRegion}</strong>
        </p>
      )} */}
    </div>
  );
};

export default SelectDropdown;
