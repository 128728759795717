import { createAction, createReducer } from "@reduxjs/toolkit";

const Capture_treat_claim = createAction("Capture_treat_claim");
const AllocatedCountries = createAction("AllocatedCountries");

const initialState = {
  treatedclaim: { claimId: 0, claimstatus: 0, name: "", amount: 0 },
  mergedAllocations: [],
};

export const treatClaimReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(Capture_treat_claim, (state, action) => {
      state.treatedclaim = action.payload;
    })

    .addCase(AllocatedCountries, (state, action) => {
      state.mergedAllocations = action.payload;
    });
});
