import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./page.css";
import { loadingData } from "../Redux/Actions/buttonClick";
import Privileges from "../Component/Privileges";
import Users from "../Component/Users";
import Roles from "../Component/Roles";
import Payments from "../Component/Payments";
import Paymentprovider from "../Component/Paymentprovider";
import Support from "../Component/Support";
import RuleEngine from "../Component/Ruleengine";
import Blacklist from "../Component/Blacklist";

const getFirstMatchingTabId = (role, userprivileges, tabs) => {
  // Find the first tab that matches both role and privileges
  const matchingTab = tabs?.find((tab) =>
    // tab.rolesAllowed?.includes(role) &&
    tab.requiredPrivilege?.every((priv) => userprivileges?.includes(priv))
  );

  // Return the id of the matching tab, or null if none found
  return matchingTab ? matchingTab.id : null;
};

const Settings = ({ setloading }) => {
  const [activeTab, setActiveTab] = useState(null);

  let { role } = useSelector((state) => state.datareponse); // Get role from Redux store
  let { userprivileges } = useSelector((state) => state.rolesData); // Get privileges from Redux store

  const dispatch = useDispatch();

  // privileges = privileges.map((item) => item.privilege_name); // Normalize privileges

  userprivileges = userprivileges?.map((item) => item.privilege_name); // Normalize privileges

  loadingData({
    status: false,
    text: "",
    type: "spin",
  });

  const tabs = [
    {
      id: 1,
      label: "Previledges",
      rolesAllowed: ["DEVELOPER", "SUPERADMIN"],
      requiredPrivilege: ["View Privileges"],
    },
    {
      id: 2,
      label: "Roles",
      rolesAllowed: ["DEVELOPER", "SUPERADMIN"],
      requiredPrivilege: ["View Roles"],
    },
    {
      id: 3,
      label: "Users",
      rolesAllowed: ["DEVELOPER", "ADMIN", "SUPERADMIN", "MANAGER"],
      requiredPrivilege: ["View User"],
    },
    {
      id: 4,
      label: "Payments Providers",
      rolesAllowed: ["DEVELOPER", "ADMIN", "ACCOUNTANT"],
      requiredPrivilege: ["View Provider"],
    },
    {
      id: 5,
      label: "Payment Options",
      rolesAllowed: ["DEVELOPER", "ADMIN", "USER"],
      requiredPrivilege: ["View Payment"],
    },
    {
      id: 6,
      label: "Rule Engine",
      rolesAllowed: ["DEVELOPER", "ADMIN", "USER"],
      requiredPrivilege: ["View Rule Engine"],
    },
    {
      id: 7,
      label: "Security",
      rolesAllowed: ["DEVELOPER"],
      requiredPrivilege: ["View Security"],
    },
    {
      id: 8,
      label: "Support",
      rolesAllowed: ["DEVELOPER"],
      requiredPrivilege: ["View Support"],
    },
    {
      id: 9,
      label: "Blacklist",
      rolesAllowed: ["DEVELOPER", "ADMIN", "USERS"],
      requiredPrivilege: ["View Blacklist"],
    },
  ];

  const firstMatchingId = getFirstMatchingTabId(role, userprivileges, tabs);

  // Filter tabs based on role and privileges
  const filteredTabs = tabs?.filter((tab) => {
    const hasRequiredRole = tab.rolesAllowed?.includes(role);
    const hasRequiredPrivilege = tab.requiredPrivilege?.every((priv) =>
      userprivileges?.includes(priv)
    );
    // return hasRequiredRole && hasRequiredPrivilege;
    return hasRequiredPrivilege;
  });

  // Set the first available tab on initial render only
  useEffect(() => {
    if (!activeTab) {
      if (firstMatchingId) {
        setActiveTab(firstMatchingId); // Automatically select the first matching tab
      } else if (filteredTabs.length > 0) {
        setActiveTab(filteredTabs[0].id); // Set the first tab if no matching tab is found
      } else {
        setActiveTab(1); // Default tab if no match or tabs
      }
    }
  }, [firstMatchingId, filteredTabs, activeTab]);

  // Handle tab click
  const handleTabClick = (id) => {
    setActiveTab(id); // Set the clicked tab as active
    dispatch({ type: "TAB_CLICK", payload: id }); // Dispatch action if necessary
  };

  const handleLoading = () => {
    setloading({
      statuscode: 3,
      message: "Session Expired Re-Login",
      type: 3,
    });
  };

  return (
    <div className="w-full h-screen flex relative">
      <div className="w-[180px] h-fit py-2 px-1 flex flex-col bg-[#f0f0f0] shadow-lg sticky top-0">
        {filteredTabs.map((tab) => (
          <div
            key={tab.id}
            className={`text-[11px] p-2 flex items-center justify-start pl-6 w-full cursor-pointer ${
              activeTab === tab.id
                ? "bg-[#a4a4a4] text-[white]"
                : "bg-[white] text-[#333]"
            }`}
            onClick={() => handleTabClick(tab.id)}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className="flex-1 p-2 w-full">
        {activeTab === 1 ? (
          <Privileges setloading={handleLoading} />
        ) : activeTab === 2 ? (
          <Roles setloading={handleLoading} />
        ) : activeTab === 3 ? (
          <Users setloading={handleLoading} />
        ) : activeTab === 4 ? (
          <Paymentprovider setloading={handleLoading} />
        ) : activeTab === 5 ? (
          <Payments />
        ) : activeTab === 6 ? (
          <RuleEngine />
        ) : activeTab === 8 ? (
          <Support />
        ) : activeTab === 9 ? (
          <Blacklist />
        ) : (
          <h2 className="text-xl font-bold">
            Settings Content for{" "}
            {filteredTabs.find((tab) => tab.id === activeTab)?.label}
          </h2>
        )}
      </div>
    </div>
  );
};

export default Settings;
