import axios from "axios";
import { NodeServer_API } from "../../server";
import Cookies from "js-cookie"; // Ensure you import cookies from the correct library
import { toast } from "react-toastify";

// create Promo Rule
export const createPromo = (formData) => async (dispatch) => {
  try {
    console.log(formData);
    dispatch({
      type: "promoCreateRequest",
    });

    const { data } = await axios.post(`${NodeServer_API}/promo`, formData, {
      headers: {
        "Content-Type": "application/json", // Explicitly set content type
        Authorization: `Bearer ${Cookies.get("Token")}`, // Retrieve token from cookies
      },
    });

    console.log(data);

    dispatch({
      type: "promoCreateSuccess",
      payload: data.promo,
    });
    return { type: "promoCreateSuccess", message: data.message };
  } catch (error) {
    const errorMessage =
      error.response?.data?.message ||
      "An error occurred while submitting the promo.";

    dispatch({
      type: "promoCreateFail",
      payload: error.response.data.message,
    });

    return { type: "promoCreateFail", error: errorMessage };
  }
};

// delete Promo Rule
export const deletePromo = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "promoDeleteRequest",
    });

    const { data } = await axios.delete(`${NodeServer_API}/promo/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    toast.success(data.message);

    dispatch({
      type: "promoDeleteSuccess",
      payload: data.promo,
    });
    return { type: "promoDeleteSuccess", message: data.message };
  } catch (error) {
    const errorMessage =
      error.response?.data?.message ||
      "An error occurred while deleting the promo.";

    dispatch({
      type: "promoDeleteFail",
      payload: error.response.data.message,
    });

    return { type: "promoDeleteFail", error: errorMessage };
  }
};

export const fetchPromos = () => async (dispatch) => {
  try {
    // Dispatch request action
    dispatch({
      type: "fetchPromoRequest",
    });

    // Perform the GET request
    const { data } = await axios.get(`${NodeServer_API}/promo`, {
      headers: {
        "Content-Type": "application/json", // Explicitly set content type
        Authorization: `Bearer ${Cookies.get("Token")}`, // Retrieve token from cookies
      },
    });

    // Dispatch success action with response data
    dispatch({
      type: "fetchPromoSuccess",
      payload: data.promos, // Adjusted to match the backend response structure
    });
  } catch (error) {
    console.error("Error:", error?.response?.data?.message || error.message);

    // Dispatch failure action with error message
    dispatch({
      type: "fetchPromoFail",
      payload: error?.response?.data?.message || "An error occurred",
    });
  }
};
