import React, { useState, useEffect } from "react";
import ButtonPagenate from "../../Component/ButtonPagenate/ButtonPagenate";
import Toggle from "../../Component/Togglebutton/Togglebutton";
import "../page.css";
// import { useCookies } from "react-cookie";
import Pocketbalance from "../../Component/Cards/Pocketbalance";
import { useSelector } from "react-redux";
import { Store } from "../../Redux/store";
import { treatClaims } from "../../Redux/Actions/treatClaims";
// import { NodeServer_API } from "../../server";
import { claimConfirm } from "../../Redux/Actions/buttonClick";
// import ClaimsJsn from "./claimsJson";

const Claims = ({ setloading, regioncode }) => {
  const { role, exchangerate, claimsdata, pocketdata } = useSelector(
    (state) => state.datareponse
  );
  const { criteria, agencyname } = useSelector(
    (state) => state.searchdata.searchtext
  );

  const [incomingdata, setIncomingdata] = useState([]);
  const [incomingpend, setIncomingpend] = useState([]);
  const [filtertab, setFiltertab] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [checkedItems, setCheckedItems] = useState([]); // Track checked items
  const [referencePaymentInfo, setReferencePaymentInfo] = useState(null); // Track the first selected item's payment_info

  useEffect(() => {
    setIncomingdata(claimsdata);
  }, [claimsdata]);

  const filterClaims = (filter_value) => {
    let filteredClaims = incomingdata.filter(
      (object_data) => object_data.claim_status === filter_value - 1
    );
    setIncomingpend(filteredClaims);
  };

  const itemsPerpage = 20;
  const pageCount = Math.ceil(incomingdata.length / itemsPerpage);
  const pagesVisited = pageNumber * itemsPerpage;

  const Treated = (e, incomingobjt) => {
    // console.log(incomingobjt);
    Store.dispatch(treatClaims(incomingobjt));
    Store.dispatch(claimConfirm(true));
  };

  const newPagenum = (num) => setPageNumber(num);

  const statusObj = {
    1: "Approved",
    2: "Pending",
    3: "Declined",
    4: "",
  };

  const handleFilterClick = (e) => {
    const filterMap = {
      all: 0,
      pend: 1,
      appr: 2,
      decl: 3,
    };
    setFiltertab(filterMap[e.target.id]);
    if (filterMap[e.target.id] > 0) filterClaims(filterMap[e.target.id]);
  };

  const newdataset = (filtertab === 0 ? incomingdata : incomingpend).filter(
    (filteredItem) => {
      if (!criteria && !agencyname) return filteredItem;
      if (
        criteria === "User Id" &&
        filteredItem.id.toString().includes(agencyname.toLowerCase())
      )
        return filteredItem;
      if (
        criteria === "Name" &&
        filteredItem.first_name.toLowerCase().includes(agencyname.toLowerCase())
      )
        return filteredItem;
      return false;
    }
  );

  // Handle checkbox change
  const handleCheckboxChange = (item) => {
    const isChecked = checkedItems.includes(item.id);
    if (isChecked) {
      // Uncheck the item
      setCheckedItems(checkedItems.filter((id) => id !== item.id));
      // Clear reference if no items are left checked
      if (checkedItems.length === 1) setReferencePaymentInfo(null);
    } else {
      // Check the item if conditions are met
      if (
        (!referencePaymentInfo ||
          (item.payment_info.payment_mode ===
            referencePaymentInfo.payment_info.payment_mode &&
            (item.payment_info.payment_mode !== "ONLINE" ||
              item.payment_info.paymentprovider_id ===
                referencePaymentInfo.payment_info.paymentprovider_id))) &&
        (!checkedItems.length ||
          item.user_id === referencePaymentInfo.user_id) &&
        (!checkedItems.length ||
          item.account_number === referencePaymentInfo.account_number)
      ) {
        setCheckedItems([...checkedItems, item.id]);
        if (!referencePaymentInfo) {
          setReferencePaymentInfo(item);
        }
      } else {
        // console.log(referencePaymentInfo);
        alert(
          "Cannot select items with different payment mode, provider, or account number."
        );
      }
    }
  };

  const handleApprove = () => {
    const selectedItems = newdataset.filter((item) =>
      checkedItems.includes(item.id)
    );

    if (selectedItems.length > 0) {
      const treated = {
        claimId: selectedItems.map((item) => item.id), // Get all claim IDs in a list
        claimstatus: selectedItems[0].claim_status, // Assuming status is the same for all selected items
        name: `${selectedItems[0].first_name} ${selectedItems[0].last_name}`, // Assuming name is from the first selected item
        amount: selectedItems.reduce(
          (acc, item) => acc + item.points_equivalent,
          0
        ),
        paymode: selectedItems[0]?.payment_info?.payment_mode,
      };

      Store.dispatch(treatClaims(treated));
      Store.dispatch(claimConfirm(true));
    }
  };

  return (
    <div className="w-full flex flex-col gap-5 px-4">
      <Pocketbalance
        currency={
          pocketdata.find((regionpocket) => regionpocket.country === regioncode)
            ?.currency
        }
        backcolor="#FFF"
        text="Wallet Balance"
        value={
          pocketdata.find((regionpocket) => regionpocket.country === regioncode)
            ?.pocketBalance
        }
        maximum="200000"
        gradient="#FF8855"
        piebackcolor="#FFC8B0"
      />

      <div className="bnw-claim-container">
        <div className="w-full flex items-center justify-between">
          <div className="bnw-filter-tab-cntainer">
            <div
              id="all"
              className="bnw-fcc tab1 text-[13px]"
              onClick={handleFilterClick}
            >
              All
            </div>
            <div
              id="pend"
              className="bnw-fcc tab3 text-[13px]"
              onClick={handleFilterClick}
            >
              Pending
            </div>
            <div
              id="appr"
              className="bnw-fcc tab2 text-[13px]"
              onClick={handleFilterClick}
            >
              Approved
            </div>
            <div
              id="decl"
              className="bnw-fcc tab4 text-[13px]"
              onClick={handleFilterClick}
            >
              Declined
            </div>
          </div>

          <div className="flex justify-center items-center gap-1">
            <button
              disabled
              className="px-2 py-1 bnw-fcc tab2 text-[11px]"
              onClick={handleApprove}
            >
              Take Action
            </button>

            {/* <button
              className="px-2 py-1 bnw-fcc tab4 text-[11px]"
              onClick={handleApprove}
            >
              Decline
            </button> */}
          </div>
        </div>

        <div className="bnw-claims-table-container">
          <table className="bnw-claims-table">
            <thead id="bnw-claims-table-head">
              <tr>
                <th className="text-[13px]"></th>
                <th className="text-[13px]">Approve</th>
                <th className="text-[13px]">Status</th>
                <th className="text-[13px]">User ID</th>
                <th className="text-[13px]">Name</th>
                <th className="text-[13px]">Promo ID</th>
                <th className="text-[13px]">Points</th>
                <th className="text-[13px]">Point Eqv.</th>
                <th className="text-[13px]">Pay Mode</th>
                <th className="text-[13px]">Account</th>
                {role === "DEVELOPER" && (
                  <th className="text-[13px]">Pay Provider</th>
                )}
                {role === "DEVELOPER" && (
                  <th className="text-[13px]">Modified By</th>
                )}
              </tr>
            </thead>
            <tbody>
              {newdataset
                .filter(
                  (claim) => claim.region_code === exchangerate.region_code
                )
                .reverse()
                .slice(pagesVisited, pagesVisited + itemsPerpage)
                .map((item, key) => (
                  <tr key={key}>
                    <td className="text-[11px]">
                      {
                        <input
                          type="checkbox"
                          disabled={item.claim_status === 1 ? true : false}
                          checked={checkedItems.includes(item.id)}
                          onChange={() => handleCheckboxChange(item)}
                        />
                      }
                    </td>
                    <td className="text-[11px]">
                      {item.claim_status !== "" && (
                        <Toggle
                          setState={(e) =>
                            Treated(e, {
                              claimId: item.id,
                              claimstatus: item.claim_status,
                              name: item.first_name + " " + item.last_name,
                              amount:
                                item.points * exchangerate.amountPerDollar,
                              paymode: item.payment_info?.payment_mode,
                            })
                          }
                          approve={item.claim_status}
                        />
                      )}
                    </td>
                    <td className="text-[11px]">
                      {item.claim_status !== "" && (
                        <div
                          id={
                            item.claim_status === 0
                              ? "bnw-tab-status_1"
                              : item.claim_status === 1
                              ? "bnw-tab-status"
                              : "bnw-tab-status_2"
                          }
                        >
                          {item.claim_status === 0
                            ? statusObj[2]
                            : item.claim_status === 1
                            ? statusObj[1]
                            : item.claim_status === 2
                            ? statusObj[3]
                            : statusObj[4]}
                        </div>
                      )}
                    </td>
                    <td className="text-[11px]">{item.user_id}</td>
                    <td className="text-[11px]">
                      {item.first_name + " " + item.last_name}
                    </td>
                    <td className="text-[11px]">{item.promo_id}</td>
                    <td className="text-[11px]">{item.points}</td>
                    {/* <td className="text-[11px]">
                      {item.points * exchangerate.amountPerDollar}
                    </td> */}
                    <td className="text-[11px]">{item.points_equivalent}</td>
                    <td className="text-[11px]">
                      {item.payment_info?.payment_mode}
                    </td>
                    <td className="text-[11px]">{item.account_number}</td>

                    {role === "DEVELOPER" && (
                      <td className="text-[11px]">
                        {item.payment_info.paymentprovider_id}
                      </td>
                    )}
                    {role === "DEVELOPER" && (
                      <td className="text-[11px]">{item.modby}</td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="bnw-table-pgnate-contain">
            <ButtonPagenate
              updatedPageNum={newPagenum}
              pageCount={pageCount}
              pageNumber={pageNumber}
              previousLabel="Prev"
              nextLabel="Next"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Claims;
