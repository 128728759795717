import React, { useState, useEffect } from "react";
import "./togglebuttn.css";
import { useSelector } from "react-redux";
import { Store } from "../../Redux/store";
import { captureModalOverlay } from "../../Redux/Actions/buttonClick";

const Toggles = ({ currentState, setState, index, page }) => {
  const [modee, setModee] = useState(currentState ? true : false);
  const { rolelist } = useSelector((state) => state.rolesData);
  let userole = useSelector((state) => state.datareponse.role); // Get role from Redux store

  let usersPrivileges = rolelist?.find(
    (roleoption) => roleoption.role_name === userole
  )?.priviledges;

  const usersprivilegeOptions = usersPrivileges?.map((privilege) => ({
    value: privilege.privilege_id,
    label: privilege.privilege_name,
  }));

  useEffect(() => {
    setModee(currentState ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentState]);

  const toggleState = () => {
    setState({ id: index, status: currentState, page });
  };

  const handleDisable = () => {
    Store.dispatch(
      captureModalOverlay({
        params: "updateusers",
        value: `Ooops! You do not have the Right to access this resource. Kindly contact your Admin.`,
      })
    );
  };

  return (
    <div className="navbar-contain">
      <div
        className={modee ? "toggle_contain_inv" : "toggle_contain"}
        onClick={() =>
          usersprivilegeOptions?.find(
            (privilege) => privilege.label === "Disable User"
          )?.label === "Disable User"
            ? toggleState()
            : handleDisable()
        }
      >
        {modee ? (
          <div id="darkmode" className="text-[10px]">
            NO
          </div>
        ) : (
          <div id="lightmode" className="text-[10px]">
            YES
          </div>
        )}
      </div>
    </div>
  );
};

export default Toggles;
