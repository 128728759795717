import React, { useState, useEffect } from "react";

const MonthDropdown = ({ onChange }) => {
  // List of all months
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Get the current date to determine the current month
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();

  // Generate a list of months up to the current month
  const availableMonths = monthNames.slice(0, currentMonth + 1);

  // Initialize state with the current month
  const [months, setMonths] = useState(availableMonths);
  const [selectedMonth, setSelectedMonth] = useState(
    availableMonths[currentMonth]
  );

  useEffect(() => {
    // Call the onChange function with the initial selected month
    onChange(selectedMonth);
  }, [selectedMonth, onChange]);

  const handleMonthChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedMonth(selectedValue);
  };

  return (
    <select
      className="py-1 px-2 outline-none border text-[12px]"
      value={selectedMonth}
      onChange={handleMonthChange}
    >
      {months.map((month, index) => (
        <option key={index} className="text-[12px] px-2 py-1" value={month}>
          {month}
        </option>
      ))}
    </select>
  );
};

export default MonthDropdown;
