import React from "react";
import ReactLoading from "react-loading";
import "./Loading.css";

const Loader = ({ type, color, text }) => (
  <div className="loadercontainer z-20">
    <div className="loadingBox">
      <ReactLoading type={type} color={"grey"} height={"50%"} width={"50%"} />
      <span className="text-[13px] font-medium text-[#e50000] mt-3">
        {text}
      </span>
    </div>
  </div>
);

export default Loader;
