export const SeerbitRegion = [
  {
    region: "Mali",
    pocketid: "SBP0014055",
  },
  {
    region: "Burkina Faso",
    pocketid: "SBP0014623",
  },
  {
    region: "Ghana",
    pocketid: "SBP0013158",
  },
  {
    region: "Cote d'voire",
    pocketid: "SBP0013159",
  },
  {
    region: "Senegal",
    pocketid: "SBP0014158",
  },
  {
    region: "Head Office",
    pocketid: "SBP0013156",
  },
  {
    region: "Nigeria",
    pocketid: "SBP0000031",
  },
];
