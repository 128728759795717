export const paymentmodelist = [
  {
    id: 1,
    paymentmode_name: "OFFLINE",
  },
  {
    id: 2,
    paymentmode_name: "ONLINE",
  },
];
