import React, { useEffect, useState } from "react";
import ButtonPagenate from "../Component/ButtonPagenate/ButtonPagenate";
import getCurrentDate from "../Component/DateSelector/Getcurrentdate";
import { CSVLink } from "react-csv";
import { TbPresentationAnalytics } from "react-icons/tb";
import { FiUpload } from "react-icons/fi";
import { NodeServer_API } from "../server";
import axios from "axios";
import { useSelector } from "react-redux";
import { SeerbitRegion } from "../utils/seerbitRegiondata";
import { toast } from "react-toastify";
import TransactionsHistory from "../Component/Walletbox/TransactionsHistory";
import { Store } from "../Redux/store";
import { loadingData } from "../Redux/Actions/buttonClick";
import { useCookies } from "react-cookie";

const Transaction = ({ setloading, regioncode }) => {
  const [cookies] = useCookies(["Token"]);

  const { pocketdata, role } = useSelector((state) => state.datareponse);

  regioncode = role !== "DIRECTOR" ? "US" : regioncode;

  const itemsPerpage = 20; //Number of items to be displayed per page

  const handleCountryClick = async (e, countryid) => {
    // console.log(countryid, e);
  };

  const [exportpro, setExportpro] = useState(false);
  const [incomingdata, setIncomingdata] = useState([]);
  const [credit, setCredit] = useState(null);
  const [debitUSD, setDebitUSD] = useState(null);
  const [creditUSD, setCreditUSD] = useState(null);
  const [debit, setDebit] = useState(null);

  const [searchdata, setSearchdata] = useState("");

  const [pageNumber, setPageNumber] = useState(0);

  const pagesize = 2000;
  const newPagenum = (num) => {
    setPageNumber(num);
  };

  const handleChange = (e) => {
    setSearchdata(e.target.value);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    FetchAllTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const FetchAllTransactions = async () => {
    try {
      Store.dispatch(
        loadingData({
          status: true,
          text: "Fetching Transactions ...",
          type: "spin",
        })
      );

      var config = {
        method: "GET",
        url:
          role === "DIRECTOR"
            ? `${NodeServer_API}/transactionHistory/seerbit/${
                pocketdata.find(
                  (regionpocket) => regionpocket.country === regioncode
                )?.pocketId
              }/${pagesize}`
            : `${NodeServer_API}/transactionHistory/seerbit/${pagesize}`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        if (response.data.success === true) {
          Store.dispatch(
            loadingData({
              status: false,
              text: "",
              type: "spin",
            })
          );

          // console.log("history: ", response.data.data);

          // Summation for DEBIT
          setDebit(
            response.data.data
              .filter(
                (tx) =>
                  tx.message === "Success" &&
                  tx.transactionLeg === "DEBIT" &&
                  tx.transactionCurrency === "XOF"
              )
              .reduce((sum, tx) => sum + parseFloat(tx.transactionAmount), 0)
          );

          // Summation for CREDIT
          setCredit(
            response.data.data
              .filter(
                (tx) =>
                  tx.message === "Success" &&
                  tx.transactionLeg === "CREDIT" &&
                  tx.transactionCurrency === "XOF"
              )
              .reduce((sum, tx) => sum + parseFloat(tx.transactionAmount), 0)
          );

          // Summation for DEBIT
          setDebitUSD(
            response.data.data
              .filter(
                (tx) =>
                  tx.message === "Success" &&
                  tx.transactionLeg === "DEBIT" &&
                  tx.ledgerBalanceCurrency === "USD" &&
                  tx.pocketId === "SBP0013156"
              )
              .reduce((sum, tx) => sum + parseFloat(tx.transactionAmount), 0)
          );

          // Summation for CREDIT
          setCreditUSD(
            response.data.data
              .filter(
                (tx) =>
                  tx.message === "Success" &&
                  tx.transactionLeg === "CREDIT" &&
                  tx.ledgerBalanceCurrency === "USD" &&
                  tx.pocketId === "SBP0013156"
              )
              .reduce((sum, tx) => sum + parseFloat(tx.transactionAmount), 0)
          );

          // Summation for CREDIT

          setIncomingdata(
            response.data.data === null ? [] : response.data.data
          );
        }
      });
    } catch (e) {
      if (e.response.status === 401) {
        setloading({
          // Expired Session
          statuscode: 3,
          message: "Session Expired Re-Login",
          type: 3,
        });
      } else {
        Store.dispatch(
          loadingData({
            status: false,
            text: "",
            type: "spin",
          })
        );
        toast.error(e.response.data);
      }
    }
  };

  // pganate for paystack transaction history
  let thirdtabpageCount = Math.ceil(
    (incomingdata === null ? 0 : incomingdata.length) / itemsPerpage
  );
  const pagesVisited = pageNumber * itemsPerpage;

  var newdataset = incomingdata?.filter((filteredItem) => {
    if (searchdata === "") {
      return filteredItem;
    } else if (searchdata !== "") {
      const searchLower = searchdata.toLowerCase();

      return (
        filteredItem?.pocketId?.toLowerCase().includes(searchLower) ||
        filteredItem?.reference?.toLowerCase().includes(searchLower) ||
        filteredItem?.transactionId?.toLowerCase().includes(searchLower) ||
        filteredItem?.description?.toLowerCase().includes(searchLower) ||
        filteredItem?.transactionLeg?.toLowerCase().includes(searchLower) ||
        filteredItem?.message?.toLowerCase().includes(searchLower)
      );
    }
  });

  // console.log(newdataset);

  const handleHover = () => {
    setExportpro(!exportpro);
  };

  const handleExportCSV = () => {};

  const csvHeaders = [
    { label: "Id", key: "id" },
    { label: "Country", key: "region" },
    { label: "Flown Ticket", key: "flown_ticket" },
    { label: "Unflown Ticket", key: "unflown_ticket" },
    { label: "Uncaptured Ticket", key: "uncaptured_ticket" },
    { label: "Total Ticket", key: "total_ticket" },
  ];

  return (
    <div className="bnw-agency-container flex flex-col gap-1">
      <div className="w-full justify-between flex bg-[#e50000] p-1">
        <div className="flex itmes-center gap-1">
          <div
            className={`relative border flex justify-center items-center bg-[#fff9]
            }`}
          >
            <span className="flex items-center px-2 py-1 text-center cursor-pointer text-[#fff] text-[12px] font-[600]">
              TRANSACTION HISTORY
            </span>

            <TbPresentationAnalytics size={25} color="#fff" />
          </div>
        </div>

        <CSVLink data={incomingdata} headers={csvHeaders}>
          <div
            className={`relative border px-2 py-1 cursor-pointer rounded-md flex justify-center items-center ${
              exportpro ? "bg-[white]" : "bg-[#e50000]"
            }`}
            onMouseEnter={handleHover}
            onMouseLeave={handleHover}
            onClick={handleExportCSV}
          >
            <FiUpload size={18} color={exportpro ? "#e50000" : "#fff"} />
            <span
              className={`px-2 py-1 cursor-pointer ${
                exportpro ? "text-[#e50000]" : "text-[#fff]"
              } text-[12px] font-[600]`}
            >
              Export
            </span>
          </div>
        </CSVLink>
      </div>

      <div className="w-full flex flex-col justify-between gap-2 h-fit">
        <div className="w-full flex justify-between gap-2 flex-wrap h-fit">
          <div className="grow w-[60%] min-w-[500px] h-[150px] bg-[#fff] shadow-md rounded-md py-6 px-3 justify-between flex gap-2">
            <TransactionsHistory
              text1="Inflow Cash"
              curr_date={getCurrentDate()}
              amount={role === "DIRECTOR" ? credit : creditUSD}
              tooltip_text="All Cash Inflow into the pocket"
              status="Received"
              regioncode={regioncode}
            />

            <div className="border mx-2" />

            <TransactionsHistory
              text1="Outflow Cash"
              curr_date={getCurrentDate()}
              amount={role === "DIRECTOR" ? debit : debitUSD}
              tooltip_text={"All Cash Outflow from the pocket"}
              status="Sent"
              regioncode={regioncode}
            />
          </div>
        </div>
      </div>

      <div className="bnw-claims-table-container">
        <div className="w-full bg-[#e50000] p-1 flex items-center justify-start gap-4">
          <input
            onChange={handleChange}
            value={searchdata}
            type="text"
            className="border text-[13px] px-2 py-1 min-w-[200px] w-[30%] outline-none rounded-md"
            placeholder="Search any text ..."
          />
        </div>

        <table>
          <thead>
            <tr>
              <th
                scope="col"
                className="text-[11px] whitespace-normal break-words"
              >
                #SN
              </th>

              <th
                scope="col"
                className="text-[11px] whitespace-normal break-words"
              >
                PocketID
              </th>

              <th
                scope="col"
                className="text-[11px] whitespace-normal break-words"
              >
                Trans. Ref.
              </th>

              <th
                scope="col"
                className="text-[11px] whitespace-normal break-words"
              >
                Trans. ID.
              </th>

              <th
                scope="col"
                className="text-[11px] whitespace-normal break-words"
              >
                Description
              </th>

              <th
                scope="col"
                className="text-[11px] whitespace-normal break-words"
              >
                Trans. Direction
              </th>

              <th
                scope="col"
                className="text-[11px] whitespace-normal break-words"
              >
                From
              </th>

              <th
                scope="col"
                className="text-[11px] whitespace-normal break-words"
              >
                To Account
              </th>

              <th
                scope="col"
                className="text-[11px] whitespace-normal break-words"
              >
                Amount
              </th>

              <th
                scope="col"
                className="text-[11px] whitespace-normal break-words"
              >
                Status
              </th>

              <th
                scope="col"
                className="text-[11px] whitespace-normal break-words"
              >
                Created At
              </th>

              {/* <th scope="col">Action</th> */}
            </tr>
          </thead>

          <tbody>
            {newdataset
              .slice(pagesVisited, pagesVisited + itemsPerpage)
              .map((tdata, index) => (
                <tr
                  className="body-tab-row cursor-pointer"
                  key={tdata.transactionId}
                  onClick={(e) => handleCountryClick(e, tdata.pocketId)}
                >
                  <td
                    data-label="SN"
                    className="text-[11px] whitespace-normal break-words"
                  >
                    {index + 1}
                  </td>

                  <td
                    data-label="PocketID"
                    className="text-[11px] whitespace-normal break-words"
                  >
                    {tdata.pocketId}
                  </td>

                  <td
                    data-label="Trans. Ref."
                    className="text-[11px] whitespace-normal break-words"
                  >
                    {tdata.reference}
                  </td>

                  <td
                    data-label="Trans. ID"
                    className="text-[11px] whitespace-normal break-words"
                  >
                    {tdata.transactionId}
                  </td>

                  <td
                    data-label="Description"
                    className="text-[11px] whitespace-normal break-words"
                  >
                    {tdata.description}
                  </td>

                  <td
                    data-label="Trans. Direction"
                    className={`text-[11px] whitespace-normal break-words text-[white] ${
                      tdata.transactionLeg === "CREDIT"
                        ? "bg-[green]"
                        : "bg-[orange]"
                    }`}
                  >
                    {tdata.transactionLeg}
                  </td>

                  <td
                    data-label="From"
                    className="text-[11px] whitespace-normal break-words"
                  >
                    {
                      SeerbitRegion?.find(
                        (pocketobj) => pocketobj?.pocketid === tdata.pocketId
                      )?.region
                    }
                  </td>

                  <td
                    data-label="To Account"
                    className="text-[11px] whitespace-normal break-words"
                  >
                    {tdata.receiverAccountNumber}
                  </td>

                  <td
                    data-label="Amount"
                    className="text-[11px] whitespace-normal break-words"
                  >
                    {tdata.ledgerBalanceCurrency +
                      " " +
                      tdata.transactionAmount}
                  </td>

                  <td
                    data-label="Status"
                    className={`text-[11px] whitespace-normal break-words text-[white] ${
                      tdata.message === "Success" ? "bg-[green]" : "bg-[red]"
                    }`}
                  >
                    {tdata.message !== "Success" ? "Failed" : "Successful"}
                  </td>

                  <td
                    data-label="Total Tkts."
                    className="text-[11px] whitespace-normal break-words"
                  >
                    {tdata.createdAt}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        <div className="bnw-table-pgnate-contain">
          <ButtonPagenate
            updatedPageNum={newPagenum}
            pageCount={thirdtabpageCount}
            pageNumber={pageNumber}
            previousLabel="Prev"
            nextLabel="Next"
          />
        </div>
      </div>
    </div>
  );
};

export default Transaction;
