import { SabreAuthAPI, SabreCertAPI } from "../../server";
import axios from "axios";
import Cookies from "js-cookie"; // Correct library for managing cookies

// Fetch airline lookup action
export const fetchAirlinelookup = (airlineCode) => async (dispatch) => {
  try {
    // Dispatch request action
    dispatch({ type: "fetchAirlineLookupRequest" });

    // Perform the GET request
    const { data } = await axios.get(
      `${SabreCertAPI}?airlinecode=${airlineCode}`,
      {
        headers: {
          "Content-Type": "application/json", // Explicitly set content type
          Authorization: `Bearer ${Cookies.get("authtoken")}`, // Retrieve token from cookies
        },
      }
    );

    console.log("Airline lookup response:", data);

    // Validate the response structure
    const airlineInfo = data?.AirlineInfo;
    if (!airlineInfo) {
      throw new Error("Airline information is missing from the response.");
    }

    // Dispatch success action with response data
    dispatch({
      type: "fetchAirlineLookupSuccess",
      payload: airlineInfo,
    });

    // Return success response for further usage
    return { type: "fetchAirlineLookupSuccess", airlinelookup: airlineInfo };
  } catch (error) {
    console.error(
      "Error during airline lookup:",
      error?.response?.data?.message || error.message
    );

    // Dispatch failure action with error message
    dispatch({
      type: "fetchAirlineLookupFail",
      payload: error?.response?.data?.message || "An error occurred",
    });

    // Return failure response for further usage
    return { type: "fetchAirlineLookupFail", message: "An error occurred" };
  }
};

// Fetch authentication token action
export const fetchAuthentication = () => async (dispatch) => {
  try {
    // Dispatch request action
    dispatch({ type: "fetchAuthRequest" });

    // Perform the GET request
    const { data } = await axios.get(`${SabreAuthAPI}/createtokenauth`);

    console.log("Token response:", data);

    // Validate the token structure
    const token = data?.data?.$t;
    if (!token) {
      throw new Error("Token is missing from the response.");
    }

    // Set token in cookies
    Cookies.set("authtoken", token, { path: "/" }); // Adjusted to store the token

    // Dispatch success action with the response data
    dispatch({ type: "fetchAuthSuccess", payload: token });

    // Return success response for further usage
    return { type: "fetchAuthSuccess", token };
  } catch (error) {
    console.error(
      "Error during authentication:",
      error?.response?.data?.message || error.message
    );

    // Dispatch failure action with the error message
    dispatch({
      type: "fetchAuthFail",
      payload: error?.response?.data?.message || "An error occurred",
    });

    // Return failure response for further usage
    return { type: "fetchAuthFail", message: "An error occurred" };
  }
};
