export const sumAllocationsByMonth = (data, month) => {
  // Map of month names to month numbers
  const monthMap = {
    January: 0,
    February: 1,
    March: 2,
    April: 3,
    May: 4,
    June: 5,
    July: 6,
    August: 7,
    September: 8,
    October: 9,
    November: 10,
    December: 11,
  };

  // Get the current month number from the provided month name
  const monthNumber = monthMap[month];

  // Filter the data based on the month
  const filteredData = data.filter((item) => {
    const itemDate = new Date(item.CreatedAt);
    return itemDate.getUTCMonth() === monthNumber;
  });

  // Sum the allocations for the filtered data
  const totalAllocation = filteredData.reduce(
    (sum, item) => sum + item.allocation,
    0
  );

  return totalAllocation;
};
