import React, { useState } from "react";
import "./addoverlay.css";
import Regionsjson from "./regionsjson";
import { CgCloseO } from "react-icons/cg";
import Select from "react-select";
import axios from "axios";
import { useCookies } from "react-cookie";
import SLoader from "../Loading/SigninLoader";
import moment from "moment";
import Error from "../Error";
import Loader from "../Loading/Loading";
import DatePicker2 from "../DatePicker/DatePicker2";
import { NodeServer_API } from "../../server";
// import PasswordInputField from '../Passwordverify/password';
// import ConfirmPasswordInputField from '../Passwordverify/Confirmpassword';

const AddPromo = ({ closeOverlay }) => {
  const [cookies] = useCookies(["Token"]);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");

  const [confirmload, setConfirmload] = useState(false);
  const [confirmresponse, setConfirmresponse] = useState(0);
  const [expiration, setExpiration] = useState();
  const [responses, setResponses] = useState();
  const [errrespnse, setErrresponse] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setConfirmload(true);
    try {
      var config = {
        method: "POST",
        url: `${NodeServer_API}/createRegion`,
        data: {
          name: firstname,
          region_code: lastname,
        },
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      // // console.log(config)

      if (config.data.name !== "" && config.data.region_code !== "") {
        await axios(config).then((response) => {
          // // console.log(response.data.data);
          if (response.data.success === true) {
            setConfirmresponse(1);
            setConfirmload(false);
            setResponses(response.data.message);
            window.location.reload(true);
          }
        });
      } else {
        // // console.log("Else")
        setConfirmload(false);
        setErrresponse("Incomplete Fields");
        setConfirmresponse(2);
      }
    } catch (e) {
      if (e.message.includes("401")) {
        // // console.log(`Error `)
        setConfirmload(false);
        setConfirmresponse(3);
        setExpiration(true);
      } else {
        setConfirmload(false);
        setConfirmresponse(3);
        setExpiration(false);
      }
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "fname") {
      setFirstname(e.target.value);
    }

    if (e.target.name === "lname") {
      setLastname(e.target.value);
    }
  };

  const closeConfirm = () => {
    closeOverlay();
  };

  const handleClose = () => {
    setConfirmresponse(0);
    closeOverlay();
  };

  const [dates, setDates] = useState({
    startDate: "",
    endDate: "",
  });

  const [currdate, setCurrdate] = useState();

  const handleDateSelect = (date) => {
    setDates({
      ...dates,
      startDate: moment(date).format("YYYY-MM-DD hh:mm:ss a"),
    });
    setCurrdate(moment(date).format("MM-DD-YYYY"));
  };

  const handleendDateSelect = (date) => {
    // console.log(date)
    setDates({
      ...dates,
      endDate: moment(date).format("YYYY-MM-DD hh:mm:ss a"),
    });
  };

  return (
    <div className="main-add-dir-container">
      <div className="bnw-addirector-pg">
        <form className="bnw-addirector-contain" onSubmit={handleSubmit}>
          <div className="bnw-direct-heading">
            <span>Add New Promo</span>
            <CgCloseO id="bnw-close-icon" onClick={closeConfirm} />
          </div>

          <div className="bnw-dirname-container">
            <input
              id="bnw-firname-box"
              placeholder="Promo Title"
              name="fname"
              value={firstname}
              onChange={handleChange}
              type="text"
            />
          </div>

          <div className="top-event-container-body">
            <div className="top-event-column1">
              <span>Start Date</span>
              <DatePicker2
                linestate={false}
                selected={handleDateSelect}
                start={true}
                currentdate={currdate}
              />
            </div>
            <div className="top-event-column2">
              <span>End Date</span>
              <DatePicker2
                linestate={false}
                selected={handleendDateSelect}
                start={false}
                currentdate={currdate}
              />
            </div>
          </div>

          <div className="bnw-dirname-container">
            <Select
              options={Regionsjson}
              className="bnw-regionfield"
              placeholder="Regions"
            />
          </div>

          <button id="bnw-submit-butn">SUBMIT</button>
        </form>
        {confirmload && <Loader type="spin" text="Sending Data..." />}
        {confirmresponse === 1 ? (
          <Error
            closeOverlay={handleClose}
            message={responses}
            icon="1"
            isTimeout={false}
          />
        ) : confirmresponse === 2 ? (
          <Error
            closeOverlay={handleClose}
            message={errrespnse}
            isTimeout={false}
            icon="2"
          />
        ) : confirmresponse === 3 ? (
          expiration ? (
            <Error
              message={"Session Expired Re-Login"}
              isTimeout={true}
              icon="3"
            />
          ) : (
            <Error
              closeOverlay={handleClose}
              message={"Connection Error"}
              isTimeout={false}
              icon="2"
            />
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default AddPromo;
