// capture button click
export const captureButtonClick = (currentpage) => ({
  type: "CAPTURE_Sidebar_Tab",
  payload: currentpage,
});

export const captureSelectedMonth = (month) => ({
  type: "Selected_Month",
  payload: month,
});

export const captureSelectedYear = (year) => ({
  type: "Selected_Year",
  payload: year,
});

export const captureTopPerforming = (topperfclick) => ({
  type: "Top_performing_agency",
  payload: topperfclick,
});

export const captureModalOverlay = (newmodal) => ({
  type: "Add_New_Modal",
  payload: newmodal,
});

export const claimConfirm = (claimsmodal) => ({
  type: "Add_Claims_Modal",
  payload: claimsmodal,
});

export const loadingData = (loading) => ({
  type: "Loding_Modal",
  payload: loading,
});

export const boxloadingstatus = (loading) => ({
  type: "BoxModalLoaderStatus",
  payload: loading,
});

export const toggle_Status = (toggle) => ({
  type: "ToggleState",
  payload: toggle,
});

export const selectedUser = (directorprop) => ({
  type: "SelectedUser",
  payload: directorprop,
});

export const expirationStatus = (expiredtoken) => ({
  type: "ExpiredToken",
  payload: expiredtoken,
});
