export const sumAllocationsByRegionAndMonth = (data, month) => {
  const result = [];

  // Filter data by the provided month
  const filteredData = data.filter((item) => {
    const itemMonth = new Date(item.CreatedAt).toLocaleString("default", {
      month: "long",
    });
    return itemMonth === month && item.Status === 1;
  });

  // Group data by region_code
  const groupedByRegion = filteredData.reduce((acc, item) => {
    const region = item.region_code;
    if (!acc[region]) {
      acc[region] = [];
    }
    acc[region].push(item.allocation);
    return acc;
  }, {});

  // Sum allocations for each region_code
  for (const region in groupedByRegion) {
    const sum = groupedByRegion[region].reduce(
      (total, allocation) => total + allocation,
      0
    );
    result.push({
      region_code: region,
      allocation: sum,
      //   status: Status,
    });
  }

  return result;
};
