import React, { useState, useEffect } from "react";
import "./style.css";
import axios from "axios";
import { CgCloseO } from "react-icons/cg";
import { useCookies } from "react-cookie";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { NodeServer_API } from "../server";
import { Store } from "../Redux/store";
import { claimsData, seerbitPocketData } from "../Redux/Actions/dataResponse";
import { claimConfirm } from "../Redux/Actions/buttonClick";
// import LoadVeriy from "./Loading/LoadVeriy"; // Assuming this is a loading component
// import Error from "./Error"; // Assuming this is an error component

const Confirmpg = ({ setloading, regioncode }) => {
  const { treatedclaim } = useSelector((state) => state.treatclaim);
  const { pocketdata } = useSelector((state) => state.datareponse);

  const cids = treatedclaim.claimId || 0;
  const appClm = treatedclaim.claimstatus || 0;

  const [cookies] = useCookies(["Token"]);
  const [confirmload, setConfirmload] = useState(false);
  const [claimtreat, setClaimtreat] = useState({
    claimid: 0,
    approvedClaims: false,
  });

  const pocketBalance = pocketdata.find(
    (regionpocket) => regionpocket.country === regioncode
  )?.pocketBalance;

  useEffect(() => {
    setClaimtreat({
      claimid: cids,
      approvedClaims: appClm === 1,
    });
  }, [cids, appClm]);

  const closeConfirm = () => {
    Store.dispatch(claimConfirm(false));
  };

  const handleAction = async (status) => {
    if (treatedclaim.amount < 3000) {
      setConfirmload(true);
      try {
        setloading({
          statuscode: 1,
          message: "Processing Payment...",
          type: 1,
        });

        const currency = pocketdata.find(
          (regionpocket) => regionpocket.country === regioncode
        )?.currency;

        const pocketID = pocketdata.find(
          (regionpocket) => regionpocket.country === regioncode
        )?.pocketId;

        const config = {
          method: "POST",
          url: `${NodeServer_API}/claims/updateClaim`,
          data: {
            claim_id: claimtreat.claimid,
            status,
            amount: treatedclaim.amount,
            currency,
            pocketsFrom: pocketID,
            paymode: treatedclaim.paymode,
          },
          headers: {
            Authorization: `Bearer ${cookies.Token}`,
          },
        };

        const response = await axios(config);

        if (response.data.success) {
          Store.dispatch(claimsData(response.data.data));
          Store.dispatch(claimConfirm(false));
          toast.success(response.data.message);

          // Fetch primary pockets and ensure it finishes before proceeding
          await FetchprimaryPockets();

          setloading({
            statuscode: 0,
            message: "",
            type: 0,
          });
        }
      } catch (e) {
        const status = e.response?.status;
        const message = e.response?.data?.message || e.message;

        if (status === 401) {
          setloading({
            statuscode: 3,
            message: "Session Expired Re-Login",
            type: 3,
          });
        } else {
          setloading({
            statuscode: 2,
            message,
            type: 2,
          });
        }
      } finally {
        setConfirmload(false);
      }
    } else {
      toast.warning("Oops!, your region has insufficient funds");
    }
  };

  const FetchprimaryPockets = async () => {
    try {
      const config = {
        method: "GET",
        url: `${NodeServer_API}/seerbit/fetchprimarypockets`,
        headers: {
          Authorization: `Bearer ${cookies.Token}`,
        },
      };

      const response = await axios(config);
      if (response.data.success === true) {
        Store.dispatch(seerbitPocketData(response.data.data));
      }
    } catch (e) {
      console.error("Error fetching primary pockets:", e.message);
      // Uncomment the toast.error line to handle the error visibly
      // toast.error(e.response?.data?.message || "Failed to fetch primary pockets");
    }
  };

  const handleApprove = () => handleAction(1);
  const handleDecline = () => handleAction(2);

  const currency =
    pocketdata.find((regionpocket) => regionpocket.country === regioncode)
      ?.currency || "";

  return (
    <div className="bnw-confirm-pg">
      <div className="flex flex-col items-center justify-center p-5 bg-[white] gap-5 min-w-[280px] w-[50%] max-w-[600px]">
        <div className="bnw-confirm-head">
          <span>Treat Claim</span>
          <CgCloseO id="bnw-conf-icon" onClick={closeConfirm} />
        </div>

        <div className="w-full text-[14px] text-center">
          By Treating Claim as Approve, you consent to make a payment of{" "}
          <strong>
            <NumberFormat
              value={treatedclaim.amount}
              displayType={"text"}
              thousandSeparator={true}
              prefix={`${currency} `}
            />
          </strong>{" "}
          to account belonging to <strong>{treatedclaim.name}</strong>
        </div>

        <div className="bnw-confm-but-contain mt-5">
          <button
            id="bnw-approve-but"
            onClick={handleApprove}
            disabled={confirmload}
          >
            Approve
          </button>
          <button
            id="bnw-decline-but"
            onClick={handleDecline}
            disabled={confirmload}
          >
            Decline
          </button>
        </div>
      </div>
    </div>
  );
};

export default Confirmpg;
