import { NodeServer_API } from "../../server";
import axios from "axios";

export const fetchAllAgencies = () => async (dispatch) => {
  try {
    // Dispatch request action
    dispatch({
      type: "fetchAgenciesRequest",
    });

    // Perform the POST request
    const { data } = await axios.get(`${NodeServer_API}/agencies`, {
      headers: {
        "Content-Type": "application/json", // Explicitly set content type
      },
    });
    // Dispatch success action with response data
    dispatch({
      type: "fetchAgenciesSuccess",
      payload: data.data, // Adjusted to match the backend response structure
    });
  } catch (error) {
    console.error("Error:", error?.response?.data?.message || error.message);

    // Dispatch failure action with error message
    dispatch({
      type: "fetchAgenciesFail",
      payload: error?.response?.data?.message || "An error occurred",
    });
  }
};
