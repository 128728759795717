import React, { useState, useEffect } from "react";
import ButtonPagenate from "../Component/ButtonPagenate/ButtonPagenate";
import { IoStatsChartOutline } from "react-icons/io5";
import { CSVLink } from "react-csv";
import { FiUpload } from "react-icons/fi";
import { useSelector } from "react-redux";
import { GoArrowLeft } from "react-icons/go";
import { Store } from "../Redux/store";
import {
  captureTopPerforming,
  loadingData,
} from "../Redux/Actions/buttonClick";
import NumberFormat from "react-number-format";
import currencyUnit from "./Claims/currencyUnit";
import { toast } from "react-toastify";
import { NodeServer_API } from "../server";
import { seerbitPocketData } from "../Redux/Actions/dataResponse";
import axios from "axios";
import Boxes from "../Component/Dashbardboxes/Boxes";
import BoxesBreakdown from "../Component/Dashbardboxes/BoxesBreakdown";

const CurrentLiability = ({ flownticket }) => {
  console.log(flownticket);
  // const { flownticketsum } = useSelector((state) => state.datareponse);
  const { financialdata, pocketdata } = useSelector(
    (state) => state.datareponse
  );

  const [exportpro, setExportpro] = useState(false);
  const [gobackbut, setGobackbut] = useState(false);
  const [flowndata, setFlowndata] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setFlowndata(flownticket);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Cum_eqv_team_1_online = flownticket.find(
    (paymentoption) => paymentoption.region_code === "NG"
  )?.equivalent_sum;

  const Cum_eqv_team_1_offline = flownticket
    .filter(
      (paymentoption) =>
        paymentoption.payment_mode === "OFFLINE" &&
        paymentoption.region_code === "NG"
    )
    .reduce((accumulator, current) => accumulator + current.equivalent_sum, 0);

  // Filter and sum equivalent_sum_usd for OFFLINE payment mode
  const Cum_eqv_team_2_offline = flownticket
    .filter((paymentoption) => paymentoption.payment_mode === "OFFLINE")
    .reduce(
      (accumulator, current) => accumulator + current.equivalent_sum_usd,
      0
    );

  // Filter and sum equivalent_sum_usd for OFFLINE payment mode
  const Cum_eqv_team_2_online = flownticket
    .filter(
      (paymentoption) =>
        paymentoption.payment_mode === "ONLINE" &&
        paymentoption.region_code !== "NG"
    )
    .reduce(
      (accumulator, current) => accumulator + current.equivalent_sum_usd,
      0
    );

  const csvHeaders = [
    { label: "Id", key: "id" },
    { label: "Country", key: "region" },
    { label: "Flown Ticket", key: "flown_ticket" },
    { label: "Unflown Ticket", key: "unflown_ticket" },
    { label: "Uncaptured Ticket", key: "uncaptured_ticket" },
    { label: "Total Ticket", key: "total_ticket" },
  ];

  const handleHover = () => {
    setExportpro(!exportpro);
  };

  const newPagenum = (num) => {
    setPageNumber(num);
  };

  const handleExportCSV = () => {};

  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerpage = 15; //Number of items to be displayed per page
  const pageCount = Math.ceil(flowndata.length / itemsPerpage);
  const pagesVisited = pageNumber * itemsPerpage;

  const handleCountryClick = () => {};

  const handle_Hover = () => {
    setGobackbut(!gobackbut);
  };

  const handleBackclick = () => {
    Store.dispatch(captureTopPerforming(0));
  };

  const cumulativeUSD = flowndata
    .reduce(
      (accumulator, currentValue) =>
        parseFloat(accumulator) + parseFloat(currentValue.equivalent_sum_usd),
      0
    )
    .toFixed(1);

  const cumulativeLocal = flowndata
    .reduce(
      (accumulator, currentValue) =>
        parseFloat(accumulator) + parseFloat(currentValue.equivalent_sum),
      0
    )
    .toFixed(1);

  let pocketBalance = pocketdata.find(
    (regionpocket) => regionpocket.currency === "USD"
  )?.pocketBalance;

  const handlePay = async (props) => {
    Store.dispatch(
      loadingData({
        status: true,
        text: "Allocating Region's Fund",
        type: "spin",
      })
    );

    if (props.amount < pocketBalance) {
      try {
        if (props.amount < financialdata.amount_left) {
          if (props.amount >= 1) {
            var config = {
              method: "POST",
              url: `${NodeServer_API}/allocation/ptoptransfer`,
              data: {
                allocation: props.amount,
                region_code: props.region,
              },
            };

            await axios(config).then((response) => {
              if (response.data.success === true) {
                Store.dispatch(seerbitPocketData(response.data.data));
                Store.dispatch(
                  loadingData({
                    status: false,
                    text: "",
                    type: "spin",
                  })
                );
                toast.success(response.data.message);
              }
            });
          } else {
            Store.dispatch(
              loadingData({
                status: false,
                text: "",
                type: "spin",
              })
            );

            toast.warning("Mimimum allocation is $1");
          }
        } else {
          Store.dispatch(
            loadingData({
              status: false,
              text: "",
              type: "spin",
            })
          );
          toast.warning("Allocation more than Available Balance!");
        }
      } catch (e) {
        Store.dispatch(
          loadingData({
            status: false,
            text: "",
            type: "spin",
          })
        );
        toast.warning(e.response.data.message);
      }
    } else {
      Store.dispatch(
        loadingData({
          status: false,
          text: "",
          type: "spin",
        })
      );
      toast.warning("Insufficient Balance");
    }
  };

  return (
    <div className="w-full h-full overflow-y-auto z-20 flex flex-col absolute top-0 gap-2 bg-[whitesmoke]">
      <div className="w-full flex flex-col gap-1">
        <div className="w-full justify-between flex items-center bg-[#e50000] p-1">
          <div className="w-full flex items-center gap-3">
            <span
              className={`p-2 border rounded-full cursor-pointer ${
                gobackbut ? "bg-[#fff]" : "bg-[#e50000]"
              }`}
              onMouseEnter={handle_Hover}
              onMouseLeave={handle_Hover}
              onClick={handleBackclick}
            >
              <GoArrowLeft size={18} color={gobackbut ? "#e50000" : "#fff"} />
            </span>
            <div className="relative flex justify-center items-center">
              <span className="flex items-center px-2 py-1 text-center cursor-pointer text-[#fff] text-[13px] font-[600]">
                Current Liability Breakdown
              </span>

              <IoStatsChartOutline size={25} color="#fff" />
            </div>
          </div>

          <CSVLink data={flowndata} headers={csvHeaders}>
            <div
              className={`relative border px-2 py-1 cursor-pointer rounded-md flex justify-center items-center ${
                exportpro ? "bg-[white]" : "bg-[#e50000]"
              }`}
              onMouseEnter={handleHover}
              onMouseLeave={handleHover}
              onClick={handleExportCSV}
            >
              <FiUpload size={18} color={exportpro ? "#e50000" : "#fff"} />
              <span
                className={`px-2 py-1 cursor-pointer ${
                  exportpro ? "text-[#e50000]" : "text-[#fff]"
                } text-[11px] font-[600]`}
              >
                Export
              </span>
            </div>
          </CSVLink>
        </div>

        <div className="w-full flex flex-wrap items-center gap-2 py-2">
          <BoxesBreakdown
            boxtitle="TEAM I BREAKDOWN"
            currency_team1="₦"
            currency_team2="$"
            team_1_value={Cum_eqv_team_1_online.toFixed(1)}
            team_2_value={Cum_eqv_team_1_offline.toFixed(1)}
            icon={1}
            tooltiptext="NGN Equivalent of Team I Current Liability Categorized into Online and Offline"
          />

          <BoxesBreakdown
            boxtitle="TEAM II BREAKDOWN"
            currency_team1="$"
            currency_team2="$"
            team_1_value={Cum_eqv_team_2_online.toFixed(1)}
            team_2_value={Cum_eqv_team_2_offline.toFixed(1)}
            icon={1}
            tooltiptext="USD Equivalent of Team II Current Liability Categorized into Online and Offline)"
          />
        </div>

        <table>
          <thead>
            <tr>
              <th scope="col" className="text-[11px]">
                Id
              </th>
              <th scope="col" className="text-[11px]">
                Region
              </th>
              <th scope="col" className="text-[11px]">
                Cumm. Points
              </th>
              <th scope="col" className="text-[11px]">
                Equivalent Local
              </th>
              <th scope="col" className="text-[11px]">
                Equivalent USD
              </th>
              <th scope="col" className="text-[11px]">
                Payment Option
              </th>
              <th scope="col" className="text-[11px]">
                Action
              </th>
            </tr>
          </thead>

          <tbody>
            {flowndata
              .filter((items) => items.equivalent_sum_usd.toFixed(2) > 0.0)
              .slice(pagesVisited, pagesVisited + itemsPerpage)
              .map((tdata, index) => (
                <tr
                  className="body-tab-row cursor-pointer"
                  key={tdata.id}
                  onClick={(e) => handleCountryClick(e, tdata.id)}
                >
                  <td data-label="Index" className="text-[11px]">
                    {index + 1}
                  </td>
                  <td data-label="Region" className="text-[11px]">
                    {
                      currencyUnit.find(
                        (element) => element.region_code === tdata.region_code
                      ).name
                    }
                  </td>
                  <td data-label="Index" className="text-[11px]">
                    {tdata.summed}
                  </td>
                  <td data-label="Equivalent Local" className="text-[11px]">
                    <NumberFormat
                      value={tdata.equivalent_sum}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={
                        currencyUnit.find(
                          (element) => element.region_code === tdata.region_code
                        ).symbol + " "
                      }
                    />
                  </td>

                  <td data-label="Equivalent USD" className="text-[11px]">
                    <NumberFormat
                      value={tdata.equivalent_sum_usd.toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  </td>
                  <td data-label="Payment Option" className="text-[11px]">
                    {tdata.payment_mode}
                  </td>

                  <td data-label="Equivalent USD" className="text-[11px]">
                    <button
                      disabled={
                        tdata.equivalent_sum_usd.toFixed(0) <= 5 ||
                        tdata.payment_mode === "OFFLINE"
                          ? true
                          : false
                      }
                      className={`py-1 px-2 text-[11px] cursor-pointer rounded-md text-[white] ${
                        tdata.equivalent_sum_usd.toFixed(0) <= 5 ||
                        tdata.payment_mode === "OFFLINE"
                          ? "bg-[#575757]"
                          : "bg-[#137513]"
                      }  `}
                      onClick={(e) =>
                        handlePay({
                          region: tdata.region_code,
                          amount: tdata.equivalent_sum_usd.toFixed(2),
                        })
                      }
                    >
                      PAY Now
                    </button>
                  </td>
                </tr>
              ))}

            <tr className="body-tab-rowws text-[11px]">
              <td data-label="Id">Total</td>
              <td data-label="Region"></td>
              <td data-label="Comm. Points"></td>
              <td data-label="Equv. Sum"></td>

              <td data-label="Equv. Sum USD">
                <NumberFormat
                  value={cumulativeUSD}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                />
              </td>
              <td data-label="Payment Option"></td>
              <td data-label="Action"></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="bnw-table-pgnate-contain">
        <ButtonPagenate
          updatedPageNum={newPagenum}
          pageCount={pageCount}
          pageNumber={pageNumber}
          previousLabel="Prev"
          nextLabel="Next"
        />
      </div>
    </div>
  );
};

export default CurrentLiability;
