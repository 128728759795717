import axios from "axios";
import { NodeServer_API } from "../../server";
import Cookies from "js-cookie"; // Ensure you import cookies from the correct library
import { toast } from "react-toastify";

// create Exchange Rate
export const createExchangeRate = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: "rateCreateRequest",
    });

    console.log(formData);

    const { data } = await axios.post(
      `${NodeServer_API}/setExchangeRate`,
      formData,
      {
        headers: {
          "Content-Type": "application/json", // Explicitly set content type
          Authorization: `Bearer ${Cookies.get("Token")}`, // Retrieve token from cookies
        },
      }
    );

    toast.success("Exchange Rate Set Successfully!");

    dispatch({
      type: "rateCreateSuccess",
      payload: data,
    });

    return {
      type: "rateCreateSuccess",
      message: "Rate Created Successfully",
    };
  } catch (error) {
    dispatch({
      type: "rateCreateFail",
      payload: error.response.data.message,
    });

    return { type: "rateCreateFail", error: error.response };
  }
};

// // fetch Exchange Rate
// export const fetchExchangeRate = (formData) => async (dispatch) => {
//   try {
//     dispatch({
//       type: "rateFetchRequest",
//     });

//     const { data } = await axios.get(`${NodeServer_API}/rate`, {
//       headers: {
//         "Content-Type": "multipart/form-data",
//         Authorization: `Bearer ${Cookies.get("Token")}`, // Retrieve token from cookies
//       },
//     });

//     console.log(data);

//     dispatch({
//       type: "rateFetchSuccess",
//       payload: data.rate,
//     });
//     return { type: "rateFetchSuccess", message: data.message };
//   } catch (error) {
//     const errorMessage =
//       error.response?.data?.message ||
//       "An error occurred while submitting the rate.";

//     dispatch({
//       type: "rateFetchFail",
//       payload: error.response.data.message,
//     });

//     return { type: "rateFetchFail", error: errorMessage };
//   }
// };

export const getAllExchangerate = (exchangerateList) => ({
  type: "ExchangeRateList",
  payload: exchangerateList,
});
