import { createAction, createReducer } from "@reduxjs/toolkit";
const regionCreateRequest = createAction("regionCreateRequest");
const regionCreateSuccess = createAction("regionCreateSuccess");
const regionCreateFail = createAction("regionCreateFail");

const regionFetchRequest = createAction("regionFetchRequest");
const regionFetchSuccess = createAction("regionFetchSuccess");
const regionFetchFail = createAction("regionFetchFail");
const RegionsList = createAction("RegionsList");

const initialState = {
  isLoading: false,
  regionlist: [],
  isAuthenticated: false,
};

export const regionReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(regionCreateRequest, (state, action) => {
      state.isLoading = true;
    })

    .addCase(regionCreateSuccess, (state, action) => {
      state.isLoading = false;
      state.regionlist = action.payload;
    })

    .addCase(regionCreateFail, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })

    .addCase(regionFetchRequest, (state, action) => {
      state.isLoading = true;
    })

    .addCase(regionFetchSuccess, (state, action) => {
      state.isLoading = false;
      state.regionlist = action.payload;
    })

    .addCase(regionFetchFail, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })

    .addCase(RegionsList, (state, action) => {
      state.isLoading = false;
      state.regionlist = action.payload;
    });
});
