import React, { useState, useEffect } from "react";
import ButtonPagenate from "../Component/ButtonPagenate/ButtonPagenate";
import { CSVLink } from "react-csv";
import { FiUpload } from "react-icons/fi";
import { TbPresentationAnalytics } from "react-icons/tb";
import { Store } from "../Redux/store";
import { loadingData } from "../Redux/Actions/buttonClick";
import { toast } from "react-toastify";
import axios from "axios";
import { NodeServer_API } from "../server";
import { useSelector } from "react-redux";
import SelectDropdown from "../Component/Dropdwon/Dropdown";
import { useCookies } from "react-cookie";

// const camelize = (str) => {
//     return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
//       return index === 0 ? word.toLowerCase() : word.toUpperCase();
//     }).replace(/\s+/g, '');
//   }

const Subpockets = ({ setloading, regioncode }) => {
  const [cookies] = useCookies(["Token"]);

  // console.log(regioncode);
  const [pageNumber, setPageNumber] = useState(0);
  const [incomingdata, setIncomingdata] = useState([]);

  const [searchdata, setSearchdata] = useState("");

  const { pocketdata, role } = useSelector((state) => state.datareponse);
  const [selectedRegion, setSelectedRegion] = useState("ML");

  // console.log(role);

  const [exportpro, setExportpro] = useState(false);

  const itemsPerpage = 20; //Number of items to be displayed per page

  const newPagenum = (num) => {
    setPageNumber(num);
  };

  let thirdtabpageCount = Math.ceil(
    (incomingdata === null ? 0 : incomingdata?.length) / itemsPerpage
  );

  const pagesVisited = pageNumber * itemsPerpage;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    FetchAllTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRegion]);

  // useEffect(() => {
  //   FetchAllTransactions();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedRegion]);

  const handleDropdown = (regionprops) => {
    setSelectedRegion(regionprops); // Set the selected value in state
  };

  const FetchAllTransactions = async () => {
    try {
      Store.dispatch(
        loadingData({
          status: true,
          text: "Fetching Subpockets...",
          type: "spin",
        })
      );

      const pocketId =
        role === "DIRECTOR"
          ? pocketdata.find(
              (regionpocket) => regionpocket.country === regioncode
            )?.pocketId
          : role === "DEVELOPER"
          ? pocketdata.find(
              (regionpocket) => regionpocket.country === selectedRegion
            )?.pocketId
          : "";

      const config = {
        method: "GET",
        url: `${NodeServer_API}/pockets/provider/${pocketId}`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      // console.log(config);

      await axios(config).then((response) => {
        if (response.data.success === true) {
          Store.dispatch(
            loadingData({
              status: false,
              text: "",
              type: "spin",
            })
          );

          setIncomingdata(
            response.data.data === null ? [] : response.data.data
          );
        }
      });
    } catch (e) {
      if (e.response.status === 401) {
        setloading({
          // Expired Session
          statuscode: 3,
          message: "Session Expired Re-Login",
          type: 3,
        });
      } else {
        Store.dispatch(
          loadingData({
            status: false,
            text: "",
            type: "spin",
          })
        );

        toast.error(e.response.message);
      }
    }
  };

  const handleHover = () => {
    setExportpro(!exportpro);
  };

  const handleExportCSV = () => {};

  const csvHeaders = [
    { label: "Created At", key: "createdAt" },
    { label: "Pocket ID", key: "pocketId" },
    { label: "Account Number", key: "accountNumber" },
    { label: "Status", key: "status" },
    { label: "Pocket Function", key: "pocketFunction" },
    { label: "Available Balance Currency", key: "availableBalanceCurrency" },
    { label: "Ledger Balance Currency", key: "ledgerBalanceCurrency" },
    { label: "Parent ID", key: "parentId" },
    { label: "Funding Link", key: "fundingLink" },
    { label: "Account Verifier Scheme", key: "accountVerifierScheme" },
    { label: "Account Verifier Number", key: "accountVerifierNumber" },
    { label: "Reference", key: "reference" },

    // Pocket Owner fields
    { label: "Pocket Owner Created At", key: "pocketOwner.createdAt" },
    { label: "Pocket Owner ID", key: "pocketOwner.pocketOwnerId" },
    { label: "Pocket Owner Business Name", key: "pocketOwner.businessName" },
    { label: "Pocket Owner First Name", key: "pocketOwner.firstName" },
    { label: "Pocket Owner Last Name", key: "pocketOwner.lastName" },
    { label: "Pocket Owner Email Address", key: "pocketOwner.emailAddress" },
    { label: "Pocket Owner Phone Number", key: "pocketOwner.phoneNumber" },
    {
      label: "Pocket Owner Verifier Scheme",
      key: "pocketOwner.ownerVerifierScheme",
    },
    {
      label: "Pocket Owner Verifier Number",
      key: "pocketOwner.ownerVerifierNumber",
    },
    { label: "Pocket Owner Public Key", key: "pocketOwner.publicKey" },
    { label: "Pocket Owner Parent ID", key: "pocketOwner.parentId" },
  ];

  const handleChange = (e) => {
    setSearchdata(e.target.value);
  };

  // Ensure all fields being filtered are defined
  var newdataset = incomingdata?.filter((filteredItem) => {
    if (searchdata === "") {
      return filteredItem;
    } else {
      const searchLower = searchdata.toLowerCase();

      return (
        (filteredItem?.pocketId?.toLowerCase() || "").includes(searchLower) ||
        (filteredItem?.reference?.toLowerCase() || "").includes(searchLower) ||
        (filteredItem?.accountNumber?.toLowerCase() || "").includes(searchLower)
      );
    }
  });

  // Flatten the data to include pocketOwner fields
  const flattenedData = newdataset?.map((item) => {
    const { pocketOwner, ...rest } = item;
    return {
      ...rest,
      ...Object.fromEntries(
        Object.entries(pocketOwner || {}).map(([key, value]) => [
          `pocketOwner.${key}`,
          value,
        ])
      ),
    };
  });

  return (
    <div className="w-full px-2 min-h-screen">
      <div className=" w-full flex flex-col gap-1 relative">
        <div className="justify-between flex  bg-[#e50000] p-1">
          <div className="flex itmes-center w-full  gap-1">
            <div
              className={`relative border flex justify-center items-center bg-[#fff9]
                    }`}
            >
              <span className="flex items-center px-2 py-1 text-center cursor-pointer text-[#fff] text-[12px] font-[600]">
                SUBPOCKET
              </span>

              <TbPresentationAnalytics size={25} color="#fff" />
            </div>
          </div>

          {/* <CSVLink data={flattenedData} headers={csvHeaders}>
            <div
              className={`relative border px-2 py-1 cursor-pointer rounded-md flex justify-center items-center ${
                exportpro ? "bg-[white]" : "bg-[#e50000]"
              }`}
              onMouseEnter={handleHover}
              onMouseLeave={handleHover}
              onClick={handleExportCSV}
            >
              <FiUpload size={18} color={exportpro ? "#e50000" : "#fff"} />
              <span
                className={`px-2 py-1 cursor-pointer ${
                  exportpro ? "text-[#e50000]" : "text-[#fff]"
                } text-[12px] font-[600]`}
              >
                Export
              </span>
            </div>
          </CSVLink> */}

          {role === "DEVELOPER" && (
            <SelectDropdown selectRegion={handleDropdown} />
          )}
        </div>

        <div className="flex w-full flex-col gap-1">
          <div className="w-full flex-1 bg-[#e50000] p-1 flex items-center justify-start gap-4">
            <input
              onChange={handleChange}
              value={searchdata}
              type="text"
              className="border text-[13px] px-2 py-1 min-w-[200px] w-[30%] outline-none rounded-md"
              placeholder="Search any text ..."
            />
          </div>

          <div className="flex w-full flex-col gap-2 overflow-x-auto">
            <table className="min-h-[200px]">
              <thead>
                <tr>
                  {flattenedData && flattenedData.length > 0 ? (
                    Object.keys(flattenedData[0]).map((key) => (
                      <th
                        key={key}
                        className="text-[11px] whitespace-normal break-words w-[120px] border"
                      >
                        {key.toUpperCase()}
                      </th>
                    ))
                  ) : (
                    <th className="text-[11px] whitespace-normal break-words w-[120px]">
                      No data available
                    </th>
                  )}
                </tr>
              </thead>

              <tbody>
                {flattenedData && flattenedData.length > 0 ? (
                  flattenedData
                    .slice(pagesVisited, pagesVisited + itemsPerpage)
                    .map((item, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        {Object.values(item).map((value, i) => (
                          <td
                            key={i}
                            className="text-[11px] whitespace-normal break-words"
                          >
                            {typeof value === "object"
                              ? JSON.stringify(value)
                              : value}
                          </td>
                        ))}
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td
                      colSpan={
                        flattenedData && flattenedData.length > 0
                          ? Object.keys(flattenedData[0]).length
                          : 1
                      }
                      className="text-center py-4"
                    >
                      No results found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="bnw-table-pgnate-contain">
            <ButtonPagenate
              updatedPageNum={newPagenum}
              pageCount={thirdtabpageCount}
              pageNumber={pageNumber}
              previousLabel="Prev"
              nextLabel="Next"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subpockets;
