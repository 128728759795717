import React, { useState, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import "./allocate.css";
import axios from "axios";
import { useCookies } from "react-cookie";
import { FaChevronDown } from "react-icons/fa";
// import Regionsjson from '../Cards/regionsjson';
import CurrencyInput from "react-currency-input-field";
import { useSelector } from "react-redux";
import { NodeServer_API } from "../../server";
import { Store } from "../../Redux/store";
import { seerbitPocketData } from "../../Redux/Actions/dataResponse";
import { toast } from "react-toastify";
import { captureModalOverlay } from "../../Redux/Actions/buttonClick";

const Allocate = ({ setloading }) => {
  const { regionlist } = useSelector((state) => state.datareponse);

  const { financialdata } = useSelector((state) => state.datareponse);

  const [cookies] = useCookies(["Token"]);
  const [region, setRegion] = useState(false);
  const [allocation, setAllocation] = useState(null);
  const [regionCode, setRegionCode] = useState({
    rc: "",
    rn: "",
  });

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setloading({
  //     statuscode: 1,
  //     message: "Allocating Fund",
  //     type: 1,
  //   });

  //   try {
  //     // console.log(allocation, financialdata.amount_left);
  //     if (allocation < financialdata.amount_left) {
  //       if (allocation > 100) {
  //         var config = {
  //           method: "POST",
  //           url: `${API}/admin/allocate_funds`,
  //           data: {
  //             allocation,
  //             region_code: regionCode.rc,
  //           },
  //           headers: {
  //             Authorization: "Bearer " + cookies.Token,
  //           },
  //         };

  //         await axios(config).then((response) => {
  //           if (response.data.success === true) {
  //             Store.dispatch(
  //               getAllocation(
  //                 response.data.data === null ? [] : response.data.data
  //               )
  //             );

  //             FetchamountDue();
  //           }
  //         });
  //       } else {
  //         setloading({
  //           statuscode: 0,
  //           message: "",
  //           type: "",
  //         });

  //         toast.warning("Mimimum allocation is $100");
  //       }
  //     } else {
  //       setloading({
  //         statuscode: 0,
  //         message: "",
  //         type: "",
  //       });
  //       toast.warning("Allocation more than Available Balance!");
  //     }
  //   } catch (e) {
  //     if (e.response.status === 401) {
  //       setloading({
  //         // Expired Session
  //         statuscode: 3,
  //         message: "Session Expired Re-Login",
  //         type: 3,
  //       });
  //     } else {
  //       setloading({
  //         statuscode: 2,
  //         message: e.message,
  //         type: 2,
  //       });
  //     }
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setloading({
      statuscode: 1,
      message: "Allocating Fund",
      type: 1,
    });

    try {
      if (allocation < financialdata.amount_left) {
        if (allocation >= 1) {
          var config = {
            method: "POST",
            url: `${NodeServer_API}/allocation/ptoptransfer`,
            data: {
              allocation,
              region_code: regionCode.rc,
            },
            headers: {
              Authorization: "Bearer " + cookies.Token,
            },
          };

          await axios(config).then((response) => {
            if (response.data.success === true) {
              Store.dispatch(seerbitPocketData(response.data.data));
              setloading({
                statuscode: 0,
                message: "",
                type: "",
              });
              toast.success(response.data.message);
            }
          });
        } else {
          setloading({
            statuscode: 0,
            message: "",
            type: "",
          });

          toast.warning("Mimimum allocation is $1");
        }
      } else {
        setloading({
          statuscode: 0,
          message: "",
          type: "",
        });
        toast.warning("Allocation more than Available Balance!");
      }
    } catch (e) {
      if (e.response.status === 401) {
        setloading({
          // Expired Session
          statuscode: 3,
          message: "Session Expired Re-Login",
          type: 3,
        });
      } else {
        toast.error(e.response.data);
        setloading({
          statuscode: 0,
          message: "",
          type: "",
        });
      }
    }
  };

  // const FetchamountDue = async () => {
  //   try {
  //     var config = {
  //       method: "GET",
  //       url: `${API}/finance/home`,
  //       headers: {
  //         Authorization: "Bearer " + cookies.Token,
  //       },
  //     };

  //     await axios(config).then((response) => {
  //       if (response.data.success === true) {
  //         Store.dispatch(financialData(response.data.data));
  //         toast.success("Successfully Allocated Fund");
  //         setloading({
  //           statuscode: 0,
  //           message: "",
  //           type: "",
  //         });
  //       }
  //     });
  //   } catch (e) {
  //     // setLoading(false);
  //   }
  // };

  const handleClose = () => {
    Store.dispatch(captureModalOverlay("close"));
  };

  const handleRegionFocus = () => {
    setRegion(!region);
  };

  const handleDrpdwnClick = (e, incoming) => {
    // // console.log(incoming)
    setRegionCode(incoming);
    setRegion(false);
  };

  return (
    <div className="overlay-container z-20 ">
      <div className="overlay-addemployee-container">
        <div className="top-container-apprais-notif">
          <span>ALLOCATE FUND</span>
          <AiOutlineCloseCircle id="closeIcon-notifc" onClick={handleClose} />
        </div>

        <form
          onSubmit={handleSubmit}
          className="top-addemployee-container-body"
        >
          <div className="form-row-addrow">
            <div className="bnw-dirname-container">
              {/* <Select onChange={setTofield} options={ Regionsjson } className='bnw-regionfield' placeholder='Regions'/> */}
              <input
                value={regionCode.rn}
                onFocus={handleRegionFocus}
                placeholder="Country"
                id="bnw-firname-box"
              />
              <FaChevronDown
                onClick={handleRegionFocus}
                className="bnw-chevrondown"
              />
              {region && (
                <div className="bnw-region-drpdwn">
                  {regionlist.map((item, key) => (
                    <span
                      onClick={(e) =>
                        handleDrpdwnClick(e, {
                          rc: item.region_code,
                          rn: item.name,
                        })
                      }
                      key={key}
                    >
                      {item.name}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="form-row-addrow">
            <div className="bnw-dirname-container">
              <CurrencyInput
                id="input-example"
                name="input-name"
                placeholder="$ 0.00"
                decimalsLimit={2}
                prefix="$"
                onValueChange={(value, name) => setAllocation(value)}
              />
            </div>
          </div>

          <div className="top-addemployee-submit-row">
            <button id="broadcast-buttn" type="submit">
              SUBMIT
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Allocate;
