import React from "react";
import ReactLoading from "react-loading";
import "./Loading.css";

const BoxLoading = ({ type, color, text }) => (
  <div className="absolute bg-[#0000001c] w-full h-full flex justify-center items-center top-0 left-0 z-20">
    <ReactLoading type={type} color={"grey"} height={"10%"} width={"10%"} />
  </div>
);

export default BoxLoading;
