// reducers.js
import { createAction, createReducer } from "@reduxjs/toolkit";

const fetchAirlineLookupRequest = createAction("fetchAirlineLookupRequest");
const fetchAirlineLookupSuccess = createAction("fetchAirlineLookupSuccess");
const fetchAirlineLookupFail = createAction("fetchAirlineLookupFail");

const initialState = {
  lookup: [],
  isLoading: false,
  isAuthenticated: false,
};

export const airlineLookupReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchAirlineLookupRequest, (state, action) => {
      state.isLoading = true;
    })

    .addCase(fetchAirlineLookupSuccess, (state, action) => {
      state.isAuthenticated = true;
      state.isLoading = false;
      state.lookup = action.payload;
    })

    .addCase(fetchAirlineLookupFail, (state, action) => {
      state.isAuthenticated = false;
      state.isLoading = false;
      state.error = action.payload;
    });
});
