import React, { useState, useEffect } from "react";
import ButtonPagenate from "../Component/ButtonPagenate/ButtonPagenate";
import { IoStatsChartOutline } from "react-icons/io5";
import { CSVLink } from "react-csv";
import { FiUpload } from "react-icons/fi";
import { useSelector } from "react-redux";
import { GoArrowLeft } from "react-icons/go";
import { Store } from "../Redux/store";
import { captureTopPerforming } from "../Redux/Actions/buttonClick";
import NumberFormat from "react-number-format";
import currencyUnit from "./Claims/currencyUnit";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import formatDateTime from "../Component/DateSelector/Datefunction";

const Claimsequivalent = ({ claimseqv }) => {
  // console.log(claimseqv);
  const { flownticketsum } = useSelector((state) => state.datareponse);
  const [exportpro, setExportpro] = useState(false);
  const [gobackbut, setGobackbut] = useState(false);
  const [flowndata, setFlowndata] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setFlowndata(claimseqv);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const statusObj = {
    1: "Approved",
    2: "Pending",
    3: "Declined",
    4: "",
  };

  const csvHeaders = [
    { label: "Id", key: "id" },
    { label: "Country", key: "region" },
    { label: "Flown Ticket", key: "flown_ticket" },
    { label: "Unflown Ticket", key: "unflown_ticket" },
    { label: "Uncaptured Ticket", key: "uncaptured_ticket" },
    { label: "Total Ticket", key: "total_ticket" },
  ];

  const handleHover = () => {
    setExportpro(!exportpro);
  };

  const newPagenum = (num) => {
    setPageNumber(num);
  };

  const handleExportCSV = () => {};

  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerpage = 15; //Number of items to be displayed per page
  const pageCount = Math.ceil(flowndata.length / itemsPerpage);
  const pagesVisited = pageNumber * itemsPerpage;

  const handleCountryClick = () => {};

  const handle_Hover = () => {
    setGobackbut(!gobackbut);
  };

  const handleBackclick = () => {
    Store.dispatch(captureTopPerforming(0));
  };

  const Pointsum = flowndata.reduce(
    (accumulator, currentValue) => accumulator + currentValue.points,
    0
  );

  const CumTicketEquv_USD = flowndata
    .reduce((accumulator, currentValue) => {
      const equivalentSumUSD =
        (currentValue.points * currentValue.local_per_ticket) /
          currentValue.local_per_usd !==
        Infinity
          ? Math.ceil(
              ((currentValue.points * currentValue.local_per_ticket) /
                currentValue.local_per_usd) *
                10
            ) / 10
          : 0;
      return accumulator + equivalentSumUSD;
    }, 0)
    .toFixed(1);

  return (
    <div className="w-full h-full overflow-y-auto z-20 flex flex-col absolute top-0 gap-2 bg-[whitesmoke]">
      <div className="w-full flex flex-col gap-1">
        <div className="w-full justify-between flex items-center bg-[#e50000] p-1">
          <div className="w-full flex items-center gap-3">
            <span
              className={`p-2 border rounded-full cursor-pointer ${
                gobackbut ? "bg-[#fff]" : "bg-[#e50000]"
              }`}
              onMouseEnter={handle_Hover}
              onMouseLeave={handle_Hover}
              onClick={handleBackclick}
            >
              <GoArrowLeft size={18} color={gobackbut ? "#e50000" : "#fff"} />
            </span>
            <div className="relative flex justify-center items-center">
              <span className="flex items-center px-2 py-1 text-center cursor-pointer text-[#fff] text-[13px] font-[600]">
                Claims Breakdown
              </span>

              <IoStatsChartOutline size={25} color="#fff" />
            </div>
          </div>

          <CSVLink data={flowndata} headers={csvHeaders}>
            <div
              className={`relative border px-2 py-1 cursor-pointer rounded-md flex justify-center items-center ${
                exportpro ? "bg-[white]" : "bg-[#e50000]"
              }`}
              onMouseEnter={handleHover}
              onMouseLeave={handleHover}
              onClick={handleExportCSV}
            >
              <FiUpload size={18} color={exportpro ? "#e50000" : "#fff"} />
              <span
                className={`px-2 py-1 cursor-pointer ${
                  exportpro ? "text-[#e50000]" : "text-[#fff]"
                } text-[11px] font-[600]`}
              >
                Export
              </span>
            </div>
          </CSVLink>
        </div>

        <table>
          <thead>
            <tr>
              {/* <th scope="col">Id</th> */}
              <th scope="col" className="text-[13px]">
                SN
              </th>
              <th scope="col" className="text-[13px]">
                Agency Name
              </th>
              <th scope="col" className="text-[13px]">
                Country
              </th>
              <th scope="col" className="text-[13px]">
                Points
              </th>
              <th scope="col" className="text-[13px]">
                Tkt/Local
              </th>
              <th scope="col" className="text-[13px]">
                USD/Local
              </th>
              <th scope="col" className="text-[13px]">
                Point Equv.
              </th>
              <th scope="col" className="text-[13px]">
                USD Equv.
              </th>
              <th scope="col" className="text-[13px]">
                Pay. Mode
              </th>
              <th scope="col" className="text-[13px]">
                Date
              </th>
              <th scope="col" className="text-[13px]">
                Status
              </th>
            </tr>
          </thead>

          <tbody>
            {flowndata
              .map((tdata, index) => (
                <Tooltip
                  TransitionComponent={Zoom}
                  title={tdata.last_name + " " + tdata.first_name}
                  followCursor
                >
                  <tr className="body-tab-row" key={index}>
                    {/* <td data-label="Id">{tdata.id}</td> */}
                    <td data-label="SN" className="text-[11px]">
                      {index + 1}
                    </td>
                    <td data-label="Agency Name" className="text-[11px]">
                      {tdata.agency_name}
                    </td>

                    <td data-label="Country" className="text-[11px]">
                      {
                        currencyUnit.find(
                          (currencyunit) =>
                            currencyunit.region_code === tdata.region_code
                        ).name
                      }
                    </td>

                    <td data-label="Points" className="text-[11px]">
                      {tdata.points}
                    </td>

                    <td data-label="Tkt/Local Rate" className="text-[11px]">
                      <NumberFormat
                        value={tdata.local_per_ticket}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={tdata.currency + " "}
                      />
                    </td>

                    <td data-label="USD/Local Rate" className="text-[11px]">
                      <NumberFormat
                        value={tdata.local_per_usd}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={tdata.currency + " "}
                      />
                    </td>

                    <td data-label="Point Equivalent" className="text-[11px]">
                      <NumberFormat
                        value={tdata.points_equivalent}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={tdata.currency + " "}
                      />
                    </td>

                    <td data-label="USD Equv." className="text-[11px]">
                      <NumberFormat
                        value={
                          tdata.amountPerLocalCurrency === 0
                            ? "rate not set"
                            : parseFloat(
                                Math.ceil(
                                  ((tdata.points * tdata.local_per_ticket) /
                                    tdata.local_per_usd) *
                                    10
                                ) / 10
                              ).toFixed(1)
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={tdata.amountPerLocalCurrency === 0 ? "" : " $"}
                      />
                    </td>

                    <td data-label="Date" className="text-[11px]">
                      {tdata.payment_info.payment_mode}
                    </td>

                    <td data-label="Date" className="text-[11px]">
                      {formatDateTime(tdata.updated_at)}
                    </td>

                    <td data-label="Status">
                      {tdata.claim_status !== "" ? (
                        <div
                          id={
                            tdata.claim_status === 0
                              ? "bnw-tab-status_1"
                              : tdata.claim_status === 1
                              ? "bnw-tab-status"
                              : "bnw-tab-status_2"
                          }
                          className="text-[11px]"
                        >
                          {tdata.claim_status === 0
                            ? statusObj[2]
                            : tdata.claim_status === 1
                            ? statusObj[1]
                            : tdata.claim_status === 2
                            ? statusObj[3]
                            : statusObj[4]}
                        </div>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                </Tooltip>
              ))
              .slice(pagesVisited, pagesVisited + itemsPerpage)}

            <tr className="body-tab-roww text-[13px]">
              <td data-label="SN">Total</td>
              <td data-label="Agency Name">Total</td>
              <td data-label="Country"></td>
              <td data-label="Points">{Pointsum}</td>
              <td data-label="Tkt/Local"></td>
              <td data-label="USD/Local"></td>
              <td data-label="Point Equv."></td>
              <td data-label="USD Equv.">
                <NumberFormat
                  value={CumTicketEquv_USD}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                />
              </td>
              <td data-label="Pay. Mode"></td>
              <td data-label="Date"></td>
              <td data-label="Status"></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="bnw-table-pgnate-contain">
        <ButtonPagenate
          updatedPageNum={newPagenum}
          pageCount={pageCount}
          pageNumber={pageNumber}
          previousLabel="Prev"
          nextLabel="Next"
        />
      </div>
    </div>
  );
};

export default Claimsequivalent;
