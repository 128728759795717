import { createAction, createReducer } from "@reduxjs/toolkit";
const rateCreateRequest = createAction("rateCreateRequest");
const rateCreateSuccess = createAction("rateCreateSuccess");
const rateCreateFail = createAction("rateCreateFail");
const ExchangeRateList = createAction("ExchangeRateList");

const initialState = {
  isLoading: false,
  exchangerate: [],
  isAuthenticated: false,
};

export const exchangeRateReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(rateCreateRequest, (state, action) => {
      state.isLoading = true;
    })

    .addCase(rateCreateSuccess, (state, action) => {
      state.isLoading = false;
      state.exchangerate = action.payload;
    })

    .addCase(rateCreateFail, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })

    .addCase(ExchangeRateList, (state, action) => {
      state.isLoading = false;
      state.exchangerate = action.payload;
    });
});
