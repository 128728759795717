export const getAllAgencies = (agenciesList) => ({
  type: "AgencyList",
  payload: agenciesList,
});

export const getAgenciesCount = (agenciescount) => ({
  type: "AgencyCount",
  payload: agenciescount,
});

export const getAllDirectors = (directorList) => ({
  type: "DirectorList",
  payload: directorList,
});

export const getActiveClaims = (activeclaims) => ({
  type: "ActiveClaims",
  payload: activeclaims,
});

export const getApprovedClaims = (approvedclaims) => ({
  type: "ApprovedClaims",
  payload: approvedclaims,
});

export const getCanceledClaims = (canceledclaims) => ({
  type: "CanceledClaims",
  payload: canceledclaims,
});

export const getRole = (role) => ({
  type: "Role",
  payload: role,
});

export const getPrivileges = (privileges) => ({
  type: "Privileges",
  payload: privileges,
});

export const getName = (fullname) => ({
  type: "Fullname",
  payload: fullname,
});

export const getAllocation = (allocations) => ({
  type: "AllocationsList",
  payload: allocations,
});

export const financialData = (finacialdata) => ({
  type: "FinancialAllocation",
  payload: finacialdata,
});

export const pointBreakdown = (prointsdata) => ({
  type: "PointBreakdown",
  payload: prointsdata,
});

export const homepageData = (homepagedata) => ({
  type: "HomepageData",
  payload: homepagedata,
});

export const paystackWalletBalance = (paystackdata) => ({
  type: "PaystackWalletBalance",
  payload: paystackdata,
});

export const paystackRecipientList = (paystacklist) => ({
  type: "PaystackRecipientList",
  payload: paystacklist,
});

export const paystackTotalDisbursed = (totaldisbursed) => ({
  type: "PaystackTotalDisbursed",
  payload: totaldisbursed,
});

export const paystackTransactionHistory = (transactionhistory) => ({
  type: "PaystackTransactionHistory",
  payload: transactionhistory,
});

export const pointsData = (pointsdata) => ({
  type: "PointsData",
  payload: pointsdata,
});

export const marketallocationData = (marketdata) => ({
  type: "MarketallocationData",
  payload: marketdata,
});

export const transactionhistoryData = (transactdata) => ({
  type: "TransactionhistoryData",
  payload: transactdata,
});

export const claimsData = (claimsdata) => ({
  type: "ClaimsData",
  payload: claimsdata,
});

export const flownticketSumsData = (flownticketdata) => ({
  type: "FlownticketSumsData",
  payload: flownticketdata,
});

export const seerbitPocketData = (pocketdata) => ({
  type: "SeerbitPocketData",
  payload: pocketdata,
});

export const seerbitTransactionHistory = (transhistory) => ({
  type: "SeerbitTransactionHistory",
  payload: transhistory,
});

export const getAllProviders = (paymentproviders) => ({
  type: "AvailableProviders",
  payload: paymentproviders,
});

export const getPaymentOptions = (paymentoptions) => ({
  type: "AvailablePaymentoptions",
  payload: paymentoptions,
});

export const getAllAgenciespcc = (agencylist) => ({
  type: "ListofAgencies",
  payload: agencylist,
});
