import React, { useState } from "react";
import "./addoverlay.css";
import { CgCloseO } from "react-icons/cg";
import Error from "../Error";
import Loader from "../Loading/Loading";
import { toast } from "react-toastify";
import { createRegion } from "../../Redux/Actions/region";
import { useDispatch } from "react-redux";
// import PasswordInputField from '../Passwordverify/password';
// import ConfirmPasswordInputField from '../Passwordverify/Confirmpassword';

const AddRegions = ({ closeOverlay }) => {
  const dispatch = useDispatch();
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");

  const [confirmload, setConfirmload] = useState(false);
  const [confirmresponse, setConfirmresponse] = useState(0);
  const [expiration, setExpiration] = useState();
  const [responses, setResponses] = useState();
  const [errrespnse, setErrresponse] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setConfirmload(true);

    const regionObj = {
      name: firstname,
      region_code: lastname,
    };

    if (regionObj.name !== "" && regionObj.region_code !== "") {
      const response = await dispatch(createRegion(regionObj));
      if (response.type === "regionCreateSuccess") {
        setConfirmload(false);
        toast.success(response.message);
      } else {
        toast.error(response.data.message);
        setConfirmload(false);
      }
    } else {
      setConfirmload(false);
      setErrresponse("Incomplete Fields");
      setConfirmresponse(2);
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "fname") {
      setFirstname(e.target.value);
    }

    if (e.target.name === "lname") {
      setLastname(e.target.value);
    }
  };

  const closeConfirm = () => {
    closeOverlay();
  };

  const handleClose = () => {
    setConfirmresponse(0);
    closeOverlay();
  };

  return (
    <div className="main-add-dir-container">
      <div className="bnw-addirector-pg">
        <form className="bnw-addirector-contain" onSubmit={handleSubmit}>
          <div className="bnw-direct-heading">
            <span>Add New Region</span>
            <CgCloseO id="bnw-close-icon" onClick={closeConfirm} />
          </div>
          {/* <div className='bnw-dirname-container'>
              <Select onChange={setTofield} options={ Regionsjson } className='bnw-regionfield' placeholder='Regions'/>
            </div> */}

          <div className="bnw-dirname-container">
            <input
              id="bnw-firname-box"
              placeholder="Set Region Name (e.g. Mali)"
              name="fname"
              value={firstname}
              onChange={handleChange}
              type="text"
            />
          </div>

          <div className="bnw-dirname-container">
            <input
              id="bnw-firname-box"
              placeholder="Set Region Code (e.g. MI)"
              name="lname"
              value={lastname}
              onChange={handleChange}
              type="text"
            />
          </div>

          <button id="bnw-submit-butn">SUBMIT</button>
        </form>
        {confirmload && <Loader type="spin" text="Sending Data..." />}
        {confirmresponse === 1 ? (
          <Error
            closeOverlay={handleClose}
            message={responses}
            icon="1"
            isTimeout={false}
          />
        ) : confirmresponse === 2 ? (
          <Error
            closeOverlay={handleClose}
            message={errrespnse}
            isTimeout={false}
            icon="2"
          />
        ) : confirmresponse === 3 ? (
          expiration ? (
            <Error
              message={"Session Expired Re-Login"}
              isTimeout={true}
              icon="3"
            />
          ) : (
            <Error
              closeOverlay={handleClose}
              message={"Connection Error"}
              isTimeout={false}
              icon="2"
            />
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default AddRegions;
