export const rolesData = (roledata) => ({
  type: "AvailableRoles",
  payload: roledata,
});

export const priviledgeData = (priviledgedata) => ({
  type: "AvailablePriviledge",
  payload: priviledgedata,
});

export const blacklistedData = (blacklistdata) => ({
  type: "Blacklist",
  payload: blacklistdata,
});

export const userpriviledgeData = (priviledgedata) => ({
  type: "UserAvailablePriviledge",
  payload: priviledgedata,
});
