export const Mixedticketequivalent = (flown, exrate) => {
  // Step 1: Append "amountPerDollar" and "amountPerLocalCurrency" to array A
  const extendedflown = flown?.map((itemA) => {
    const matchingItemB = exrate?.find(
      (itemB) => itemB.region_code === itemA.region_code
    );
    if (matchingItemB) {
      return {
        ...itemA,
        amountPerDollar: matchingItemB.amountPerDollar,
        amountPerLocalCurrency: matchingItemB.amountPerLocalCurrency,
      };
    } else {
      return itemA;
    }
  });

  // Step 2: Generate "dollareqv" field
  const arrayWithDollareqv = extendedflown.map((item) => ({
    ...item,
    dollareqv: (item.amountPerDollar / item.amountPerLocalCurrency).toFixed(2),
  }));

  // console.log(arrayWithDollareqv);

  // Step 3: Regroup into TeamI and TeamII
  const groupedTeams = arrayWithDollareqv.reduce((result, item) => {
    const teamKey = item.region_code === "NG" ? "TeamI" : "TeamII";
    const year = new Date(item.created_at).getFullYear().toString();
    if (!result[year]) {
      result[year] = {
        TeamI: Array.from({ length: 12 }).fill(0),
        TeamII: Array.from({ length: 12 }).fill(0),
      };
    }
    const monthIndex = new Date(item.created_at).getMonth();
    result[year][teamKey][monthIndex] += parseFloat(item.dollareqv);
    return result;
  }, {});

  // Step 4: Round values to 2 decimal places
  for (const year in groupedTeams) {
    for (const teamKey in groupedTeams[year]) {
      groupedTeams[year][teamKey] = groupedTeams[year][teamKey].map((value) =>
        parseFloat(value.toFixed(2))
      );
    }
  }

  return groupedTeams;
};
