import React from "react";
import NumberFormat from "react-number-format";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { useSelector } from "react-redux";

const TransactionsHistory = ({
  text1,
  tooltip_text,
  curr_date,
  amount,
  status,
  regioncode,
}) => {
  const { pocketdata } = useSelector((state) => state.datareponse);

  // console.log(pocketdata);
  // console.log(regioncode);
  return (
    <Tooltip TransitionComponent={Zoom} title={tooltip_text} followCursor>
      <div className="grow flex min-w-[220px] h-full w-[48%] flex-col gap-3">
        <div className="flex justify-between">
          <span className="text-[10px] font-[500] walletbox:text-[15px]">
            {text1}
          </span>
          <span className="text-[10px] font-[400] walletbox:text-[12px] wallet:font-[400]">
            {curr_date}
          </span>
        </div>

        <div className="flex w-full gap-3 items-start relative">
          <span className="text-[28px] font-[700]">
            <NumberFormat
              value={amount}
              displayType={"text"}
              thousandSeparator={true}
              prefix={
                pocketdata?.find(
                  (regionpocket) => regionpocket.country === regioncode
                )?.currency + " "
              }
            />
          </span>
          <span
            className={`absolute right-0 rounded-full px-4 py-1 text-[10px] font-[600] ${
              status === "Sent"
                ? "text-[#FFFF00] bg-[#333]"
                : "text-[#66FF00] bg-[#87A96B]"
            } walletbox:text-[10px] wallet:font-[400]`}
          >
            {status}
          </span>
        </div>
      </div>
    </Tooltip>
  );
};

export default TransactionsHistory;
