import React, { useState } from "react";
import "./psswrd.css";
import "../Cards/addoverlay.css";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";

const PasswordInputField = ({
  handleValidation,
  handlePasswordChange,
  passwordValue,
  passwordError,
}) => {
  const [visible, setVisible] = useState(true);

  const handleVisIcon = () => {
    setVisible(!visible);
  };

  return (
    <div className="flex grow relative flex-col items-center gap-1">
      <input
        required
        id="bnw-firname-boxx"
        placeholder="New Password"
        type={visible ? "password" : "text"}
        value={passwordValue}
        onChange={handlePasswordChange}
        onKeyUp={handleValidation}
        name="password"
        className="form-control text-[12px] border border-gray-300 py-2 px-3 w-[30%] min-w-[180px] outline-none hover:border-gray-500 focus:border-blue-500 rounded-sm grow"
      />
      <p className="text-danger">{passwordError}</p>
      {visible ? (
        <MdOutlineVisibility id="visib-icon" onClick={handleVisIcon} />
      ) : (
        <MdOutlineVisibilityOff id="visib-icon" onClick={handleVisIcon} />
      )}
    </div>
  );
};
export default PasswordInputField;
