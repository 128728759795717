import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import axios from "axios";
import { NodeServer_API } from "../server";
import { Store } from "../Redux/store";
import { rolesData } from "../Redux/Actions/roles";
import { toast } from "react-toastify";
import { loadingData } from "../Redux/Actions/buttonClick";
import ButtonPagenate from "./ButtonPagenate/ButtonPagenate";
import { useCookies } from "react-cookie";

const Roles = ({ setloading }) => {
  const [cookies] = useCookies(["Token"]);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedPrivileges, setSelectedPrivileges] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  const { privileges, rolelist, userprivileges } = useSelector(
    (state) => state.rolesData
  );

  let userole = useSelector((state) => state.datareponse.role); // Get role from Redux store

  let usersPrivileges = rolelist?.find(
    (roleoption) => roleoption.role_name === userole
  )?.priviledges;

  const usersprivilegeOptions = usersPrivileges?.map((privilege) => ({
    value: privilege.privilege_id,
    label: privilege.privilege_name,
  }));

  const newPagenum = (num) => {
    setPageNumber(num);
  };

  const itemsPerpage = 20; //Number of items to be displayed per page
  const pagesVisited = pageNumber * itemsPerpage;
  const pageCount = Math.ceil(
    (rolelist === null ? 0 : rolelist.length) / itemsPerpage
  );

  const handlePrivilegeChange = (privileges) => {
    setSelectedPrivileges(privileges || []);
  };

  const handleSave = async (e) => {
    e.preventDefault();

    Store.dispatch(
      loadingData({
        status: true,
        text: "Creating Roles...",
        type: "spin",
      })
    );

    // Construct the payload
    const payload = {
      role_name: selectedRole.toUpperCase(),
      priviledges: selectedPrivileges?.map((privilege) => ({
        privilege_id: privilege.value,
        privilege_name: privilege.label,
      })),
    };

    try {
      var config = {
        method: "POST",
        url: `${NodeServer_API}/roles`,
        data: payload,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      // Send POST request to update the user

      await axios(config).then((response) => {
        // console.log(response.data);
        Store.dispatch(rolesData(response.data.data));
        toast.success(response.data.message);
        Store.dispatch(
          loadingData({
            status: false,
            text: "",
            type: "spin",
          })
        );
      });
    } catch (error) {
      toast.error(error.response.message);

      // Handle error
      if (error.response.status === 401) {
        setloading({
          // Expired Session
          statuscode: 3,
          message: "Session Expired Re-Login",
          type: 3,
        });
      }
    }
  };

  const privilegeOptions = (
    userole === "DEVELOPER" ? privileges : userprivileges
  )?.map((privilege) => ({
    value: privilege.id,
    label: privilege.privilege_name,
  }));

  const handleChange = (e) => {
    setSelectedRole(e.target.value);
  };

  return (
    <div className="flex-1 flex-col flex gap-3">
      <form
        onSubmit={handleSave}
        className="flex-1 bg-[white] p-4 flex flex-col gap-3"
      >
        <div className="flex justify-center items-center">
          <h2 className="text-l font-bold mb-2">
            CREATE ROLES & ASSIGN PRIVILEGES
          </h2>
        </div>

        <div className="flex-1 flex-wrap flex gap-2 items-center">
          <label className="text-[12px] font-medium w-[120px]">Roles :</label>
          <input
            name="role"
            onChange={handleChange}
            className="text-[12px] border border-gray-300 py-2 px-3 w-[30%] min-w-[180px] outline-none hover:border-gray-500 focus:border-blue-500 rounded-sm grow"
            value={selectedRole}
            type="text"
            required
          />
        </div>

        <div className="flex-1 flex-wrap flex gap-2 items-center">
          <h3 className="text-[12px] font-medium w-[120px]">
            Assign Privileges :
          </h3>
          <Select
            options={privilegeOptions}
            isMulti
            value={selectedPrivileges}
            onChange={handlePrivilegeChange}
            placeholder="Select Privileges"
            className="w-[30%] min-w-[150px] text-[12px] rounded-sm grow"
          />
        </div>

        <input
          disabled={
            usersprivilegeOptions?.find(
              (privilege) => privilege.label === "Create Role"
            )?.label === "Create Role"
              ? false
              : true
          }
          type="submit"
          className="btn btn-primary mt-4 text-[14px] font-semibold text-[white] py-2 rounded-sm bg-[#0743077d] hover:bg-[#074307f7] cursor-pointer"
          value={"SAVE CHANGES"}
        />
      </form>

      <div className="flex-1 bg-[white] p-3 flex flex-col gap-2">
        <table className="min-w-full border-collapse">
          <thead>
            <tr>
              <th className="text-[11px] whitespace-normal break-words border px-2 py-1">
                #SN
              </th>
              <th className="text-[11px] whitespace-normal break-words border px-2 py-1">
                Roles
              </th>
              <th className="text-[11px] whitespace-normal break-words border px-2 py-1">
                Privileges
              </th>
            </tr>
          </thead>

          <tbody>
            {(userole !== "DEVELOPER"
              ? rolelist?.filter((items) => items.role_name !== "DEVELOPER")
              : rolelist
            )
              ?.slice(pagesVisited, pagesVisited + itemsPerpage)
              ?.map((tdata, index) => (
                <tr className="body-tab-row cursor-pointer" key={tdata.id}>
                  <td
                    data-label="SN"
                    className="text-[11px] whitespace-normal break-words border px-2 py-1"
                  >
                    {index + 1}
                  </td>

                  <td
                    data-label="Roles"
                    className="text-[11px] whitespace-normal break-words border px-2 py-1"
                  >
                    {tdata.role_name}
                  </td>

                  <td
                    data-label="Previleges"
                    className="text-[11px] whitespace-normal break-words border  py-1"
                  >
                    <ul className="flex flex-wrap gap-1 ">
                      {tdata?.priviledges?.map((privilege) => (
                        <li
                          key={privilege.privilege_id}
                          className="border p-1 text-[10px] hover:border-[#0743077d]"
                        >
                          {privilege.privilege_name}
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        <div className="bnw-table-pgnate-contain">
          <ButtonPagenate
            updatedPageNum={newPagenum}
            pageCount={pageCount}
            pageNumber={pageNumber}
            previousLabel="Prev"
            nextLabel="Next"
          />
        </div>
      </div>
    </div>
  );
};

export default Roles;
