import React, { useState, useEffect } from "react";
import Singleselect from "../Component/Singleselect/Singleselectt";
import "./promorate.css";
import "./table.css";
import ButtonPagenate from "../Component/ButtonPagenate/ButtonPagenate";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Regionsjson from "../Component/Cards/regionsjson";
import Select from "react-select";
import { createExchangeRate } from "../Redux/Actions/exchangeRate";
import { fetchAllRegions } from "../Redux/Actions/region";

const Exchangerate = ({ setloading, closeOverlay }) => {
  const { exchangerate } = useSelector((state) => state.exchangerate);

  const { regionlist } = useSelector((state) => state.regions);
  const { promodata } = useSelector((state) => state.ruleengine);

  const regionOptions = regionlist.map((region) => ({
    value: region.region_code,
    label: region.name,
  }));

  const promoOptions = promodata.map((promo) => ({
    value: promo.id,
    label: promo.name,
  }));

  const dispatch = useDispatch();

  const [currentRate, setCurrentRate] = useState([]);
  const [amount, setAmount] = useState({
    ticketperlocal: "",
    localperusd: "",
    defaultpromoid: "",
  });
  const [country, setCountry] = useState("");
  const [pageNumber, setPageNumber] = useState(0);

  const handleSelected = (incoming) => {
    setCountry(incoming.value);
  };

  const itemsPerpage = 15; //Number of items to be displayed per page
  const pageCount = Math.ceil(currentRate?.length / itemsPerpage);
  const pagesVisited = pageNumber * itemsPerpage;

  useEffect(() => {
    setRegions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurrentRate(exchangerate.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exchangerate]);

  console.log(currentRate);

  const setRegions = async () => {
    dispatch(fetchAllRegions());
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAmount({ ...amount, [name]: value.replace(/\D/g, "") });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const objt = {
      amountPerDollar: parseInt(amount.ticketperlocal),
      amountPerLocalcurrency: parseInt(amount.localperusd),
      region_code: country,
      defaultpromoid: parseInt(amount.defaultpromoid), // Include defaultpromoid
    };

    if (
      amount.amountPerDollar !== "" ||
      amount.amountPerLocalcurrency !== "" ||
      country !== ""
    ) {
      if (
        objt.amountPerDollar > 0 &&
        objt.amountPerLocalcurrency > 0 &&
        objt.defaultpromoid > 0
      ) {
        setloading({
          statuscode: 1,
          message: "Updating Record",
          type: 1,
        });

        const response = await dispatch(createExchangeRate(objt));
        if (response.type === "rateCreateSuccess") {
          setloading({
            statuscode: 0,
            message: "",
            type: "",
          });
        } else {
          if (response.status === 401) {
            setloading({
              // Expired Session
              statuscode: 3,
              message: "Session Expired Re-Login",
              type: 3,
            });
          } else {
            setloading({
              statuscode: 2,
              message: e.message,
              type: 2,
            });
          }
        }
      } else {
        toast.warning("Ensure all fields are filled!");
      }
    }
  };

  const newPagenum = (num) => {
    setPageNumber(num);
  };

  return (
    <div className="bnw-exrt-main-container">
      <div className="bnw-promopg-container">
        <form className="bnw-promopg gap-2" onSubmit={handleSubmit}>
          <p className="text-[17px] font-[700]">Create Exchange Rate</p>
          <div className="bnw-promopg-field-contain">
            <div className="bnw-promopg-field-1">
              <span>Amount/Ticket :</span>
            </div>
            <div className="bnw-promopg-field-2">
              <input
                placeholder="1 Ticket → Local Cur."
                type="text"
                onChange={handleChange}
                value={amount.localperusd}
                id="amout-per-dollar"
                className="text-[13px]"
                name="localperusd"
              />
            </div>
          </div>

          <div className="bnw-promopg-field-contain">
            <div className="bnw-promopg-field-1">
              <span>Currency/USD :</span>
            </div>
            <div className="bnw-promopg-field-2">
              <input
                placeholder="1 USD → Local Cur."
                type="text"
                onChange={handleChange}
                value={amount.ticketperlocal}
                id="amout-per-dollar"
                className="text-[13px]"
                name="ticketperlocal"
              />
            </div>
          </div>

          <div className="bnw-promopg-field-contain">
            <div className="bnw-promopg-field-1">
              <span>Default Promo ID:</span>
            </div>
            <div className="bnw-promopg-field-2">
              <Select
                options={promoOptions}
                onChange={(selectedOption) =>
                  setAmount({ ...amount, defaultpromoid: selectedOption.value })
                }
                placeholder="Select Promo"
                className="w-full text-[13px]"
              />
            </div>
          </div>

          <div className="bnw-promopg-field-contain">
            <div className="bnw-promopg-field-1">
              <span>Country</span>
            </div>
            <div className="bnw-promopg-field-2">
              <Select
                options={regionOptions}
                onChange={handleSelected}
                placeholder="Region"
                className="w-full text-[13px]"
              />
            </div>
          </div>

          <div className="bnw-promopg-field-contain">
            <div className="bnw-promopg-field-1" />
            <div className="bnw-promopg-field-2">
              <input className="bnw-promopg-submits" type="submit" />
            </div>
          </div>
        </form>

        <div className="bnw-promopg-exch-table">
          <table>
            <thead>
              <tr className="text-[14px]">
                <th
                  scope="col"
                  className="text-[11px] whitespace-normal break-words border px-2 py-2"
                >
                  Id
                </th>
                <th
                  scope="col"
                  className="text-[11px] whitespace-normal break-words border px-2 py-2"
                >
                  Currency/USD
                </th>
                <th
                  scope="col"
                  className="text-[11px] whitespace-normal break-words border px-2 py-2"
                >
                  Amount/Tkt
                </th>
                <th
                  scope="col"
                  className="text-[11px] whitespace-normal break-words border px-2 py-2"
                >
                  Region Code
                </th>
                <th
                  scope="col"
                  className="text-[11px] whitespace-normal break-words border px-2 py-2"
                >
                  Default Promo ID
                </th>
              </tr>
            </thead>
            <tbody>
              {currentRate
                ?.slice(pagesVisited, pagesVisited + itemsPerpage)
                .map((tdata) => (
                  <tr className="body-tab-row" key={tdata.ID}>
                    <td data-label="Id" className="text-[11px]">
                      {tdata.ID}
                    </td>
                    <td data-label="Amount/Tkt" className="text-[11px]">
                      {tdata.amountPerDollar}
                    </td>
                    <td data-label="Currency/USD" className="text-[11px]">
                      {tdata.amountPerLocalCurrency}
                    </td>
                    <td data-label="Region Code" className="text-[11px]">
                      {
                        Regionsjson.find(
                          (element) => element.value === tdata.region_code
                        )?.label
                      }
                    </td>
                    <td data-label="Default Promo ID" className="text-[11px]">
                      {tdata?.defaultPromo?.name}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="bnw-table-pgnate-contain">
            <ButtonPagenate
              updatedPageNum={newPagenum}
              pageCount={pageCount}
              pageNumber={pageNumber}
              previousLabel="Prev"
              nextLabel="Next"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Exchangerate;
