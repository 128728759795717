import React from "react";
import "./style.css";

const Footer = () => {
  const date = new Date();
  return (
    <div className="footerContainerSup">
      <span id="footer-text" className="text-[13px]">
        Copyright &#169; <b> {date.getFullYear()} Sabre Travel Network</b>. All
        rights Reserved. v-4.0
      </span>
    </div>
  );
};

export default Footer;
