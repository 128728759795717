import axios from "axios";
import { NodeServer_API } from "../../server";
import Cookies from "js-cookie"; // Ensure you import cookies from the correct library
import { toast } from "react-toastify";

// create Exchange Rate
export const createRegion = (formData) => async (dispatch) => {
  try {
    console.log(formData);
    dispatch({
      type: "regionCreateRequest",
    });

    const { data } = await axios.post(
      `${NodeServer_API}/createRegion`,
      formData,
      {
        headers: {
          "Content-Type": "application/json", // Explicitly set content type
          Authorization: `Bearer ${Cookies.get("Token")}`, // Retrieve token from cookies
        },
      }
    );

    dispatch({
      type: "regionCreateSuccess",
      payload: data,
    });

    return {
      type: "regionCreateSuccess",
      message: "Region Created Successfully",
    };
  } catch (error) {
    dispatch({
      type: "regionCreateFail",
      payload: error.response.data.message,
    });

    return { type: "regionCreateFail", error: error.response };
  }
};

// fetch All Regions
export const fetchAllRegions = () => async (dispatch) => {
  try {
    dispatch({
      type: "regionFetchRequest",
    });

    const { data } = await axios.get(`${NodeServer_API}/regions`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("Token")}`, // Retrieve token from cookies
      },
    });

    console.log(data);

    dispatch({
      type: "regionFetchSuccess",
      payload: data.data,
    });
    return {
      type: "regionFetchSuccess",
      message: "Successfully Fetched Regions",
    };
  } catch (error) {
    dispatch({
      type: "regionFetchFail",
      payload: error.response,
    });

    return { type: "regionFetchFail", error: error.response };
  }
};

export const getAllRegions = (regionsList) => ({
  type: "RegionsList",
  payload: regionsList,
});
