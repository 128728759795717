import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";
import Error from "../Component/Error";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import { RiLock2Fill } from "react-icons/ri";
import "./page.css";
import PasswordInputField from "../Component/Passwordverify/password";
import ConfirmPasswordInputField from "../Component/Passwordverify/Confirmpassword";
import LoadVeriy from "../Component/Loading/LoadVeriy";
import { NodeServer_API } from "../server";
import { useDispatch } from "react-redux";
import { fetchAuthentication } from "../Redux/Actions/airlineLookup";

export default function Loginpg() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(2);
  const [loaderror, setLoaderror] = useState({
    statusid: 0,
    message: "",
  });
  const [visible, setVisible] = useState(true);
  const navigate = useNavigate();
  const [cookie, setCookie] = useCookies(["Token"]);
  const [emailvalue, setEmailvalue] = useState();

  const [passwordError, setPasswordErr] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordInput, setPasswordInput] = useState({
    password: "",
    confirmPassword: "",
    otp: "",
  });

  const [emailfield, setEmailfield] = useState("");
  const [passwordfield, setPasswordfield] = useState("");

  const [loadVerify, setLoadVerify] = useState(0);
  const [sendemail, setSendemail] = useState(0);
  // const [expiry, setExpiry] = useState(new Date())

  useEffect(() => {
    setCookie("Token", "undefined", { path: "/" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // setCookie("authtoken", "undefined", { path: "/" });
    dispatch(fetchAuthentication());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  ///********** Handle Submit Button ********///
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoaderror({
        statusid: 1,
        message: "Loading...",
      });
      const obj = {
        email: emailfield,
        password: passwordfield,
      };

      if (obj.email !== "" && obj.password !== "") {
        await axios.post(`${NodeServer_API}/login`, obj).then((response) => {
          if (response.data.code === 200) {
            const objct = {
              // role: response.data.role,
              status: response.data.code,
            };
            navigate("/dashboard", { state: objct });
            setCookie("Token", response.data.token, { path: "/" }); // Set Token To the Cookie Value and make it accessible to all routes
          }
        });
      }
    } catch (e) {
      if (e.code === "ERR_NETWORK") {
        setLoading(2);
        setLoaderror({
          statusid: 2,
          message: "Connection Error, check your Network",
        });
      } else if (e.code === "ERR_BAD_REQUEST") {
        setLoading(2);
        setLoaderror({
          statusid: 2,
          message: e.response.data.message,
        });
      } else if (
        e.message.includes("500") ||
        e.message.includes("502") ||
        e.message.includes("503") ||
        e.message.includes("504")
      ) {
        setLoading(2);
        setLoaderror({
          statusid: 2,
          message: e.response.data.message,
        }); // Bad response 500
      } else if (
        e.message.includes("400") ||
        e.message.includes("401") ||
        e.message.includes("403") ||
        e.message.includes("404") ||
        e.message.includes("408")
      ) {
        setLoading(2);
        setLoaderror({
          statusid: 3,
          message: e.response.data.message,
        }); // Bad response 400
      }
    }
  };

  ///******* Handle Reset Submit *******////
  const handleResetSubmit = async (e) => {
    try {
      e.preventDefault();

      // If Confirm password Error is not returning any error and Confirm password Field is not empty, that suggest a password match
      if (
        confirmPasswordError === "" &&
        passwordError === "" &&
        passwordInput.confirmPassword !== "" &&
        passwordInput.password !== "" &&
        passwordInput.otp !== ""
      ) {
        setLoadVerify(1);
        const obj = {
          password: passwordInput.confirmPassword,
          otp: passwordInput.otp,
          email: emailvalue,
        };

        await axios
          .post(`${NodeServer_API}/directors/resetPassword`, obj)
          .then((response) => {
            console.log(response.data);
            if (response.data.success === true) {
              setLoadVerify(2);
            } else {
              setLoadVerify(3);
            }
          });
      } else {
        setLoading(4);
      }
    } catch (e) {
      console.log(e.response.data);

      setLoading(3);
      setLoaderror({
        statusid: 3,
        message: e.response.data.message,
      });
    }
  };

  ///******** Handle Fields Onchange *******///
  const handleLoginChange = (e) => {
    if (e.target.name === "email") {
      setEmailfield(e.target.value);
      // // console.log(e.target.value)
    }

    if (e.target.name === "password") {
      setPasswordfield(e.target.value);
      // // console.log(e.target.value)
    }
  };

  ///********** Verify That OTP is confirmed ********///
  const Bnwcnpotpfield = async (e) => {
    setPasswordInput({ otp: e.target.value });
  };

  // ///****** Send OTP to Email Address ******///
  // const handleOTPSubmit = async (e) => {
  //   e.preventDefault();
  //   setSendemail(1);
  //   const obj = { email: emailvalue };
  //   await axios
  //     .post(`${NodeServer_API}/directors/resetPassword`, obj)
  //     .then((response) => {
  //       console.log(response.data.data);
  //       if (response.data.success === true) {
  //         setSendemail(2); // Email Sent
  //         setLoading(5); // Move To The Next Page
  //       } else {
  //         setSendemail(3); // Unable to send Email
  //       }
  //     });
  // };

  const handleOTPSubmit = async (e) => {
    e.preventDefault();
    setSendemail(1); // Start sending email
    const obj = { email: emailvalue };

    try {
      const response = await axios.post(
        `${NodeServer_API}/directors/resetPassword`,
        obj
      );

      if (response.data.success) {
        console.log(response.data.data);
        setSendemail(2); // Email Sent
        setLoading(5); // Move To The Next Page
      } else {
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      setSendemail(3);
      setLoaderror({
        statusid: 3,
        message: error.response.data.message,
      });
    }
  };

  //// ****** CLOSE All Error/Confirmation Overlay ****** ///
  const handleClose = () => {
    // setLoaderror(0)
    setLoadVerify(0);
    setSendemail(0);
  };

  const handleCloseConf = () => {
    // setLoaderror(0)
    setLoadVerify(0);
    setSendemail(0);
    window.location.reload(false);
  };

  const handleVisIcon = () => {
    setVisible(!visible);
  };

  const handleGoBack = () => {
    setLoading(2);
  };

  const handleForgotpass = () => {
    setLoading(3);
  };

  const handlePasswordChange = (evnt) => {
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;
    const NewPasswordInput = {
      ...passwordInput,
      [passwordInputFieldName]: passwordInputValue,
    };
    setPasswordInput(NewPasswordInput);
    // // console.log(`setPass: ${JSON.stringify(NewPasswordInput)}`)
  };

  const handleValidation = (evnt) => {
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;
    //for password
    if (passwordInputFieldName === "password") {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{8,}/;
      const passwordLength = passwordInputValue.length;
      const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
      const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
      const digitsPassword = digitsRegExp.test(passwordInputValue);
      const specialCharPassword = specialCharRegExp.test(passwordInputValue);
      const minLengthPassword = minLengthRegExp.test(passwordInputValue);
      let errMsg = "";
      if (passwordLength === 0) {
        errMsg = "Password is empty";
      } else if (!uppercasePassword) {
        errMsg = "At least one Uppercase";
      } else if (!lowercasePassword) {
        errMsg = "At least one Lowercase";
      } else if (!digitsPassword) {
        errMsg = "At least one digit";
      } else if (!specialCharPassword) {
        errMsg = "At least one Special Characters";
      } else if (!minLengthPassword) {
        errMsg = "At least minumum 8 characters";
      } else {
        errMsg = "";
      }
      setPasswordErr(errMsg);
    }

    // for confirm password
    if (
      passwordInputFieldName === "confirmPassword" ||
      (passwordInputFieldName === "password" &&
        passwordInput.confirmPassword.length > 0)
    ) {
      if (passwordInput.confirmPassword !== passwordInput.password) {
        setConfirmPasswordError("Confirm password is not matched");
      } else {
        setConfirmPasswordError("");
      }
    }
  };

  const handlebnwemailconfm = (e) => {
    setEmailvalue(e.target.value);
    // // console.log(e.target.value)
  };

  const handleFocus = () => {
    setLoaderror({
      statusid: 0,
      message: "",
    });
  };

  return (
    <div className="bnw-login-contain">
      {loading === 2 ? (
        <div className="signinContain">
          {loaderror.statusid === 1 ? (
            <LoadVeriy type="spin" text={loaderror.message} />
          ) : loaderror.statusid === 3 ? (
            <Error
              closeOverlay={handleClose}
              message={loaderror.message}
              icon="5"
            />
          ) : (
            ""
          )}
          <form
            className="bnw-login-body w-[40%] min-w-[350px] max-w-[400px]"
            onSubmit={handleSubmit}
          >
            <div className="bnw-lock-icon-container">
              <RiLock2Fill id="bnw-lock-icon" />
            </div>
            <span>SIGN IN</span>

            <input
              onFocus={handleFocus}
              className="bnw-ligin-email text-[13px]"
              name="email"
              placeholder="Email"
              value={emailfield}
              type="email"
              onChange={handleLoginChange}
            />
            <div className="bnw-ligin-email-container">
              <input
                onFocus={handleFocus}
                className="bnw-ligin-password text-[13px]"
                name="password"
                placeholder="Password"
                value={passwordfield}
                type={visible ? "password" : "text"}
                onChange={handleLoginChange}
              />
              {visible ? (
                <MdOutlineVisibility id="visib-icon" onClick={handleVisIcon} />
              ) : (
                <MdOutlineVisibilityOff
                  id="visib-icon"
                  onClick={handleVisIcon}
                />
              )}
            </div>

            {loaderror.statusid === 2 ? (
              <div className="errorpopup">
                <span>{loaderror.message}</span>
              </div>
            ) : (
              ""
            )}

            <div className="forgotpass-cont">
              <span onClick={handleForgotpass}>Forgot Password?</span>
            </div>
            <input className="bnw-login-submit" type="submit" value="SIGN IN" />
          </form>
        </div>
      ) : loading === 3 ? (
        <div className="signinContain">
          {sendemail === 1 ? (
            <LoadVeriy type="spin" text="Sending Confirmation Email" />
          ) : sendemail === 2 ? (
            <Error
              closeOverlay={handleClose}
              message={"Email Sent Successfully"}
              icon="1"
            />
          ) : sendemail === 3 ? (
            <Error
              closeOverlay={handleClose}
              message={"Connection Error"}
              icon="2"
            />
          ) : (
            ""
          )}

          <form className="bnw-login-body" onSubmit={handleOTPSubmit}>
            <div className="bnw-lock-icon-container">
              <RiLock2Fill id="bnw-lock-icon" />
            </div>
            <span>RESET PASSWORD</span>

            <div className="resetpass-bnw">
              <span>
                Enter the Email Asscoiated with your account and we'll send an
                Email OTP to Complete your password reset
              </span>
            </div>

            <input
              className="bnw-ligin-email"
              placeholder="Email Address"
              value={emailvalue}
              onChange={handlebnwemailconfm}
              type="email"
            />

            <div className="forgotpass-cont">
              <span onClick={handleGoBack}>Back</span>
            </div>
            <input
              className="bnw-login-submit"
              type="submit"
              value="Generate 2FA OTP"
            />
          </form>
        </div>
      ) : (
        <div className="signinContain">
          {loadVerify === 1 ? (
            <LoadVeriy type="spin" text="Verifying OTP" />
          ) : loadVerify === 2 ? (
            <Error
              closeOverlay={handleCloseConf}
              message={"Password Changed Successfully"}
              icon="1"
            />
          ) : loadVerify === 3 ? (
            <Error
              closeOverlay={handleClose}
              message={"Invalid OTP"}
              icon="2"
            />
          ) : (
            ""
          )}
          <form
            className="w-[40%] min-w-[290px] h-[450px] bg-[white] shadow-md shadow-gray-800/36 flex flex-col items-center justify-start  gap-[15px]"
            onSubmit={handleResetSubmit}
          >
            <div className="bnw-lock-icon-container">
              <RiLock2Fill id="bnw-lock-icon" />
            </div>
            <span>Create New Password</span>
            <input
              className="bnw-ligin-email"
              placeholder="2FA-Code"
              value={passwordInput.otp}
              type="text"
              onChange={Bnwcnpotpfield}
            />

            <div className="bnw-ligin-email-container">
              <PasswordInputField
                handlePasswordChange={handlePasswordChange}
                handleValidation={handleValidation}
                passwordValue={passwordInput.password}
                passwordError={passwordError}
              />
            </div>

            <div className="bnw-ligin-email-container">
              <ConfirmPasswordInputField
                handlePasswordChange={handlePasswordChange}
                handleValidation={handleValidation}
                confirmPasswordValue={passwordInput.confirmPassword}
                confirmPasswordError={confirmPasswordError}
              />
            </div>

            <div className="forgotpass-cont">
              <span onClick={handleGoBack}>Back To Home</span>
            </div>

            <input
              className="bnw-login-submit"
              type="submit"
              value="Confirm Password Change"
            />
          </form>
        </div>
      )}
    </div>
  );
}
