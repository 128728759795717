import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { captureButtonClick } from "../../Redux/Actions/buttonClick";
import { resetStore } from "../../Redux/store";

const FunctionComponent = ({ id, icon: Icon, label, path, rolesAllowed }) => {
  const { role, activeclaims } = useSelector((state) => state.datareponse);
  const { buttonClicks } = useSelector((state) => state.buttonclick);
  const { userprivileges } = useSelector((state) => state.rolesData);

  const dispatch = useDispatch();

  // if (rolesAllowed?.every((priv) => userprivileges?.includes(priv))) {
  //   return null;
  // }

  if (!rolesAllowed.includes(role)) {
    return null;
  }

  const handleClick = (e) => {
    if (id === "Logout") {
      resetStore();
    } else {
      dispatch(captureButtonClick(id));
    }
  };

  const isActive = buttonClicks === id;

  return (
    <li
      className={`w-full border hover:border-gray-200 rounded-r-md ${
        isActive ? "text-deepgrey border-deepgrey" : "border-transparent"
      }`}
      id={id}
      onClick={handleClick}
    >
      <Link
        to={path}
        className={`no-underline flex items-center p-4 text-sm font-semibold cursor-pointer ${
          isActive ? "text-deepgrey" : ""
        }`}
        id={id}
        onClick={handleClick}
      >
        <span id={id} className={`ml-6 ${isActive ? "#333" : ""}`}>
          <Icon color={isActive ? "#333" : "#A9A9A9"} size={20} />{" "}
          {/* Lilac on active, Gray on inactive */}
        </span>
        <span
          id={id}
          className={`ml-6 text-[12px] ${
            isActive ? "text-deepgrey font-bold" : "text-[#8888]"
          }`}
        >
          {label}
        </span>
        {id === "Claims" && (
          <div className="bg-red-200 text-[11px] text-red-700 px-1.5 rounded-full ml-16">
            {activeclaims.toString().length < 4 ? activeclaims : "10k+"}
          </div>
        )}
      </Link>
    </li>
  );
};

export default FunctionComponent;
