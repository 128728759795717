import React, { useState, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import "../Allocate/allocate.css";
import axios from "axios";
import { useCookies } from "react-cookie";
import { FaChevronDown } from "react-icons/fa";
// import Regionsjson from '../Cards/regionsjson';
import CurrencyInput from "react-currency-input-field";
import { useSelector } from "react-redux";
import { NodeServer_API } from "../../server";
import { Store } from "../../Redux/store";
import {
  financialData,
  getAllocation,
  marketallocationData,
  paystackWalletBalance,
} from "../../Redux/Actions/dataResponse";
import { toast } from "react-toastify";
import { captureModalOverlay } from "../../Redux/Actions/buttonClick";

const Pockets = ({ setloading }) => {
  const { financialdata } = useSelector((state) => state.datareponse);
  const { regionlist } = useSelector((state) => state.datareponse);

  const [cookies] = useCookies(["Token"]);
  const [region, setRegion] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [allocation, setAllocation] = useState(null);
  const [regionCode, setRegionCode] = useState({
    rc: "",
    rn: "",
  });

  const [pocket, setPocket] = useState({
    currency: "",
    countryCode: "",
  });

  // Create Region's Pocket
  const HandlePockets = async (e) => {
    e.preventDefault();
    setloading({
      statuscode: 1,
      message: "Allocating Fund",
      type: 1,
    });

    try {
      // console.log(allocation, financialdata.amount_left);
      if (allocation < financialdata.amount_left) {
        var config = {
          method: "POST",
          url: `${NodeServer_API}/pockets`,
          data: {
            currency: pocket.currency,
            countryCode: pocket.countryCode,
          },
          headers: {
            Authorization: "Bearer " + cookies.Token,
          },
        };

        await axios(config).then((response) => {
          if (response.data.success === true) {
            Store.dispatch(
              getAllocation(
                response.data.data === null ? [] : response.data.data
              )
            );
          }
        });
      } else {
        setloading({
          statuscode: 0,
          message: "",
          type: "",
        });
        toast.warning("Allocation more than Available Balance!");
      }
    } catch (e) {
      if (e.response.status === 401) {
        setloading({
          // Expired Session
          statuscode: 3,
          message: "Session Expired Re-Login",
          type: 3,
        });
      } else {
        setloading({
          statuscode: 2,
          message: e.message,
          type: 2,
        });
      }
    }
  };

  const handleClose = () => {
    Store.dispatch(captureModalOverlay("close"));
  };

  const handleRegionFocus = () => {
    setRegion(!region);
  };

  const handleDrpdwnClick = (e, incoming) => {
    if (incoming.rc === "Nigeria") {
      setToggle(true);
    } else {
      setToggle(false);
    }
    setRegionCode(incoming);
    setRegion(false);
  };

  return (
    <div className="overlay-container z-10 ">
      <div className="overlay-addemployee-container">
        <div className="top-container-apprais-notif">
          <span>CREATE POCKETS</span>
          <AiOutlineCloseCircle id="closeIcon-notifc" onClick={handleClose} />
        </div>

        <form
          onSubmit={HandlePockets}
          className="top-addemployee-container-body"
        >
          <div className="form-row-addrow">
            <div className="bnw-dirname-container">
              {/* <Select onChange={setTofield} options={ Regionsjson } className='bnw-regionfield' placeholder='Regions'/> */}
              <input
                value={regionCode.rn}
                onFocus={handleRegionFocus}
                placeholder="Country"
                id="bnw-firname-box"
              />
              <FaChevronDown
                onClick={handleRegionFocus}
                className="bnw-chevrondown"
              />
              {region && (
                <div className="bnw-region-drpdwn">
                  {regionlist.map((item, key) => (
                    <span
                      onClick={(e) =>
                        handleDrpdwnClick(e, {
                          rc: item.region_code,
                          rn: item.name,
                        })
                      }
                      key={key}
                    >
                      {item.name}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="top-addemployee-submit-row">
            <input id="broadcast-buttn" type="submit" value="SUBMIT" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Pockets;
