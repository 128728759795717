import React, { useState } from "react";
import { BiSearchAlt2 } from "react-icons/bi";
import { HiMenu } from "react-icons/hi";
import Singleselect from "../Singleselect/Singleselect";
import SortByjson from "../Singleselect/filteredBy";
import { useSelector, useDispatch } from "react-redux";
import { searchFilter } from "../../Redux/Actions/searchFilter";
import "./Sidebar.css";

const TopSidebar = ({ menuClicked }) => {
  const dispatch = useDispatch();
  const { fullname, role } = useSelector((state) => state.datareponse);
  const { buttonClicks } = useSelector((state) => state.buttonclick);
  const { criteria, agencyname } = useSelector(
    (state) => state.searchdata.searchtext
  );

  const [searchname, setSearchname] = useState("");

  const handleMenuClick = () => {
    menuClicked();
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchname(value);
    dispatch(searchFilter({ agencyname: value, criteria }));
  };

  const handleSelected = (incoming) => {
    dispatch(searchFilter({ criteria: incoming, agencyname }));
  };

  const renderButtonClickText = () => {
    const buttonClickTexts = {
      Main: "Dashboard",
      Budget: "Budget",
      Claim: "Claims",
      Claims: "Claims",
      Transactions: "Transaction History",
      Directors: "Directors",
      Agencies: "Agencies",
      Wallet: "Report",
      exrate: "Exchange Rate",
      Region: "Regions",
      Settings: "Settings",
      Subpockets: "Subpockets",
    };

    return (
      <span className="text-[20px] font-[700] leading-8 text-[#475366] mr-2">
        {buttonClickTexts[buttonClicks] || ""}
      </span>
    );
  };

  return (
    <div className="bnw-tpsbar-main-container shadow-[0_4px_10px_rgba(0,0,0,0.1)]">
      <div className="bnw-tpsbar-container">
        <HiMenu id="bnw-himenu-icon" onClick={handleMenuClick} />
        <div className="flex items-center topbar:hidden">
          {renderButtonClickText()}
        </div>
        <div className="bnw-searchbox-container">
          <div className="dropdwon-sort">
            <Singleselect
              socialjs={SortByjson}
              selectedSocVal={handleSelected}
              placeholder="Search by"
            />
          </div>
          <div className="bnw-search-box-contain">
            <input
              value={searchname}
              onChange={handleSearch}
              placeholder="Search Content..."
              id="bnw-search-box"
              className="text-[13px]"
            />
            <BiSearchAlt2 id="bnw-search-icon" />
          </div>
          <div className="bnw-profile flex gap-3">
            <div className="bnw-profileImg-container p-1">
              <div id="bnw-profileImg" />
            </div>
            <div className="bnw-profile-name w-full">
              <span className="text-[13px]">{fullname}</span>
              <span className="text-[10px]">{role}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopSidebar;
