import React, { useState, useEffect } from "react";
import "./togglebuttn.css";

const Toggle = ({ approve, setState }) => {
  const [modee, setModee] = useState(approve === 1 ? false : true);

  useEffect(() => {
    setModee(approve === 1 ? false : true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approve]);

  const toggleState = () => {
    // setModee(!modee);
    setState(modee);
  };

  return (
    <div className="navbar-contain">
      <div
        className={
          approve === 1
            ? "toggle_contain-disabled"
            : modee
            ? "toggle_contain_inv"
            : "toggle_contain"
        }
        onClick={toggleState}
      >
        {modee ? <div id="darkmode" /> : <div id="lightmode" />}
      </div>
    </div>
  );
};

export default Toggle;
