import React, { useState, useEffect } from "react";
import axios from "axios";
import { NodeServer_API } from "../server";
import { toast } from "react-toastify";
import { Store } from "../Redux/store";
import { loadingData } from "../Redux/Actions/buttonClick";
import { priviledgeData } from "../Redux/Actions/roles";
import ButtonPagenate from "./ButtonPagenate/ButtonPagenate";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";

const Privileges = ({ setloading }) => {
  const [cookies] = useCookies(["Token"]);

  useEffect(() => {
    Store.dispatch(
      loadingData({
        status: false,
        text: "",
        type: "spin",
      })
    );
  }, []);

  const [selectedPrevilege, setSelectedPrevilege] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const { privileges, rolelist } = useSelector((state) => state.rolesData);

  let userole = useSelector((state) => state.datareponse.role); // Get role from Redux store

  let usersPrivileges = rolelist?.find(
    (roleoption) => roleoption?.role_name === userole
  )?.priviledges;

  const usersprivilegeOptions = usersPrivileges?.map((privilege) => ({
    value: privilege.privilege_id,
    label: privilege.privilege_name,
  }));

  const newPagenum = (num) => {
    setPageNumber(num);
  };

  const itemsPerpage = 20; //Number of items to be displayed per page
  const pagesVisited = pageNumber * itemsPerpage;
  const pageCount = Math.ceil(
    (privileges === null ? 0 : privileges.length) / itemsPerpage
  );

  const handlePrivilegeChange = (e) => {
    setSelectedPrevilege(e.target.value);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    Store.dispatch(
      loadingData({
        status: true,
        text: "Creating Privileges...",
        type: "spin",
      })
    );

    // Construct the payload
    const payload = {
      privilege_name: selectedPrevilege,
    };

    try {
      var config = {
        method: "POST",
        url: `${NodeServer_API}/privileges`,
        data: payload,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      // Send POST request to update the user

      await axios(config).then((response) => {
        Store.dispatch(
          loadingData({
            status: false,
            text: "",
            type: "spin",
          })
        );

        // console.log("Successfully saved:", response.data);
        toast.success(response.data.message);
        Store.dispatch(priviledgeData(response.data.data));
      });
    } catch (error) {
      console.error("Error saving user roles and privileges:", error);

      if (error.response.status === 401) {
        setloading({
          statuscode: 3,
          message: "Session Expired Re-Login",
          type: 3,
        });
      }
    }
  };

  return (
    <div className="flex-1 flex-col flex gap-3">
      <form
        onSubmit={handleSave}
        className="flex-1 bg-[white] p-4 flex flex-col gap-3"
      >
        <div className="flex justify-center items-center">
          <h2 className="text-l font-bold mb-2">CREATE PRIVILEGES</h2>
        </div>

        <div className="flex-1 flex-wrap flex gap-2 items-center justify-between">
          <input
            name="Privilege Label"
            onChange={handlePrivilegeChange}
            className="text-[12px] border border-gray-300 py-2 px-3 w-[30%] min-w-[180px] outline-none hover:border-gray-500 focus:border-blue-500 rounded-sm grow"
            value={selectedPrevilege}
            type="text"
            required
            placeholder="Privilege Label"
          />
        </div>

        <input
          disabled={
            usersprivilegeOptions?.find(
              (privilege) => privilege.label === "Create Privileges"
            )?.label === "Create Privileges"
              ? false
              : true
          }
          type="submit"
          className="btn btn-primary mt-4 text-[14px] font-semibold text-[white] py-2 rounded-sm bg-[#0743077d] hover:bg-[#074307f7] cursor-pointer"
          value={"SAVE CHANGES"}
        />
      </form>

      <div className="flex-1 bg-[white] p-3 flex flex-col gap-2">
        <table className="min-w-full border-collapse">
          <thead>
            <tr>
              <th className="text-[11px] whitespace-normal break-words border px-2 py-1">
                #SN
              </th>
              <th className="text-[11px] whitespace-normal break-words border px-2 py-1">
                Privileges
              </th>
            </tr>
          </thead>

          <tbody>
            {privileges
              .slice(pagesVisited, pagesVisited + itemsPerpage)
              .map((tdata, index) => (
                <tr className="body-tab-row cursor-pointer" key={tdata.id}>
                  <td
                    data-label="SN"
                    className="text-[11px] whitespace-normal break-words border px-2 py-1"
                  >
                    {index + 1}
                  </td>

                  <td
                    data-label="Roles"
                    className="text-[11px] whitespace-normal break-words border px-2 py-1"
                  >
                    {tdata.privilege_name}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        <div className="bnw-table-pgnate-contain">
          <ButtonPagenate
            updatedPageNum={newPagenum}
            pageCount={pageCount}
            pageNumber={pageNumber}
            previousLabel="Prev"
            nextLabel="Next"
          />
        </div>
      </div>
    </div>
  );
};

export default Privileges;
