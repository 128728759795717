export const airlines = [
  { label: "Delta Air Lines", value: "DL" },
  { label: "American Airlines", value: "AA" },
  { label: "United Airlines", value: "UA" },
  { label: "Southwest Airlines", value: "WN" },
  { label: "Air Canada", value: "AC" },
  { label: "British Airways", value: "BA" },
  { label: "Lufthansa", value: "LH" },
  { label: "Emirates", value: "EK" },
  { label: "Qatar Airways", value: "QR" },
  { label: "Air France", value: "AF" },
  { label: "KLM Royal Dutch Airlines", value: "KL" },
  { label: "Singapore Airlines", value: "SQ" },
  { label: "Cathay Pacific", value: "CX" },
  { label: "Japan Airlines", value: "JL" },
  { label: "Qantas", value: "QF" },
  { label: "Turkish Airlines", value: "TK" },
  { label: "Etihad Airways", value: "EY" },
  { label: "Virgin Atlantic", value: "VS" },
  { label: "Swiss International Air Lines", value: "LX" },
  { label: "Alitalia", value: "AZ" },
  { label: "Aeroflot", value: "SU" },
  { label: "Korean Air", value: "KE" },
  { label: "Thai Airways", value: "TG" },
  { label: "China Airlines", value: "CI" },
  { label: "Aeromexico", value: "AM" },
  { label: "Avianca", value: "AV" },
  { label: "LATAM Airlines", value: "LA" },
  { label: "Iberia", value: "IB" },
  { label: "Finnair", value: "AY" },
  { label: "Malaysia Airlines", value: "MH" },
  { label: "Scandinavian Airlines", value: "SK" },
  { label: "Austrian Airlines", value: "OS" },
  { label: "Brussels Airlines", value: "SN" },
  { label: "Hawaiian Airlines", value: "HA" },
  { label: "Garuda Indonesia", value: "GA" },
  { label: "Saudia", value: "SV" },
  { label: "El Al", value: "LY" },
  { label: "Air India", value: "AI" },
  { label: "IndiGo", value: "6E" },
  { label: "BlueJet Airways", value: "B6" },
  { label: "Spirit Airlines", value: "NK" },
  { label: "Ryanair", value: "FR" },
  { label: "easyJet", value: "U2" },
  { label: "Vueling Airlines", value: "VY" },
  { label: "Norwegian Air Shuttle", value: "DY" },
  { label: "Wizz Air", value: "W6" },
  { label: "Azul Brazilian Airlines", value: "AD" },
  { label: "Gol Linhas Aéreas", value: "G3" },
  { label: "AirAsia", value: "AK" },
  { label: "Vietnam Airlines", value: "VN" },
  { label: "Ethiopian Airlines", value: "ET" },
  { label: "South African Airways", value: "SA" },
  { label: "Kenya Airways", value: "KQ" },
  { label: "Air Peace", value: "P4" },
];
