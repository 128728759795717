import React, { useState, useEffect } from "react";
import "./page.css";
import ButtonPagenate from "../Component/ButtonPagenate/ButtonPagenate";
// import AgencyTableJson from './agencytable';
// import Toggle from '../Component/Togglebutton/Togglebutton'
import { useCookies } from "react-cookie";
import axios from "axios";
import Regionsjson from "../Component/Cards/regionsjson";
import Toggles from "../Component/Togglebutton/Togglebuttons";
import { useSelector } from "react-redux";
import { FiUpload } from "react-icons/fi";
import { CSVLink } from "react-csv";
import { FcStatistics } from "react-icons/fc";
import { Store } from "../Redux/store";
import {
  captureModalOverlay,
  expirationStatus,
  loadingData,
  toggle_Status,
} from "../Redux/Actions/buttonClick";
import { getAllAgencies } from "../Redux/Actions/dataResponse";
import { toast } from "react-toastify";
// import Error from '../Component/Error';

const Agencies = ({ setloading, nameSearch }) => {
  const criteria = useSelector((state) => state.searchdata.searchtext);
  const { agency } = useSelector((state) => state.datareponse);

  const [cookies] = useCookies(["Token"]);
  const [pageNumber, setPageNumber] = useState(0);
  const [exportpro, setExportpro] = useState(false);
  const itemsPerpage = 20; //Number of items to be displayed per page
  const pageCount = Math.ceil(agency.length / itemsPerpage);
  const pagesVisited = pageNumber * itemsPerpage;

  const { rolelist } = useSelector((state) => state.rolesData);

  let userole = useSelector((state) => state.datareponse.role); // Get role from Redux store

  let usersPrivileges = rolelist?.find(
    (roleoption) => roleoption.role_name === userole
  )?.priviledges;

  const usersprivilegeOptions = usersPrivileges?.map((privilege) => ({
    value: privilege.privilege_id,
    label: privilege.privilege_name,
  }));

  // useEffect(() => {
  //   Makerolecall();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const Makerolecall = async () => {
  //   Store.dispatch(
  //     loadingData({
  //       status: true,
  //       text: "Fetching Agencies ...",
  //       type: "spin",
  //     })
  //   );

  //   try {
  //     var config = {
  //       method: "GET",
  //       url: `${NodeServer_API}/agents/list`,
  //       headers: {
  //         Authorization: "Bearer " + cookies.Token,
  //       },
  //     };

  //     await axios(config).then((response) => {
  //       if (response.data.success === true) {
  //         Store.dispatch(
  //           loadingData({
  //             status: false,
  //             text: "",
  //             type: "spin",
  //           })
  //         );
  //         Store.dispatch(getAllAgencies(response.data.data));
  //       }
  //     });
  //   } catch (e) {
  //     if (e.response.status === 401) {
  //       Store.dispatch(
  //         expirationStatus({
  //           statuscode: 3,
  //           message: "Session Expired Re-Login",
  //           type: 3,
  //         })
  //       );
  //     } else {
  //       Store.dispatch(
  //         loadingData({
  //           status: false,
  //           text: "",
  //           type: "spin",
  //         })
  //       );

  //       toast.error(e.response.message);
  //     }
  //   }
  // };

  const Treated = (incoming) => {
    Store.dispatch(toggle_Status(incoming));
  };

  const newPagenum = (num) => {
    setPageNumber(num);
  };

  const loginStatusObj = {
    1: "Active",
    2: "In-Active",
  };

  var newdataset = agency.filter((filteredItem) => {
    if (criteria === "" && nameSearch === "") {
      return filteredItem;
    } else if (criteria !== "" && nameSearch === "") {
      return filteredItem;
    } else if (criteria === "" && nameSearch !== "") {
      return filteredItem;
    } else if (criteria !== "" && nameSearch !== "") {
      if (criteria === "User Id") {
        if (
          filteredItem.user_id.toString()?.includes(nameSearch.toLowerCase())
        ) {
          return filteredItem;
        }
      } else if (criteria === "Name") {
        if (
          filteredItem.firstname
            .toLowerCase()
            .includes(nameSearch.toLowerCase())
        ) {
          return filteredItem;
        }
      } else if (criteria === "Agency Name") {
        if (
          filteredItem.agencyName
            .toLowerCase()
            .includes(nameSearch.toLowerCase())
        ) {
          return filteredItem;
        }
      } else {
        return filteredItem;
      }
    }
  });

  const csvHeaders = [
    { label: "Id", key: "ID" },
    { label: "Firstname", key: "first_name" },
    { label: "Lastname", key: "last_name" },
    { label: "Agency Name", key: "agency_name" },
    { label: "Phone Number", key: "phone" },
    { label: "Email Address", key: "email" },
    { label: "Region", key: "region_code" },
  ];

  const handleHover = () => {
    setExportpro(!exportpro);
  };

  const handleExportCSV = () => {};

  const handleDisable = (props) => {
    Store.dispatch(
      captureModalOverlay({
        params: "updateusers",
        value: `Ooops! You do not have the Right to access this resource. Kindly contact your Admin.`,
      })
    );
  };

  return (
    <div className="bnw-agency-container flex flex-col gap-1">
      <div className="w-full justify-between flex bg-[#e50000] p-1">
        <div className="relative flex justify-center items-center">
          <span className="flex items-center px-2 py-1 text-center cursor-pointer text-[#fff] text-[12px] font-[600]">
            AGENCIES
          </span>

          <FcStatistics size={25} color="#fff" />
        </div>

        <CSVLink
          data={agency}
          headers={csvHeaders}
          onClick={() => {
            const hasExportCSVPrivilege = usersprivilegeOptions?.some(
              (privilege) => privilege.label === "Export CSV"
            );
            if (hasExportCSVPrivilege) {
              handleExportCSV();
            } else {
              handleDisable();
            }
            return false; // Prevents default CSV download if needed
          }}
        >
          <div
            className={`relative border px-2 py-1 cursor-pointer rounded-md flex justify-center items-center ${
              exportpro ? "bg-[white]" : "bg-[#e50000]"
            }`}
            onMouseEnter={handleHover}
            onMouseLeave={handleHover}
          >
            <FiUpload size={18} color={exportpro ? "#e50000" : "#fff"} />
            <span
              className={`px-2 py-1 cursor-pointer ${
                exportpro ? "text-[#e50000]" : "text-[#fff]"
              } text-[12px] font-[600]`}
            >
              Export
            </span>
          </div>
        </CSVLink>
      </div>

      <div className="w-full flex flex-col gap-1">
        <table className="bnw-claims-table">
          <thead id="bnw-claims-table-head">
            <tr>
              <th scope="col" className="text-[13px]">
                User ID
              </th>
              <th scope="col" className="text-[13px]">
                Name
              </th>
              <th scope="col" className="text-[13px]">
                Agency Name
              </th>
              <th scope="col" className="text-[13px]">
                Phone Number
              </th>
              <th scope="col" className="text-[13px]">
                Email Addr
              </th>
              <th scope="col" className="text-[13px]">
                Region
              </th>
              <th scope="col" className="text-[13px]">
                Status
              </th>
              <th scope="col" className="text-[13px]">
                Can-Login
              </th>
            </tr>
          </thead>
          <tbody>
            {newdataset
              .reverse()
              .slice(pagesVisited, pagesVisited + itemsPerpage)
              .map((item, index) => (
                <tr key={index}>
                  <td data-label="USER ID" className="text-[11px]">
                    {/* {item.id} */}
                    {index + 1}
                  </td>
                  <td data-label="NAME" className="text-[11px]">
                    {item.first_name + " " + item.last_name}
                  </td>
                  <td data-label="AGENCY NAME" className="text-[11px]">
                    {item.agency_name}
                  </td>
                  <td data-label="PHONE" className="text-[11px]">
                    {item.phone}
                  </td>
                  <td data-label="EMAIL" className="text-[11px]">
                    {item.email}
                  </td>
                  <td data-label="REGION" className="text-[11px]">
                    {
                      Regionsjson.find((m) => m.value === item.region_code)
                        .label
                    }
                  </td>
                  <td
                    data-label="STATUS"
                    id="status-col"
                    className="text-[11px]"
                  >
                    <div
                      id={!item.active ? "bnw-status-act" : "bnw-status-deact"}
                    >
                      {!item.active ? loginStatusObj[1] : loginStatusObj[2]}
                    </div>
                  </td>
                  <td data-label="CAN-LOGIN" className="text-[11px]">
                    <Toggles
                      currentState={!item.can_login}
                      setState={Treated}
                      index={item.id}
                      page="agencies"
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="bnw-table-pgnate-contain">
          {/* <button id='addbitn' onClick={handleAddButtn}>Add</button> */}
          <ButtonPagenate
            updatedPageNum={newPagenum}
            pageCount={pageCount}
            pageNumber={pageNumber}
            previousLabel="Prev"
            nextLabel="Next"
          />
        </div>
      </div>
    </div>
  );
};

export default Agencies;
