import React, { useState } from "react";
import { useSelector } from "react-redux";
import { createPromo, deletePromo } from "../Redux/Actions/ruleengine";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { AiFillDelete } from "react-icons/ai";
import { Store } from "../Redux/store";
import Select from "react-select";

import { captureModalOverlay, loadingData } from "../Redux/Actions/buttonClick";
import ButtonPagenate from "./ButtonPagenate/ButtonPagenate";
import { fetchAirlinelookup } from "../Redux/Actions/airlineLookup";
import { utsDateformatter } from "../utils/utlsDateConverter";

const CreatePromoForm = () => {
  const dispatch = useDispatch();
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectAgency, setSelectAgency] = useState(null);
  const [selectAirlines, setSelectAirlines] = useState("");

  const { regionlist, agency } = useSelector((state) => state.regions);

  const { agenciesdata } = useSelector((state) => state.agencieslist);
  const { promodata } = useSelector((state) => state.ruleengine);
  const { lookup, isLoading } = useSelector((state) => state.airlinelookup);

  const [pageNumber, setPageNumber] = useState(0);

  const newPagenum = (num) => {
    setPageNumber(num);
  };

  const itemsPerpage = 10; // Number of items to be displayed per page
  const pagesVisited = pageNumber * itemsPerpage;
  const pageCount = Math.ceil(
    (promodata === null ? 0 : promodata?.length) / itemsPerpage
  );

  const handleRegionChange = (region) => {
    setFormData({ ...formData, region_id: region.value });
    setSelectedRegion(region);
  };

  const { privileges } = useSelector((state) => state.rolesData);
  const privilegeOptions = privileges?.map((privilege) => ({
    value: privilege.id,
    label: privilege.privilege_name,
  }));

  const [formData, setFormData] = useState({
    name: "",
    start_date: "",
    end_date: "",
    region_id: "",
    airline_id: "",
    agency_id: "",
    amount_per_local_currency: "",
    priority: "",
    amount_per_dollar: "",
    active: 1, // Default active to 1
  });

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    Store.dispatch(
      loadingData({
        status: true,
        text: "Creating Rules...",
        type: "spin",
      })
    );

    const result = await dispatch(createPromo(formData));
    if (result.type === "promoCreateSuccess") {
      Store.dispatch(
        loadingData({
          status: false,
          text: "",
          type: "spin",
        })
      );
      toast.success(result.message);
    } else {
      Store.dispatch(
        loadingData({
          status: false,
          text: "",
          type: "spin",
        })
      );
      toast.error(result.error);
    }
  };

  // Open performance appraisal page to fetch the record of user with the particular ID if it exists in the db
  const handleDelete = async (props) => {
    Store.dispatch(
      loadingData({
        status: true,
        text: "Deleting Payment Option...",
        type: "spin",
      })
    );

    const response = await dispatch(deletePromo(props));

    if (response.type === "promoDeleteSuccess") {
      Store.dispatch(
        loadingData({
          status: false,
          text: "",
          type: "spin",
        })
      );
    } else {
      toast.error(response.error);
    }
  };

  const handleDisable = (e) => {
    e.preventDefault();
    Store.dispatch(
      captureModalOverlay({
        params: "updateusers",
        value: `Ooops! You do not have the Right to access this resource. Kindly contact your Admin.`,
      })
    );
  };

  const regionOptions = regionlist?.map((region) => ({
    value: region.ID,
    label: region.name,
    regionCode: region.region_code,
  }));

  const agencyOptions = agenciesdata?.map((item) => ({
    value: item.id,
    label: item.agency_name,
    pcc: item.pcc,
  }));

  // console.log(lookup);
  const airlineOptions = lookup?.map((item) => ({
    value: item.AirlineCode,
    label: item.AlternativeBusinessName,
    // pcc: item.pcc,
  }));

  const handleAgency = (agency) => {
    setFormData({ ...formData, agency_id: agency.value });
    setSelectAgency(agency);
  };

  const handleAirlineChange = (airline) => {
    // console.log(airline.label);
    setSelectAirlines(airline);
    setFormData({ ...formData, airline_id: airline.value });
  };

  const handleAirlineSearch = (inputValue) => {
    // console.log("Input value:", inputValue);
    if (inputValue.length === 2) {
      const airlinename = dispatch(fetchAirlinelookup(inputValue));
      if (airlinename.type === "fetchAirlineLookupSuccess") {
        // console.log(airlinename.airlinelookup);
      }
    }
  };

  return (
    <div className="flex justify-center items-start min-h-screen flex-col gap-4 bg-gray-100">
      <div className="w-full p-4 flex flex-col justify-start items-center gap-1 bg-[white] shadow-sm ">
        <div className="flex justify-center items-center">
          <h2 className="text-l font-bold mb-4">CREATE NEW PROMO</h2>
        </div>
        <form
          onSubmit={
            privilegeOptions.find(
              (privilege) => privilege.label === "Create User"
            )?.label === "Create User"
              ? handleSubmit
              : handleDisable
          }
          className="self-stretch flex flex-wrap gap-2"
        >
          <div className="self-stretch flex flex-col gap-1 flex-grow">
            <label htmlFor="name" className="text-[12px] font-medium">
              Promo Name:
            </label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Promo Name"
              value={formData.name}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-[12px]"
            />
          </div>
          <div className="self-stretch flex flex-col gap-1 flex-grow">
            <label htmlFor="start_date" className="text-[12px] font-medium">
              Start Date:
            </label>
            <input
              type="datetime-local"
              id="start_date"
              name="start_date"
              value={formData.start_date}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-[12px]"
            />
          </div>
          <div className="self-stretch flex flex-col gap-1 flex-grow">
            <label htmlFor="end_date" className="text-[12px] font-medium">
              End Date:
            </label>
            <input
              type="datetime-local"
              id="end_date"
              name="end_date"
              value={formData.end_date}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-[12px]"
            />
          </div>
          <div className="self-stretch flex flex-col gap-1 flex-grow">
            <label htmlFor="region_id" className="text-[12px] font-medium">
              Target Region (Optional):
            </label>
            <Select
              options={regionOptions}
              value={selectedRegion}
              onChange={handleRegionChange}
              className="w-full min-w-[180px] text-[12px] rounded-sm grow"
              placeholder="Select Region"
            />
          </div>
          <div className="self-stretch flex flex-col gap-1 flex-grow">
            <label htmlFor="airline_id" className="text-[12px] font-medium">
              Target Airline (Optional):
            </label>
            <Select
              options={airlineOptions}
              value={selectAirlines}
              onInputChange={(inputValue) => handleAirlineSearch(inputValue)} // Pass the input value
              onChange={(e) => handleAirlineChange(e)} // Pass the selected airline code
              className="w-full min-w-[180px] text-[12px] rounded-sm grow"
              placeholder="Select Airline"
              isLoading={isLoading}
            />
          </div>
          <div className="self-stretch flex flex-col gap-1 flex-grow">
            <label htmlFor="agency_id" className="text-[12px] font-medium">
              Target Agency (Optional):
            </label>
            <Select
              options={agencyOptions}
              value={selectAgency}
              onChange={handleAgency}
              className="w-full min-w-[180px] text-[12px] rounded-sm grow"
              placeholder="Select Agency"
            />
          </div>

          <div className="self-stretch flex flex-col gap-1 flex-grow min-w-[180px]">
            <label htmlFor="active" className="text-[12px] font-medium">
              Active:
            </label>
            <select
              id="active"
              name="active"
              value={formData.active}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-[12px]"
            >
              <option value={1}>Active</option>
              <option value={0}>Inactive</option>
            </select>
          </div>

          <div className="self-stretch flex flex-col gap-1 flex-grow min-w-[180px]">
            <label htmlFor="agency_id" className="text-[12px] font-medium">
              <span>Amount/Ticket :</span>
            </label>
            <input
              placeholder="1 Ticket → Local Cur."
              type="text"
              onChange={handleChange}
              value={formData.amount_per_local_currency}
              id="amout-per-dollar"
              className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-[12px]"
              name="amount_per_local_currency"
            />
          </div>

          <div className="self-stretch flex flex-col gap-1 flex-grow min-w-[180px]">
            <label htmlFor="agency_id" className="text-[12px] font-medium">
              Currency/USD :
            </label>
            <input
              placeholder="1 USD → Local Cur."
              type="text"
              onChange={handleChange}
              value={formData.amount_per_dollar}
              id="amout-per-dollar"
              className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-[12px]"
              name="amount_per_dollar"
            />
          </div>

          <div className="self-stretch flex flex-col gap-1 flex-grow min-w-[180px]">
            <label htmlFor="agency_id" className="text-[12px] font-medium">
              Priority:
            </label>
            <input
              placeholder="Priority"
              type="text"
              onChange={handleChange}
              value={formData.priority}
              id="amout-per-dollar"
              className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-[12px]"
              name="priority"
            />
          </div>

          <input
            disabled={
              privilegeOptions.find(
                (privilege) => privilege.label === "Create User"
              )?.label === "Create User"
                ? false
                : true
            }
            type="submit"
            className="btn btn-primary mt-4 text-[14px] w-full font-semibold text-[white] py-2 rounded-sm bg-[#0743077d] hover:bg-[#074307f7] cursor-pointer transition"
            value={"CREATE RULE"}
          />
        </form>
      </div>

      <div className="flex-1 bg-[white] p-3 flex flex-col gap-2">
        <table className="min-w-full border-collapse">
          <thead>
            <tr>
              <th className="text-[11px] whitespace-normal break-words border px-2 py-1">
                #SN
              </th>
              <th className="text-[11px] whitespace-normal break-words border px-2 py-1">
                Promo Name
              </th>
              <th className="text-[11px] whitespace-normal break-words border px-2 py-1">
                Start Date
              </th>
              <th className="text-[11px] whitespace-normal break-words border px-2 py-1">
                End Date
              </th>
              <th className="text-[11px] whitespace-normal break-words border px-2 py-1">
                Target Region
              </th>
              <th className="text-[11px] whitespace-normal break-words border px-2 py-1">
                Target Airline
              </th>
              <th className="text-[11px] whitespace-normal break-words border px-2 py-1">
                Target Agency
              </th>

              <th
                scope="col"
                className="text-[11px] whitespace-normal break-words border px-2 py-1"
              >
                Amount/Tkt
              </th>
              <th
                scope="col"
                className="text-[11px] whitespace-normal break-words border px-2 py-1"
              >
                Currency/USD
              </th>

              <th
                scope="col"
                className="text-[11px] whitespace-normal break-words border px-2 py-1"
              >
                Priority
              </th>

              <th className="text-[11px] whitespace-normal break-words border px-2 py-1">
                Status
              </th>

              <th className="text-[11px] whitespace-normal break-words border px-2 py-1">
                Action
              </th>
            </tr>
          </thead>

          <tbody>
            {promodata
              ?.slice(pagesVisited, pagesVisited + itemsPerpage)
              .map((tdata, index) => (
                <tr className="body-tab-row cursor-pointer" key={tdata.id}>
                  <td
                    data-label="SN"
                    className="text-[11px] whitespace-normal break-words border px-2 py-1"
                  >
                    {index + 1 + pagesVisited}
                  </td>

                  <td
                    data-label="Promo name"
                    className="text-[11px] whitespace-normal break-words border px-2 py-1"
                  >
                    {tdata.name}
                  </td>

                  <td
                    data-label="Start date"
                    className="text-[11px] whitespace-normal break-words border px-2 py-1"
                  >
                    {utsDateformatter(tdata.start_date)}
                  </td>

                  <td
                    data-label="End date"
                    className="text-[11px] whitespace-normal break-words border px-2 py-1"
                  >
                    {utsDateformatter(tdata.end_date)}
                  </td>

                  <td
                    data-label="Region"
                    className="text-[11px] whitespace-normal break-words border px-2 py-1"
                  >
                    {
                      regionlist.find((items) => items.ID === tdata.region_id)
                        ?.name
                    }
                  </td>

                  <td
                    data-label="Airline"
                    className="text-[11px] whitespace-normal break-words border px-2 py-1"
                  >
                    {tdata.airline_id}
                  </td>

                  <td
                    data-label="Agency"
                    className="text-[11px] whitespace-normal break-words border px-2 py-1"
                  >
                    {
                      agency?.find((items) => items.ID === tdata.agency_id)
                        ?.agency_name
                    }
                  </td>

                  <td data-label="Amount/Tkt" className="text-[11px]">
                    {tdata.amount_per_dollar}
                  </td>
                  <td data-label="Currency/USD" className="text-[11px]">
                    {tdata.amount_per_local_currency}
                  </td>
                  <td data-label="Currency/USD" className="text-[11px]">
                    {tdata.priority}
                  </td>
                  <td
                    data-label="Airline"
                    className="text-[11px] whitespace-normal break-words border px-2 py-1"
                  >
                    {tdata.active === 1 ? (
                      <span>Active</span>
                    ) : (
                      <span>Inactive</span>
                    )}
                  </td>

                  <td
                    data-label="Action"
                    className="text-[11px] flex items-center justify-center whitespace-normal break-words border px-2 py-1"
                  >
                    <AiFillDelete
                      onClick={() =>
                        privilegeOptions.find(
                          (privilege) => privilege.label === "Delete Payment"
                        )?.label === "Delete Payment"
                          ? handleDelete(tdata.id)
                          : handleDisable()
                      }
                      id="butn-action"
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="bnw-table-pgnate-contain">
          <ButtonPagenate
            updatedPageNum={newPagenum}
            pageCount={pageCount}
            pageNumber={pageNumber}
            previousLabel="Prev"
            nextLabel="Next"
          />
        </div>
      </div>
    </div>
  );
};

export default CreatePromoForm;
