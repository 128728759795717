import React, { useState, useEffect } from "react";
import Claimcards from "../Component/Cards/Claimcards";
import Agency from "../Component/Cards/Loctioncard";
import Salesfunnel from "../Component/Cards/Salesfunnel";
import TPA from "../Component/Cards/TPA";
import TPR from "../Component/Cards/TPR";
import "./page.css";
import "./table.css";
import BudgetOverview from "../Component/Cards/BudgetOverview";
import Countrydata from "../Component/Cards/budgetJson";
import Pocketbalance from "../Component/Cards/Pocketbalance";
import { useSelector } from "react-redux";
import FinancialPointsperCostChart from "../Component/charts/Mixedfinancialchart";
import Topperformingagencies from "./topperformingagencies";
import pointBreakdwonScript from "../Component/Scripts/pointbreakdownscript";
import Regionsjson from "../Component/Cards/regionsjson";
import Topperformingregions from "./topperformingregions";
import Boxes from "../Component/Dashbardboxes/Boxes";
import YearSelector from "../Component/DateSelector/Yearselector.jsx";
import MonthSelector from "../Component/DateSelector/Dateselector.jsx";
import { sumAllocationsByYearAndMarket } from "../utils/summedallocationbyyear.jsx";
import currencyUnit from "./Claims/currencyUnit.js";
import { Store } from "../Redux/store.jsx";
import {
  captureSelectedMonth,
  captureTopPerforming,
} from "../Redux/Actions/buttonClick.js";
import { Liability } from "../Component/Functions/Liability.jsx";
import claimsFunction from "../Component/Scripts/claimsfunction.jsx";
import { addDecimal } from "../Component/Functions/Numberformat.js";
import CurrentLiability from "./currentLiability.jsx";
// import { flownticketSumsData } from "../Redux/Actions/dataResponse.js";
import ExpectedLiability from "./expectedLiability.jsx";
import { AllflownTickets } from "../utils/allflownTickets.jsx";
import Claimsequivalent from "./claimsequivalent.jsx";
import UnflownTicketsequv from "./unflownticketsequv.jsx";
import FlownTicketsequv from "./flownticketsequv.jsx";
import FlownUnflownTicketsequv from "./flownunflowntickte.jsx";

const Body = ({ regioncode }) => {
  const currentDate = new Date();
  const { pocketdata } = useSelector((state) => state.datareponse);

  const {
    role,
    activeclaims,
    approvedclaims,
    canceledclaims,
    regionlist,
    agencycount,
    financialdata,
    homepagedata,
    paystackwalletdata,
    marketallocationdata,
    pointbreakdown,
    exchangerate,
    pointsdata,
    claimsdata,
    paystacktotaldisbursedata,
  } = useSelector((state) => state.datareponse);

  // Filter only flown ticktes from the points
  let onlyFlownPoints = pointsdata.filter(
    (pointobject) => pointobject.ticket_status === 1
  );

  // Filter only unflown ticktes from the points
  let onlyunFlownPoints = pointsdata.filter(
    (pointobject) => pointobject.ticket_status === 0
  );

  ///// sum of all ticketed unclaimed tickets in the system
  let only_ticketed_Points = pointsdata.filter(
    (pointobject) =>
      pointobject.ticket_status === 1 || pointobject.ticket_status === 0
  );

  ///// sum of all flown unclaimed tickets in the system
  let sum_of_all_flown_tickets = Liability(
    onlyFlownPoints,
    claimsdata,
    exchangerate.data
  );

  // filter NG off to capture Team 2 only
  let team_2 = sum_of_all_flown_tickets.filter(
    (item) => item.region_code !== "NG"
  );

  // filter off all except NG
  let team_1 = sum_of_all_flown_tickets.filter(
    (item) => item.region_code === "NG"
  );

  const Cum_eqv_team_2_points = team_2.reduce(
    (accumulator, sum_usd) => accumulator + sum_usd.equivalent_sum_usd,
    0
  );

  const Cum_eqv_team_1_points = team_1.reduce(
    (accumulator, sum_usd) => accumulator + sum_usd.equivalent_sum,
    0
  );

  // // sum of all flown tickets in the system
  let cummulative_ticketed_booking = Liability(
    only_ticketed_Points,
    claimsdata,
    exchangerate.data
  );

  // filter NG off to capture Team 2 only
  let team_2_ticketed = cummulative_ticketed_booking.filter(
    (item) => item.region_code !== "NG"
  );

  // filter off all except NG
  let team_1_ticketed = cummulative_ticketed_booking.filter(
    (item) => item.region_code === "NG"
  );

  let Cum_ticketed_team_2_points = team_2_ticketed.reduce(
    (accumulator, sum_usd) => accumulator + sum_usd.equivalent_sum_usd,
    0
  );

  let Cum_ticketed_team_1_points = team_1_ticketed.reduce(
    (accumulator, sum_usd) => accumulator + sum_usd.equivalent_sum,
    0
  );

  ////// This block gets the cummulative in USD of all claimed approved tickets in the system
  let pointsData = claimsFunction(claimsdata, currencyUnit, exchangerate.data);

  const CumTicketEquv_USD_Team2 = pointsData
    .filter((item) => item.region_code !== "NG")
    .filter((filteredItem) => filteredItem.claim_status === 1)
    .reduce((accumulator, currentValue) => {
      const equivalentSumUSD =
        (currentValue.points * currentValue.local_per_ticket) /
          currentValue.local_per_usd !==
        Infinity
          ? Math.ceil(
              ((currentValue.points * currentValue.local_per_ticket) /
                currentValue.local_per_usd) *
                10
            ) / 10
          : 0;
      return accumulator + equivalentSumUSD;
    }, 0)
    .toFixed(1);

  const CumTicketEquv_USD_Team1 = pointsData
    .filter((item) => item.region_code === "NG")
    .filter((filteredItem) => filteredItem.claim_status === 1)
    .reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.points_equivalent,
      0
    )
    .toFixed(1);

  const [mycheckbox, setMycheckbox] = useState(false);

  const [financials, setFinancials] = useState({
    amount_left: 0,
    current_allocation: 0,
    finance_approved_budget: 0,
  });

  useEffect(() => {
    Store.dispatch(captureTopPerforming(0));
    setFinancials({ ...financials, ...financialdata });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [financialdata]);

  // Summed up allocation, grouped by the year of creation
  let formattedallocation = sumAllocationsByYearAndMarket(marketallocationdata);

  let allocationSummation = homepagedata.allocation_summation;

  const { topperformclick } = useSelector((state) => state.buttonclick);

  // console.log(pointsData);

  const CumTicketEquv_USD_TeamII = pointsData
    .filter((region) => region.region_code !== "NG")
    .reduce((accumulator, currentValue) => {
      const equivalentSumUSD =
        (currentValue.points * currentValue.local_per_ticket) /
          currentValue.local_per_usd !==
        Infinity
          ? Math.ceil(
              ((currentValue.points * currentValue.local_per_ticket) /
                currentValue.local_per_usd) *
                10
            ) / 10
          : 0;
      return accumulator + equivalentSumUSD;
    }, 0)
    .toFixed(1);

  const CumTicketEquv_USD_TeamI = pointsData
    .filter((region) => region.region_code === "NG")
    .reduce((accumulator, currentValue) => {
      const equivalentSumLocalCurrency =
        currentValue.points * currentValue.local_per_ticket;
      return accumulator + equivalentSumLocalCurrency;
    }, 0)
    .toFixed(1);

  // The function helps generate the equivalent of all tickted bookings only
  let ticketEquivalentofallTicketed = AllflownTickets(
    only_ticketed_Points,
    exchangerate.data
  );

  // console.log(ticketEquivalentofallTicketed);

  // The function helps generate the equivalent of all flown tickts only
  let ticketEquivalent = AllflownTickets(onlyFlownPoints, exchangerate.data);

  // The function helps generate the equivalent of all unflown tickts only
  let ticketEquivalentofallUnflown = AllflownTickets(
    onlyunFlownPoints,
    exchangerate.data
  );

  // Equivalent sum of Total flown tickets in USD for TeamI and TeamII
  let totalflownTeamI = ticketEquivalent
    .filter((flownitem) => flownitem.region_code === "NG")
    .reduce(
      (accumulator, currentValue) => accumulator + currentValue.ticketequv,
      0
    )
    .toFixed(1);

  let totalflownTeamII = ticketEquivalent
    .filter((flownitem) => flownitem.region_code !== "NG")
    .reduce(
      (accumulator, currentValue) => accumulator + currentValue.USDequv,
      0
    )
    .toFixed(1);

  // Equivalent sum of Total unflown tickets in USD for TeamI and TeamII
  let totalunflownTeamI = ticketEquivalentofallUnflown
    .filter((flownitem) => flownitem.region_code === "NG")
    .reduce(
      (accumulator, currentValue) => accumulator + currentValue.ticketequv,
      0
    )
    .toFixed(1);

  let totalunflownTeamII = ticketEquivalentofallUnflown
    .filter((flownitem) => flownitem.region_code !== "NG")
    .reduce(
      (accumulator, currentValue) => accumulator + currentValue.USDequv,
      0
    )
    .toFixed(1);

  // Equivalent sum of Total ticketed bookings in USD for TeamI and TeamII
  let totalticketedTeamI = ticketEquivalentofallTicketed
    .filter((flownitem) => flownitem.region_code === "NG")
    .reduce(
      (accumulator, currentValue) => accumulator + currentValue.ticketequv,
      0
    )
    .toFixed(1);

  const claimedPointTeamI = pointsData
    .filter((item) => item.region_code === "NG")
    .reduce((accumulator, currentValue) => {
      const totalPoints = currentValue.points;
      return accumulator + totalPoints;
    }, 0);

  // console.log(totalticketedTeamI);
  // console.log(CumTicketEquv_USD_TeamI);
  // console.log(
  //   totalticketedTeamI - (CumTicketEquv_USD_TeamI / claimedPointTeamI) * 600
  // );

  let totalticketedTeamII = ticketEquivalentofallTicketed
    .filter((flownitem) => flownitem.region_code !== "NG")
    .reduce(
      (accumulator, currentValue) => accumulator + currentValue.USDequv,
      0
    )
    .toFixed(1);

  let allocationData = pointBreakdwonScript(
    pointbreakdown,
    Regionsjson,
    exchangerate.data
  );

  const CumflownTickets_team2 = allocationData
    .filter((items) => items.country !== "Nigeria")
    .reduce(
      (accumulator, currentValue) => accumulator + currentValue.active_points,
      0
    );

  // console.log(allocationData);

  let CumflownTickets_team1 = allocationData
    .filter((items) => items.country === "Nigeria")
    .reduce(
      (accumulator, currentValue) => accumulator + currentValue.active_points,
      0
    );

  // Cumulative flown tickets for Team 1
  const CumunflownTickets_team1 = allocationData
    .filter((items) => items.country === "Nigeria")
    .reduce(
      (accumulator, currentValue) => accumulator + currentValue.unflown_tickets,
      0
    );

  // Cumulative flown tickets for Team 2
  let CumunflownTickets_team2 = allocationData
    .filter((items) => items.country !== "Nigeria")
    .reduce(
      (accumulator, currentValue) => accumulator + currentValue.unflown_tickets,
      0
    );

  useEffect(() => {
    handleWidth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionlist, agencycount]);

  useEffect(() => {
    handleWidth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [widthval, setWidthval] = useState({
    pie1: 0,
    pie2: 0,
    pie3: 0,
  });

  const handleWidth = () => {
    setWidthval({ pie1: 8000, pie2: agencycount, pie3: regionlist.length });
  };

  const handlesetMycheckbox = () => {
    if (mycheckbox) {
      Store.dispatch(captureSelectedMonth("All"));
    } else {
      Store.dispatch(captureSelectedMonth("January"));
    }
    setMycheckbox(!mycheckbox);
  };

  const allowedRoles_1 = ["ADMIN", "DEVELOPER"];
  const allowedRoles_2 = ["DIRECTOR"];
  const allowedRoles_3 = ["ADMIN", "DEVELOPER", "DIRECTOR"];

  const hasAccessToResource = (userRoles, allowedRoles) => {
    return allowedRoles.some((role) => role.includes(userRoles));
  };

  return (
    <div className="bnw-main-body-container flex-col gap-1 p-2 relative">
      {hasAccessToResource(role, allowedRoles_1) && (
        <div className="w-full p-2 gap-5 border rounded-5 flex items-center sticky top-0 z-20 bg-[white] rounded-[8px]">
          <YearSelector />

          <MonthSelector />

          <div className="flex item-center gap-1">
            <input
              type="checkbox"
              onChange={() => handlesetMycheckbox()}
              checked={!mycheckbox ? true : false}
              className="cursor-pointer"
            />

            <label
              htmlFor="checkbox"
              className="text-[12px] font-[500] text-[red]"
            >
              All
            </label>
          </div>
        </div>
      )}

      {hasAccessToResource(role, allowedRoles_1) && (
        <div className="rounded-[10px] w-full gap-3 py-2 flex items-center flex-wrap">
          <Boxes
            boxtitle="ALL TICKETED BOOKINGS"
            currency_team1="₦"
            currency_team2="$"
            team_1_value={totalticketedTeamI}
            team_2_value={totalticketedTeamII}
            icon={1}
            tooltiptext="USD & NGN Equivalent of all flown and unflown tickets - all ticketed bookings - in the system. ALL TICKETED BOOKINGS = ALL FLOWN TICKETS + ALL UNFLOWN TICKETS"
          />

          <Boxes
            boxtitle="ALL FLOWN TICKETS"
            currency_team1="₦"
            currency_team2="$"
            team_1_value={totalflownTeamI}
            team_2_value={totalflownTeamII}
            icon={1}
            tooltiptext="USD & NGN Equivalent of all flown tickets in the system. ALL FLOWN TICKETS = ALL CLAIMED TICKETS + CURRENT LIABILITY"
          />

          <Boxes
            boxtitle="ALL UNFLOWN TICKETS"
            currency_team1="₦"
            currency_team2="$"
            team_1_value={totalunflownTeamI}
            team_2_value={totalunflownTeamII}
            icon={1}
            tooltiptext="USD & NGN Equivalent of all unflown tickets in the system. ALL FLOWN TICKETS = ALL CLAIMED TICKETS + CURRENT LIABILITY"
          />

          <Boxes
            boxtitle="ALL CLAIMED TICKETS"
            currency_team1="₦"
            currency_team2="$"
            team_1_value={CumTicketEquv_USD_TeamI}
            team_2_value={CumTicketEquv_USD_TeamII}
            icon={1}
            tooltiptext="USD & NGN Equivalent of all Claimed tickets in the system"
          />

          <Boxes
            boxtitle="CURRENT LIABILITY"
            currency_team1="₦"
            currency_team2="$"
            team_1_value={Cum_eqv_team_1_points.toFixed(1)}
            team_2_value={Cum_eqv_team_2_points.toFixed(1)}
            icon={1}
            tooltiptext="USD & NGN Equivalent of Current Liability = All flown Ticket - All Claimed Ticket + All Pending claims + All Declined Claims)"
          />

          <Boxes
            boxtitle="EXPECTED LIABLILTY"
            currency_team1="₦"
            currency_team2="$"
            team_1_value={Cum_ticketed_team_1_points.toFixed(1)}
            team_2_value={Cum_ticketed_team_2_points.toFixed(1)}
            icon={1}
            tooltiptext="USD & NGN Equivalent of all Flown & Unflown tickets in the system. EXPECTED LIABLILTY = ALL TICKETED BOOKINGS - ALL CLAIMED TICKET"
          />

          <Boxes
            boxtitle="TOTAL DISBURSED"
            currency_team1="₦"
            currency_team2="$"
            // team_1_value={formattedallocation[currentDate.getFullYear()]?.TeamI}
            team_1_value={addDecimal(paystacktotaldisbursedata) || 0.0}
            team_2_value={
              formattedallocation[currentDate.getFullYear()]?.TeamII || 0.0
            }
            icon={1}
            tooltiptext="Total amount disbursed by finance to Team 1 and Team 2 Purse "
          />

          <Boxes
            boxtitle="TOTAL FLOWN TICKETS"
            currency_team1=""
            currency_team2=""
            team_1_value={CumflownTickets_team1}
            team_2_value={CumflownTickets_team2}
            icon={2}
            tooltiptext="Total Flown ticket count for team 1 and team 2"
          />

          <Boxes
            boxtitle="TOTAL UNFLOWN TICKETS"
            currency_team1=""
            currency_team2=""
            team_1_value={CumunflownTickets_team1}
            team_2_value={CumunflownTickets_team2}
            icon={3}
            tooltiptext="Total unflown ticket count for team 1 and team 2"
          />

          <Boxes
            boxtitle="WALLET BALANCE"
            currency_team1="₦"
            currency_team2="$"
            team_1_value={addDecimal(paystackwalletdata)}
            team_2_value={
              pocketdata.find((regionpocket) => regionpocket.currency === "USD")
                ?.pocketBalance
            }
            icon={1}
            tooltiptext="Current Balance left in team 1 and team 2 Purse"
          />
        </div>
      )}

      <div className="bnw-body-container relative">
        <div className="w-[72%] flex flex-col gap-3 grow salesbox:w-full">
          <div className="bnw-body-left-top grow">
            {hasAccessToResource(role, allowedRoles_1) && (
              <BudgetOverview
                backcolor="#FFFFF3"
                text="Budget Overview"
                countrydata={Countrydata.data}
                value={Countrydata.total}
                maximum="10000"
                gradient="#C0CE22"
                piebackcolor="#F9FFB4"
              />
            )}

            {hasAccessToResource(role, allowedRoles_2) && (
              <Claimcards
                backcolor="#FEFFE3"
                strokecolor="254, 190, 16"
                text="Active Claims"
                value={activeclaims}
                maximum="50"
                gradient="#C0CE22"
                piebackcolor="#F9FFB4"
              />
            )}

            {hasAccessToResource(role, allowedRoles_2) && (
              <Claimcards
                backcolor="#F2FFE8"
                strokecolor="3, 192, 60"
                text="Approved Claims"
                value={approvedclaims}
                maximum="50"
                gradient="#44CE22"
                piebackcolor="#1FC1B0"
              />
            )}

            {hasAccessToResource(role, allowedRoles_2) && (
              <Claimcards
                backcolor="#FFF0E8"
                strokecolor="165, 42, 42"
                text="Canceled Claims"
                value={canceledclaims}
                maximum="50"
                gradient="#FF8855"
                piebackcolor="#FFC8B0"
              />
            )}

            {hasAccessToResource(role, allowedRoles_2) && (
              <Pocketbalance
                currency={
                  pocketdata.find((regionpocket) => {
                    return regionpocket.country === regioncode;
                  })?.currency
                }
                backcolor="#F2FFE8"
                strokecolor="3, 192, 60"
                text="Wallet Balance"
                value={
                  pocketdata.find((regionpocket) => {
                    return regionpocket.country === regioncode;
                  })?.pocketBalance
                }
                maximum="10000"
                gradient="#FF8855"
                piebackcolor="#1FC1B0"
              />
            )}
          </div>

          {hasAccessToResource(role, allowedRoles_1) && (
            <div className="bnw-body-left-mid">
              <Salesfunnel />
            </div>
          )}
          {/* <div className="bnw-body-left-botm">
            {hasAccessToResource(role, allowedRoles_1) && (
              <CRS
                backcolor="#F2FFE8"
                text="Current Region Sales"
                value="465356"
                maximum="1000000"
                gradient="#44CE22"
                piebackcolor="#C0FEB1"
              />
            )}
            {hasAccessToResource(role, allowedRoles_1) && <TPR />}
          </div> */}

          {hasAccessToResource(role, allowedRoles_1) && (
            <FinancialPointsperCostChart />
          )}
        </div>

        <div className="bnw-body-right grow">
          {hasAccessToResource(role, allowedRoles_1) && (
            <Agency
              backcolor="#ffffff"
              text="Agencies"
              value={widthval.pie2}
              maximum="500"
              gradient="#32477C"
              piebackcolor="#D1FFF1"
            />
          )}

          {hasAccessToResource(role, allowedRoles_1) && (
            <Agency
              backcolor="#F2FFE8"
              text="Regions"
              value={widthval.pie3}
              maximum="30"
              gradient="#32877C"
              piebackcolor="#D1FFF1"
            />
          )}
          {hasAccessToResource(role, allowedRoles_3) && <TPA />}

          {hasAccessToResource(role, allowedRoles_1) && <TPR />}
        </div>

        {topperformclick === 1 ? (
          <Topperformingagencies />
        ) : topperformclick === 2 ? (
          <Topperformingregions />
        ) : topperformclick === 3 ? (
          <CurrentLiability flownticket={sum_of_all_flown_tickets} />
        ) : topperformclick === 4 ? (
          <ExpectedLiability flownticket={cummulative_ticketed_booking} />
        ) : topperformclick === 5 ? (
          <Claimsequivalent claimseqv={pointsData} />
        ) : topperformclick === 6 ? (
          <UnflownTicketsequv unflowntickets={ticketEquivalentofallUnflown} />
        ) : topperformclick === 7 ? (
          <FlownTicketsequv flowntickets={ticketEquivalent} />
        ) : topperformclick === 8 ? (
          <FlownUnflownTicketsequv
            flownunflownall={ticketEquivalentofallTicketed}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Body;
