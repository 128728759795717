import { createAction, createReducer } from "@reduxjs/toolkit";
const promoCreateRequest = createAction("promoCreateRequest");
const promoCreateFail = createAction("promoCreateFail");
const promoCreateSuccess = createAction("promoCreateSuccess");
const fetchPromoRequest = createAction("fetchPromoRequest");
const fetchPromoSuccess = createAction("fetchPromoSuccess");
const fetchPromoFail = createAction("fetchPromoFail");
const promoDeleteRequest = createAction("promoDeleteRequest");
const promoDeleteSuccess = createAction("promoDeleteSuccess");
const promoDeleteFail = createAction("promoDeleteFail");

const initialState = {
  isLoading: false,
  promodata: [],
  isAuthenticated: false,
};

export const ruleEngineReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(promoCreateRequest, (state, action) => {
      state.isLoading = true;
    })

    .addCase(promoCreateSuccess, (state, action) => {
      state.isLoading = false;
      state.promodata = action.payload;
    })

    .addCase(promoCreateFail, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })

    .addCase(promoDeleteRequest, (state, action) => {
      state.isLoading = true;
    })

    .addCase(promoDeleteSuccess, (state, action) => {
      state.isLoading = false;
      state.promodata = action.payload;
    })

    .addCase(promoDeleteFail, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })

    .addCase(fetchPromoRequest, (state, action) => {
      state.isLoading = true;
    })

    .addCase(fetchPromoSuccess, (state, action) => {
      state.isAuthenticated = true;
      state.isLoading = false;
      state.promodata = action.payload;
    })

    .addCase(fetchPromoFail, (state, action) => {
      state.isAuthenticated = false;
      state.isLoading = false;
      state.error = action.payload;
    });
});
