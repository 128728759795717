import React, { useState, useEffect } from "react";
import "./budget.css";
import ButtonPagenate from "../Component/ButtonPagenate/ButtonPagenate";
import Agenciesallocation from "./agenciesallocation";
import NumberFormat from "react-number-format";
// import { AiFillDelete} from 'react-icons/ai'
import Box from "../Component/Cards/Box/Box";
import Regionsjson from "../Component/Cards/regionsjson";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import { FiUpload } from "react-icons/fi";
import { FcStatistics } from "react-icons/fc";
import mergeAllocationsWithLabels from "../Component/Mergedallocation/mergedallocations";
import formatDateTime from "../Component/DateSelector/Datefunction";
import { Store } from "../Redux/store";
import { captureModalOverlay } from "../Redux/Actions/buttonClick";
import { TbPresentationAnalytics } from "react-icons/tb";
import { sumAllocationsByMarket } from "../utils/summedallocationbymarket";
// import AllocationBox from "../Component/Cards/Box/AllocationBox.js";
import { sumAllocationsByYearAndMarket } from "../utils/summedallocationbyyear.jsx";
import MonthDropdown from "../Component/DateSelector/Getmonthstopresent.jsx";
import currencyUnit from "./Claims/currencyUnit.js";
import { addDecimal } from "../Component/Functions/Numberformat.js";
import { sumAllocationsByMonth } from "../utils/summedTotalallocation.jsx";
import { sumAllocationsByRegionAndMonth } from "../utils/sumAllocationsByRegionAndMonth.jsx";

const Budget = ({ regioncode }) => {
  const [financials, setFinancials] = useState({
    amount_left: 0,
    current_allocation: 0,
    finance_approved_budget: 0,
  });

  const currentDate = new Date();

  const [exportpro, setExportpro] = useState(false);
  const [activetab, setActivetab] = useState(1);
  const [marketdata, setMarketdata] = useState([]);
  const [mycheckbox, setMycheckbox] = useState(false);
  const [currentmonth, setCurrentmonth] = useState("");
  const [currentallocation, setCurrentallocation] = useState();
  const [currentallocationdata, setCurrentallocationdata] = useState([]);

  const {
    allocations,
    role,
    financialdata,
    marketallocationdata,
    paystackwalletdata,
    pocketdata,
    // exchangerate,
  } = useSelector((state) => state.datareponse);

  let formattedallocation = sumAllocationsByYearAndMarket(marketallocationdata);

  // let marketallocation = marketallocationdata.reduce()
  useEffect(() => {
    setMarketdata(sumAllocationsByMarket(marketallocationdata, currentmonth));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketallocationdata, currentmonth]);

  useEffect(() => {
    setCurrentallocation(sumAllocationsByMonth(allocations, currentmonth));
  }, [currentmonth, allocations]);

  useEffect(() => {
    setCurrentallocationdata(
      sumAllocationsByRegionAndMonth(allocations, currentmonth)
    );
  }, [currentmonth, allocations]);

  useEffect(() => {
    setFinancials({ ...financials, ...financialdata });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [financialdata]);

  // const mergedAllocations = mergeAllocationsWithLabels(
  //   allocations,
  //   Regionsjson
  // );

  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerpage = 15; //Number of items to be displayed per page
  const pageCount = Math.ceil(pocketdata.length / itemsPerpage);
  const pagesVisited = pageNumber * itemsPerpage;

  const newPagenum = (num) => {
    setPageNumber(num);
  };

  const handleAddButtn = (props) => {
    if (props === "newallocation") {
      Store.dispatch(captureModalOverlay(props));
    } else {
      Store.dispatch(captureModalOverlay(props));
    }
  };

  // const currMonth = new Date().toLocaleString([], {
  //   month: "long",
  // });

  const handleCountryClick = async (e, countryid) => {
    // // console.log(countryid, e);
  };

  const handleHover = () => {
    setExportpro(!exportpro);
  };

  const handleExportCSV = () => {};

  const csvHeaders = [
    { label: "Id", key: "ID" },
    { label: "Country", key: "label" },
    { label: "Allocation", key: "allocation" },
    { label: "CreatedAt", key: "CreatedAt" },
  ];

  // const CumBudget = mergedAllocations.reduce(
  //   (accumulator, currentValue) => accumulator + currentValue.allocation,
  //   0
  // );

  // const CumAllocation = marketdata.reduce(
  //   (accumulator, currentValue) => accumulator + currentValue.allocation,
  //   0
  // );y

  const handleTabChange = (props) => {
    if (props === "tab_1") {
      setActivetab(1);
    } else if (props === "tab_2") {
      setActivetab(2);
    } else if (props === "tab_3") {
      setActivetab(3);
    }
  };

  const handleOnChange = (props) => {
    setCurrentmonth(props);
  };

  return (
    <div className="bnw-body-container-tab">
      <div className="w-full p-2 gap-5 border rounded-5 flex justify-between items-center sticky top-0 z-20 bg-[white] rounded-[8px]">
        <div className="flex items-center gap-4">
          <div className="flex item-center gap-1">
            <input
              type="checkbox"
              onChange={() => setMycheckbox(!mycheckbox)}
              checked={mycheckbox ? true : false}
              className="cursor-pointer"
            />
            <label
              htmlFor="checkbox"
              className="text-[12px] font-[500] text-[green]"
            >
              Team I
            </label>
          </div>

          <div className="flex item-center gap-1">
            <input
              type="checkbox"
              onChange={() => setMycheckbox(!mycheckbox)}
              checked={!mycheckbox ? true : false}
              className="cursor-pointer"
            />
            <label
              htmlFor="checkbox"
              className="text-[12px] font-[500] text-[red]"
            >
              Team II
            </label>
          </div>
        </div>

        <MonthDropdown onChange={handleOnChange} />
      </div>

      <div className="budget-overview-break">
        {/* {role === "FINANCE" && (
          <Box
            color="#ffbb7c"
            tooltiptext={`Budget Remaining / Finance Budget for ${currMonth}`}
            boxname="Non-Active Ponts"
            boxvalue={
              <>
                <NumberFormat
                  value={AllocationBalance}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />{" "}
                /{" "}
                <NumberFormat
                  value={BudgetCum}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </>
            }
          />
        )} */}

        {role === "ADMIN" && (
          <Box
            color="#7b7dfd"
            tooltiptext={`Budget Approved by finance for ${currentDate.toLocaleString(
              "default",
              { month: "long" }
            )}`}
            boxname={`Finance Approved Budgets - ${currentmonth}`}
            boxvalue={
              <NumberFormat
                // value={
                //   !mycheckbox
                //     ? formattedallocation[currentDate.getFullYear()]?.TeamII
                //     : formattedallocation[currentDate.getFullYear()]?.TeamI
                // }
                value={
                  marketdata?.length > 0
                    ? !mycheckbox
                      ? marketdata?.find(
                          (marketitem) => marketitem.market === "TeamII"
                        )?.allocation
                      : marketdata?.find(
                          (marketitem) => marketitem.market === "TeamI"
                        )?.allocation
                    : "0"
                }
                displayType={"text"}
                thousandSeparator={true}
                prefix={!mycheckbox ? "$" : "₦"}
              />
            }
          />
        )}

        {role === "ADMIN" && (
          <Box
            color="#6dffb4"
            tooltiptext={`Balance Left in wallet as of ${currentDate.toLocaleString(
              "default",
              { month: "long" }
            )}`}
            boxname="Wallet Balance"
            boxvalue={
              <NumberFormat
                value={
                  !mycheckbox
                    ? pocketdata.find((regionpocket) => {
                        return regionpocket.country === "US";
                      })?.pocketBalance
                    : addDecimal(paystackwalletdata)
                }
                displayType={"text"}
                thousandSeparator={true}
                prefix={!mycheckbox ? "$" : "₦"}
              />
            }
          />
        )}

        {role === "ADMIN" && (
          <Box
            color="#ff71ba"
            tooltiptext={`Summation of Allocation made to regions in ${currentDate.toLocaleString(
              "default",
              { month: "long" }
            )}`}
            boxname={`${currentmonth} Allocation`}
            boxvalue={
              <NumberFormat
                // value={
                //   !mycheckbox
                //     ? financials.current_allocation
                //     : formattedallocation[currentDate.getFullYear()].TeamI -
                //       paystackwalletdata / 100
                // }
                value={
                  !mycheckbox
                    ? currentallocation
                    : formattedallocation[currentDate.getFullYear()].TeamI -
                      paystackwalletdata / 100
                }
                displayType={"text"}
                thousandSeparator={true}
                prefix={!mycheckbox ? "$" : "₦"}
              />
            }
          />
        )}

        {/* {role.toLowerCase().includes("FINANCE") && (
          <Box
            color="#B7B8F9"
            tooltiptext={`Invalid / Unflown Ticket ${currMonth}`}
            boxname="Claims"
            boxvalue={
              <>
                <NumberFormat
                  value={AllocationBalance}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                />{" "}
                /{" "}
                <NumberFormat
                  value={BudgetCum}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                />
              </>
            }
          />
        )} */}

        {/* {role.includes("FINANCE") && (
          <Box
            color="#F9D7B7"
            tooltiptext="All Flown Tickets"
            boxname="Points Eqv."
            boxvalue={
              <NumberFormat
                value={CumunflownTickets}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            }
          />
        )} */}

        {/* {role.includes("FINANCE") && (
          <Box
            color="#B7F9D7"
            tooltiptext="Outstanding Payment"
            boxname="Amount Due"
            boxvalue={
              <NumberFormat
                value={CumTicketEquv_USD}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            }
          />
        )} */}

        {/* {role.includes("FINANCE") && (
          <Box
            color="#F9B7D9"
            tooltiptext="Current Payment: All requested Claims by agents"
            boxname="All Claims"
            boxvalue={
              <NumberFormat
                value={CumTicketEquv_USD}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            }
          />
        )} */}
      </div>

      <div className="w-full flex flex-col gap-2">
        <div className="w-full justify-between flex bg-[#e50000] p-1">
          <div className="flex itmes-center gap-1">
            <div
              className={`relative border flex justify-center items-center ${
                activetab === 1 ? "bg-[#fff9]" : ""
              }`}
              onClick={() => handleTabChange("tab_1")}
            >
              <span className="flex items-center px-2 py-1 text-center cursor-pointer text-[#fff] text-[12px] font-[600]">
                POCKET
              </span>

              <FcStatistics size={25} color="#fff" />
            </div>

            <div
              className={`relative border flex justify-center items-center ${
                activetab === 3 ? "bg-[#fff9]" : ""
              }`}
              onClick={() => handleTabChange("tab_3")}
            >
              <span className="flex items-center px-2 py-1 text-center cursor-pointer text-[#fff] text-[12px] font-[600]">
                ALLOCATED FUNDS
              </span>

              <FcStatistics size={25} color="#fff" />
            </div>

            <div
              className={`relative border flex justify-center items-center  ${
                activetab === 2 ? "bg-[#fff9]" : ""
              }`}
              onClick={() => handleTabChange("tab_2")}
            >
              <span className="flex items-center px-2 py-1 text-center cursor-pointer text-[#fff] text-[12px] font-[600]">
                MARKET BUDGET
              </span>

              <TbPresentationAnalytics size={25} color="#fff" />
            </div>
          </div>

          <CSVLink data={pocketdata} headers={csvHeaders}>
            <div
              className={`relative border px-2 py-1 cursor-pointer rounded-md flex justify-center items-center ${
                exportpro ? "bg-[white]" : "bg-[#e50000]"
              }`}
              onMouseEnter={handleHover}
              onMouseLeave={handleHover}
              onClick={handleExportCSV}
            >
              <FiUpload size={18} color={exportpro ? "#e50000" : "#fff"} />
              <span
                className={`px-2 py-1 cursor-pointer ${
                  exportpro ? "text-[#e50000]" : "text-[#fff]"
                } text-[11px] font-[600]`}
              >
                Export
              </span>
            </div>
          </CSVLink>
        </div>

        {activetab === 1 ? (
          <table>
            <thead>
              <tr>
                <th scope="col" className="text-[11px]">
                  Country
                </th>
                <th scope="col" className="text-[11px]">
                  PocketID
                </th>
                <th scope="col" className="text-[11px]">
                  Pocket Balance
                </th>
              </tr>
            </thead>

            <tbody>
              {pocketdata
                .filter(
                  (item) => item.country !== "NG" && item.country !== "US"
                )
                .slice(pagesVisited, pagesVisited + itemsPerpage)
                .map((tdata) => (
                  <tr
                    className="body-tab-row cursor-pointer"
                    key={tdata.ID}
                    onClick={(e) => handleCountryClick(e, tdata.ID)}
                  >
                    <td data-label="Country" className="text-[12px]">
                      {
                        Regionsjson.find(
                          (items) => items.value === tdata.country
                        ).label
                      }
                    </td>
                    <td data-label="PocketID" className="text-[12px]">
                      {tdata.pocketId}
                    </td>
                    <td data-label="Local Eqv." className="text-[12px]">
                      <NumberFormat
                        value={tdata.pocketBalance}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={tdata.currency + " "}
                      />
                    </td>
                  </tr>
                ))}

              {/* <tr className="body-tab-rowws text-[11px]">
                <td data-label="">Total</td>
                <td data-label="Flown Tkts.">
                  <NumberFormat
                    value={CumBudget}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </td>
                <td data-label=""></td>
              </tr> */}
            </tbody>
          </table>
        ) : activetab === 2 ? (
          <table>
            <thead>
              <tr>
                <th scope="col" className="text-[11px]">
                  ID
                </th>

                <th scope="col" className="text-[11px]">
                  Team
                </th>
                <th scope="col" className="text-[11px]">
                  Budget
                </th>
              </tr>
            </thead>

            <tbody>
              {marketdata
                .slice(pagesVisited, pagesVisited + itemsPerpage)
                .map((tdata, index) => (
                  <tr
                    className="body-tab-row cursor-pointer"
                    key={index}
                    onClick={(e) => handleCountryClick(e, tdata.id)}
                  >
                    <td data-label="ID" className="text-[12px]">
                      {index + 1}
                    </td>

                    <td data-label="Team" className="text-[12px]">
                      {tdata.market === "TeamI" ? "Team I" : "Team II"}
                    </td>

                    <td data-label="Budget" className="text-[12px]">
                      {
                        <NumberFormat
                          value={tdata.allocation}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={tdata.market === "TeamII" ? "$" : "₦"}
                        />
                      }
                    </td>
                  </tr>
                ))}
              <tr className="body-tab-rowws text-[11px]">
                <td data-label="Local Equv."></td>

                <td data-label=""></td>

                <td data-label="Budget">
                  {/* <NumberFormat
                    value={CumAllocation}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  /> */}
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <table>
            <thead>
              <tr>
                <th scope="col" className="text-[11px]">
                  Country
                </th>
                <th scope="col" className="text-[11px]">
                  Allocation
                </th>
              </tr>
            </thead>

            <tbody>
              {currentallocationdata
                ?.slice(pagesVisited, pagesVisited + itemsPerpage)
                ?.map((tdata) => (
                  <tr
                    className="body-tab-row cursor-pointer"
                    key={tdata.ID}
                    onClick={(e) => handleCountryClick(e, tdata.ID)}
                  >
                    <td data-label="Country" className="text-[12px]">
                      {
                        Regionsjson.find(
                          (items) => items.value === tdata?.region_code
                        )?.label
                      }
                    </td>
                    <td data-label="Allocation" className="text-[12px]">
                      <NumberFormat
                        value={tdata.allocation}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix="$ "
                      />
                    </td>
                  </tr>
                ))}

              {/* <tr className="body-tab-rowws text-[11px]">
                <td data-label="">Total</td>
                <td data-label="Flown Tkts.">
                  <NumberFormat
                    value={CumBudget}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </td>
                <td data-label=""></td>
              </tr> */}
            </tbody>
          </table>
        )}
      </div>

      <div className="bnw-table-pgnate-contain mt-2	">
        {/* {role === "ADMIN" && ( */}
        {activetab === 3 ? (
          <button
            id="addbitn"
            className="text-[13px]"
            onClick={() => handleAddButtn("newallocation")}
          >
            Add
          </button>
        ) : activetab === 2 ? (
          <button
            id="addbitn"
            className="text-[13px]"
            onClick={() => handleAddButtn("newbudget")}
          >
            Create Budget
          </button>
        ) : (
          activetab === 1 && (
            <button
              id="addbitn"
              className="text-[13px]"
              onClick={() => handleAddButtn("newpocket")}
            >
              Create Pocket
            </button>
          )
        )}
        {/* )} */}

        <ButtonPagenate
          updatedPageNum={newPagenum}
          pageCount={pageCount}
          pageNumber={pageNumber}
          previousLabel="Prev"
          nextLabel="Next"
        />
      </div>
    </div>
  );
};

export default Budget;
