// export const Liability = (points, exchangeRates) => {
//   // Create a map to store summed values for each region_code
//   const summedValues = {};

//   // Calculate summed values for each region_code
//   points.forEach((point) => {
//     if (!summedValues[point.region_code]) {
//       summedValues[point.region_code] = {
//         summed: 0,
//         region_code: point.region_code,
//       };
//     }
//     summedValues[point.region_code].summed++;
//   });

//   // console.log(summedValues);

//   // Calculate equivalent_sum and equivalent_sum_usd
//   const resultArray = Object.values(summedValues).map((summedValue) => {
//     const exchangeRate = exchangeRates?.find(
//       (rate) => rate.region_code === summedValue.region_code
//     );
//     const equivalentSum =
//       summedValue.summed * (exchangeRate ? exchangeRate.amountPerDollar : 0);
//     const equivalentSumUSD =
//       equivalentSum / (exchangeRate ? exchangeRate.amountPerLocalCurrency : 1);

//     return {
//       summed: summedValue.summed,
//       equivalent_sum: equivalentSum,
//       equivalent_sum_usd: parseFloat(equivalentSumUSD.toFixed(1)),
//       region_code: summedValue.region_code,
//     };
//   });

//   return resultArray;
// };

// export const Liability = (points, claimsdata, exchangeRates) => {
//   // Step 1: Count occurrences of each region_code in claimsdata
//   const claimsOccurrence = claimsdata.reduce((acc, claim) => {
//     acc[claim.region_code] = (acc[claim.region_code] || 0) + 1;
//     return acc;
//   }, {});

//   // Step 2: Count occurrences of each region_code in points
//   const pointsOccurrence = points.reduce((acc, point) => {
//     acc[point.region_code] = (acc[point.region_code] || 0) + 1;
//     return acc;
//   }, {});

//   // Step 3: Calculate the difference between pointsOccurrence and claimsOccurrence
//   const finalOccurrence = Object.keys(pointsOccurrence).map((region_code) => ({
//     region_code,
//     summed:
//       (pointsOccurrence[region_code] || 0) -
//       (claimsOccurrence[region_code] || 0),
//   }));

//   // Step 4 and 5: Calculate equivalent_sum and equivalent_sum_usd
//   const resultArray = finalOccurrence.map((item) => {
//     const exchangeRate = exchangeRates?.find(
//       (rate) => rate.region_code === item.region_code
//     );
//     const equivalent_sum =
//       item.summed * (exchangeRate ? exchangeRate.amountPerDollar : 0);
//     const equivalent_sum_usd =
//       equivalent_sum / (exchangeRate ? exchangeRate.amountPerLocalCurrency : 1);
//     return {
//       ...item,
//       equivalent_sum,
//       equivalent_sum_usd,
//     };
//   });

//   return resultArray;
// };

// export const Liability = (points, claimsdata, exchangeRates) => {
//   // Step 1: Sum the "points" of regions that are similar to form a new array
//   const claimsOccurrence = claimsdata.reduce((acc, claim) => {
//     acc[claim.region_code] = (acc[claim.region_code] || 0) + claim.points;
//     return acc;
//   }, {});

//   // Step 2: Sum the "points" of regions in points data
//   const pointsOccurrence = points.reduce((acc, point) => {
//     acc[point.region_code] = (acc[point.region_code] || 0) + point.points;
//     return acc;
//   }, {});

//   // Step 3: Calculate the difference between pointsOccurrence and claimsOccurrence
//   const finalOccurrence = Object.keys(pointsOccurrence).map((region_code) => ({
//     region_code,
//     summed:
//       (pointsOccurrence[region_code] || 0) -
//       (claimsOccurrence[region_code] || 0),
//   }));

//   // Step 4 and 5: Calculate equivalent_sum and equivalent_sum_usd
//   const resultArray = finalOccurrence.map((item) => {
//     const exchangeRate = exchangeRates.find(
//       (rate) => rate.region_code === item.region_code
//     );
//     const equivalent_sum =
//       item.summed * (exchangeRate ? exchangeRate.amountPerDollar : 0);
//     const equivalent_sum_usd =
//       equivalent_sum /
//       (exchangeRate ? exchangeRate.amount_per_local_currency : 1);
//     return {
//       ...item,
//       equivalent_sum,
//       equivalent_sum_usd,
//     };
//   });

//   return resultArray;
// };

// export const Liability = (points, claimsdata, exchangeRates) => {
//   // Step 1: Sum the "points" of regions that are similar
//   const claimsOccurrence = claimsdata.reduce((acc, claim) => {
//     acc[claim.region_code] = (acc[claim.region_code] || 0) + claim.points;
//     return acc;
//   }, {});

//   // // console.log(claimsOccurrence);

//   // Step 2: Sum the "points" of regions that are similar from the "points" array
//   const pointsOccurrence = points.reduce((acc, point) => {
//     acc[point.region_code] = (acc[point.region_code] || 0) + 1;
//     return acc;
//   }, {});

//   // // console.log(pointsOccurrence);

//   // Step 3: Calculate the difference between pointsOccurrence and claimsOccurrence
//   const finalOccurrence = Object.keys(pointsOccurrence).map((region_code) => ({
//     region_code,
//     summed:
//       (pointsOccurrence[region_code] || 0) -
//       (claimsOccurrence[region_code] || 0),
//   }));

//   // Step 4: Calculate equivalent_sum and equivalent_sum_usd
//   const resultArray = finalOccurrence.map((item) => {
//     const exchangeRate = exchangeRates?.find(
//       (rate) => rate.region_code === item.region_code
//     );
//     const equivalent_sum =
//       item.summed * (exchangeRate ? exchangeRate.amountPerDollar : 0);
//     const equivalent_sum_usd =
//       equivalent_sum / (exchangeRate ? exchangeRate.amountPerLocalCurrency : 1);
//     return {
//       ...item,
//       equivalent_sum,
//       equivalent_sum_usd,
//     };
//   });
//   return resultArray;
// };

// export const Liability = (points, claimsdata, exchangeRates) => {
//   // Step 1: Sum the "points" of regions that are similar, considering payment_mode
//   const claimsOccurrence = claimsdata.reduce((acc, claim) => {
//     const key = `${claim.region_code}-${claim?.payment_info?.payment_mode}`;
//     acc[key] = (acc[key] || 0) + claim.points;
//     return acc;
//   }, {});

//   // Step 2: Sum the "points" of regions that are similar from the "points" array, considering payment_mode
//   const pointsOccurrence = points.reduce((acc, point) => {
//     const key = `${point.region_code}-${point?.payment_info?.payment_mode}`;
//     acc[key] = (acc[key] || 0) + 1;
//     return acc;
//   }, {});

//   // const pointsOccurrence = points.reduce((acc, point) => {
//   //   acc[point.region_code] = (acc[point.region_code] || 0) + 1;
//   //   return acc;
//   // }, {});

//   // Step 3: Calculate the difference between pointsOccurrence and claimsOccurrence
//   const finalOccurrence = Object.keys(pointsOccurrence).map((key) => {
//     const [region_code, payment_mode] = key.split("-");
//     return {
//       region_code,
//       payment_mode,
//       summed: (pointsOccurrence[key] || 0) - (claimsOccurrence[key] || 0),
//     };
//   });

//   // console.log(finalOccurrence);

//   // Step 4: Calculate equivalent_sum and equivalent_sum_usd
//   const resultArray = finalOccurrence.map((item) => {
//     const exchangeRate = exchangeRates?.find(
//       (rate) => rate.region_code === item.region_code
//     );
//     const equivalent_sum =
//       item.summed * (exchangeRate ? exchangeRate.amountPerDollar : 0);
//     const equivalent_sum_usd =
//       equivalent_sum / (exchangeRate ? exchangeRate.amountPerLocalCurrency : 1);
//     return {
//       ...item,
//       equivalent_sum,
//       equivalent_sum_usd,
//     };
//   });

//   return resultArray;
// };

// export const Liability = (points, claimsdata, exchangeRates) => {
//   // Step 1: Sum the points in claimsdata, grouped by region_code and payment_mode
//   // console.log(claimsdata);
//   // console.log(points);
//   const claimsOccurrence = claimsdata.reduce((acc, claim) => {
//     const key = `${claim.region_code}-${claim.payment_info.payment_mode}`;
//     acc[key] = (acc[key] || 0) + claim.points;
//     return acc;
//   }, {});

//   // console.log(claimsOccurrence);

//   // Step 2: Count the points in the points array, grouped by region_code
//   const pointsOccurrence = points.reduce((acc, point) => {
//     const key = `${point.region_code}`;
//     acc[key] = (acc[key] || 0) + 1;
//     return acc;
//   }, {});

//   // console.log(pointsOccurrence);

//   // Step 3: Calculate the difference between pointsOccurrence and claimsOccurrence
//   const finalOccurrence = Object.keys(claimsOccurrence).map((key) => {
//     // console.log(claimsOccurrence);
//     const [region_code, payment_mode] = key.split("-");
//     // console.log(region_code, payment_mode);

//     const summed =
//       pointsOccurrence[region_code] || 0 - claimsOccurrence[key] || 0;
//     // console.log(summed);

//     // console.log(region_code, payment_mode, summed);

//     return {
//       region_code,
//       payment_mode,
//       summed,
//     };
//   });

//   // console.log(finalOccurrence);

//   // Step 4: Calculate equivalent_sum and equivalent_sum_usd
//   const resultArray = finalOccurrence.map((item) => {
//     const exchangeRate = exchangeRates?.find(
//       (rate) => rate.region_code === item.region_code
//     );
//     const equivalent_sum =
//       item.summed * (exchangeRate ? exchangeRate.amountPerDollar : 0);
//     const equivalent_sum_usd =
//       equivalent_sum / (exchangeRate ? exchangeRate.amountPerLocalCurrency : 1);
//     return {
//       ...item,
//       equivalent_sum,
//       equivalent_sum_usd,
//     };
//   });

//   return resultArray;
// };

export const Liability = (points, claimsdata, exchangeRates) => {
  // Step 1: Sum the points in claimsdata, grouped by region_code and payment_mode

  const claimsOccurrence = claimsdata
    .filter((byclaimstatus) => byclaimstatus.claim_status === 1)
    .reduce((acc, claim) => {
      const key = `${claim?.region_code}-${claim?.payment_info?.payment_mode}`;
      acc[key] = (acc[key] || 0) + claim.points;
      return acc;
    }, {});

  // Step 2: Count the points in the points array, grouped by region_code and payment_mode
  const pointsOccurrence = points.reduce((acc, point) => {
    const paymentMode = point?.payment_info?.payment_mode || "NOT SET";
    const key = `${point?.region_code}-${paymentMode}`;
    acc[key] = (acc[key] || 0) + 1;
    return acc;
  }, {});

  // Step 3: Calculate the difference between pointsOccurrence and claimsOccurrence
  const finalOccurrence = Object.keys(pointsOccurrence).map((key) => {
    const [region_code, payment_mode] = key.split("-");

    const pointsInRegion = pointsOccurrence[key] || 0;
    const claimedPointsInRegion = claimsOccurrence[key] || 0;

    const summed = pointsInRegion - claimedPointsInRegion;

    return {
      region_code,
      payment_mode,
      summed,
    };
  });

  // Step 4: Calculate equivalent_sum and equivalent_sum_usd
  const resultArray = finalOccurrence.map((item) => {
    const exchangeRate = exchangeRates?.find(
      (rate) => rate.region_code === item.region_code
    );
    // const equivalent_sum =
    //   item.summed * (exchangeRate ? exchangeRate.amountPerDollar : 0);
    // const equivalent_sum_usd =
    //   equivalent_sum / (exchangeRate ? exchangeRate.amountPerLocalCurrency : 1);

    const equivalent_sum =
      item.summed *
      (exchangeRate
        ? exchangeRate.defaultPromo && exchangeRate.defaultPromo.active === 1
          ? exchangeRate.defaultPromo.amount_per_local_currency
          : exchangeRate.amountPerLocalCurrency
        : 0);

    const equivalent_sum_usd =
      equivalent_sum /
      (exchangeRate
        ? exchangeRate.defaultPromo && exchangeRate.defaultPromo.active === 1
          ? exchangeRate.defaultPromo.amount_per_dollar
          : exchangeRate.amountPerDollar
        : 1);

    return {
      ...item,
      equivalent_sum,
      equivalent_sum_usd,
    };
  });

  return resultArray;
};
