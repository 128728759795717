import React from "react";
import { FaShoppingCart, FaGlobeAfrica, FaBuilding } from "react-icons/fa";
import { RiDashboardFill, RiRefund2Line } from "react-icons/ri";
import { GiCash, GiWallet, GiShinyPurse } from "react-icons/gi";
import { HiUserGroup } from "react-icons/hi";
import { MdTune } from "react-icons/md";
import { RiLogoutCircleFill } from "react-icons/ri";
import { GrTransaction } from "react-icons/gr";
import FunctionComponent from "./FunctionComponent";

const Sidebar = ({ changeWidth }) => {
  const iconsObjt = {
    1: RiDashboardFill,
    2: FaShoppingCart,
    3: HiUserGroup,
    4: FaBuilding,
    5: MdTune,
    6: RiLogoutCircleFill,
    7: GiCash,
    8: FaGlobeAfrica,
    9: GiWallet,
    10: RiRefund2Line,
    11: GrTransaction,
    12: GiShinyPurse,
  };

  return (
    <div
      className={`${
        changeWidth ? "w-64" : "w-0"
      } h-screen flex flex-col sticky top-0 shadow-[4px_0px_10px_rgba(0,0,0,0.1)]`}
    >
      <ul>
        <FunctionComponent
          id="Main"
          icon={iconsObjt[1]} // Pass the icon component directly
          label="Main Page"
          path="/dashboard"
          rolesAllowed={["USER", "ADMIN", "DIRECTOR", "DEVELOPER"]}
        />
        <FunctionComponent
          id="Budget"
          icon={iconsObjt[10]} // Pass the icon component directly
          label="Budget"
          path=""
          rolesAllowed={["ADMIN", "DEVELOPER"]}
        />
        <FunctionComponent
          id="Claim"
          icon={iconsObjt[7]} // Pass the icon component directly
          label="Claim"
          path=""
          rolesAllowed={["ADMIN", "DEVELOPER"]}
        />
        <FunctionComponent
          id="Claims"
          icon={iconsObjt[7]} // Pass the icon component directly
          label="Claims"
          path=""
          rolesAllowed={["DIRECTOR, DEVELOPER", "DIRECTOR"]}
        />
        <FunctionComponent
          id="Subpockets"
          icon={iconsObjt[12]} // Pass the icon component directly
          label="Subpockets"
          path=""
          rolesAllowed={["DEVELOPER"]}
        />
        <FunctionComponent
          id="Wallet"
          icon={iconsObjt[9]} // Pass the icon component directly
          label="Pocket"
          path=""
          rolesAllowed={["ADMIN", "DEVELOPER"]}
        />
        <FunctionComponent
          id="Region"
          icon={iconsObjt[8]} // Pass the icon component directly
          label="Regions"
          path=""
          rolesAllowed={["ADMIN", "DEVELOPER"]}
        />
        <FunctionComponent
          id="exrate"
          icon={iconsObjt[7]} // Pass the icon component directly
          label="Exchange Rate"
          path=""
          rolesAllowed={["FINANCE", "DEVELOPER"]}
        />
        <FunctionComponent
          id="Directors"
          icon={iconsObjt[3]} // Pass the icon component directly
          label="Directors"
          path=""
          rolesAllowed={["ADMIN", "DEVELOPER"]}
        />
        <FunctionComponent
          id="Transactions"
          icon={iconsObjt[11]} // Pass the icon component directly
          label="Transactions"
          path=""
          rolesAllowed={["DIRECTOR", "DEVELOPER"]}
        />
        <FunctionComponent
          id="Agencies"
          icon={iconsObjt[4]} // Pass the icon component directly
          label="Agencies"
          path=""
          rolesAllowed={["USER", "ADMIN", "DIRECTOR", "DEVELOPER"]}
        />
        <FunctionComponent
          id="Settings"
          icon={iconsObjt[5]} // Pass the icon component directly
          label="Settings"
          path=""
          rolesAllowed={["ADMIN", "DEVELOPER"]}
        />
        <FunctionComponent
          id="Logout"
          icon={iconsObjt[6]} // Pass the icon component directly
          label="Logout"
          path="/"
          rolesAllowed={["USER", "ADMIN", "DIRECTOR", "DEVELOPER"]}
        />
      </ul>
    </div>
  );
};

export default Sidebar;
