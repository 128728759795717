import React from "react";
import Users from "../Users";

const Editdirector = ({ setloading }) => {
  return (
    <div className="overlay-container z-10 ">
      <div className="w-full min-w-[280px] max-w-[60%]">
        <Users />
      </div>
    </div>
  );
};

export default Editdirector;
