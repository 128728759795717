export const AllflownTickets = (flown, exrate) => {
  // const claimedPointTeamI = pointsData
  //   .filter((item) => item.region_code === "NG")
  //   .reduce((accumulator, currentValue) => {
  //     const totalPoints = currentValue.points;
  //     return accumulator + totalPoints;
  //   }, 0);

  const result = flown.map((itemA) => {
    // Find the matching item in array exrate based on region_code
    const matchingItemB = exrate?.find(
      (itemB) => itemB.region_code === itemA.region_code
    );

    if (matchingItemB) {
      const ticketequv =
        matchingItemB.defaultPromo && matchingItemB.defaultPromo.active === 1
          ? matchingItemB.defaultPromo.amount_per_local_currency
          : matchingItemB.amountPerLocalCurrency;

      const USDequv = parseFloat(
        (
          ticketequv /
          (matchingItemB.defaultPromo && matchingItemB.defaultPromo.active === 1
            ? matchingItemB.defaultPromo.amount_per_dollar
            : matchingItemB.amountPerDollar)
        ).toFixed(2)
      );

      return {
        ...itemA,
        ticketequv,
        USDequv,
      };
    } else {
      // If no matching item is found, return itemA as it is
      return itemA;
    }
  });

  return result;
};
