// export const sumAllocationsByMarket = (data, month) => {
//   console.log(data, month);
//   const result = [];

//   // Group data by market
//   const groupedByMarket = data.reduce((acc, item) => {
//     const market = item.market;
//     if (!acc[market]) {
//       acc[market] = [];
//     }
//     acc[market].push(item.allocation);
//     return acc;
//   }, {});

//   // Sum allocations for each market
//   for (const market in groupedByMarket) {
//     const sum = groupedByMarket[market].reduce(
//       (total, allocation) => total + allocation,
//       0
//     );
//     result.push({
//       market: market,
//       allocation: sum,
//     });
//   }

//   return result;
// };

export const sumAllocationsByMarket = (data, selectedMonth) => {
  const result = [];

  // Group data by market
  const groupedByMarket = data.reduce((acc, item) => {
    const market = item.market;

    // Extract the month name from the created_at date
    const createdAtDate = new Date(item.created_at);
    const monthName = createdAtDate.toLocaleString("default", {
      month: "long",
    });

    // Check if the month matches the selected month
    if (monthName === selectedMonth) {
      if (!acc[market]) {
        acc[market] = [];
      }
      acc[market].push(item.allocation);
    }

    return acc;
  }, {});

  // Sum allocations for each market
  for (const market in groupedByMarket) {
    const sum = groupedByMarket[market].reduce(
      (total, allocation) => total + allocation,
      0
    );
    result.push({
      market: market,
      allocation: sum,
    });
  }

  return result;
};
