// export const server = "https://estate-mgt-backend.omotoyecda.org/ems";

export const API = "https://admin.sabrecwa.com/backend";
export const SeerbitAPI = "https://pocket.seerbitapi.com";
// export const NodeServer_API = "https://9626-41-204-236-230.ngrok-free.app/bookandwin";
export const Paystack_url = "https://api.paystack.co";

// export const NodeServer_API =
//   "https://m6hsr3z5-4000.uks1.devtunnels.ms/bookandwin";

// export const NodeServer_API = "http://localhost:4000/bookandwin";

export const NodeServer_API =
  "https://incentive.sabrecwa.com/backend/bookandwin";

export const SabreCertAPI =
  "https://api.cert.platform.sabre.com/v1/lists/utilities/airlines";
export const SabreAuthAPI = "http://localhost:5000/bawscript";
