import React, { useState, useEffect } from "react";
import "./addoverlay.css";
// import Regionsjson from './regionsjson'
import { CgChevronDown, CgCloseO } from "react-icons/cg";
// import Select from 'react-select';
import axios from "axios";
import { useCookies } from "react-cookie";
// import SLoader from '../Loading/SigninLoader';
import Error from "../Error";
import Loader from "../Loading/Loading";
import { FaChevronDown } from "react-icons/fa";
import { NodeServer_API } from "../../server";
import { toast } from "react-toastify";
import { getAllDirectors } from "../../Redux/Actions/dataResponse";
import { Store } from "../../Redux/store";
// import PasswordInputField from '../Passwordverify/password';
// import ConfirmPasswordInputField from '../Passwordverify/Confirmpassword';

const AddDirectors = ({ closeOverlay, setExpire }) => {
  const [cookies] = useCookies(["Token"]);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [eemail, setEemail] = useState("");

  const [confirmload, setConfirmload] = useState(false);
  const [confirmresponse, setConfirmresponse] = useState(0);
  const [expiration, setExpiration] = useState();
  const [responses, setResponses] = useState();
  const [errrespnse, setErrresponse] = useState("");
  const [regionsjson, setRegionsjson] = useState([]);
  const [region, setRegion] = useState(false);
  const [regionCode, setRegionCode] = useState({
    rc: "",
    rn: "",
  });

  useEffect(() => {
    SetIncoming();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SetIncoming = async () => {
    try {
      var config = {
        method: "GET",
        url: `${NodeServer_API}/regions`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        if (response.data.success === true) {
          // // console.log(response.data.data)
          setRegionsjson(response.data.data);
        }
      });
    } catch (e) {
      if (e.message.includes("401")) {
        // // console.log(`Error `)
      } else {
        // setConfirmresponse(3)
        setExpire();
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setConfirmload(true);
    try {
      var config = {
        method: "POST",
        url: `${NodeServer_API}/register`,
        data: {
          first_name: firstname,
          last_name: lastname,
          email: eemail,
          region_code: regionCode.rc,
        },
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      // // console.log(config)

      if (
        config.data.first_name !== "" &&
        config.data.last_name !== "" &&
        config.data.email !== "" &&
        config.data.region_code !== ""
      ) {
        await axios(config).then((response) => {
          if (response.data.success === true) {
            Store.dispatch(getAllDirectors(response.data.data));
            toast.success(response.data.message);
            setConfirmload(false);
          }
        });
      } else {
        // // console.log("Elseee")
        setConfirmload(false);
        setErrresponse("Incomplete Fields");
        setConfirmresponse(2);
      }
    } catch (e) {
      if (e.message.includes("401")) {
        // // console.log(`Error `)
        setConfirmload(false);
        setConfirmresponse(3);
        setExpiration(true);
      } else {
        setConfirmload(false);
        Store.dispatch(getAllDirectors(e.response.data.data));
        toast.error(e.response.data.message);
      }
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "fname") {
      setFirstname(e.target.value);
    }

    if (e.target.name === "lname") {
      setLastname(e.target.value);
    }

    if (e.target.name === "email") {
      setEemail(e.target.value);
    }
  };

  const closeConfirm = () => {
    closeOverlay();
  };

  const handleClose = () => {
    setConfirmresponse(0);
    closeOverlay();
  };

  const handleRegionFocus = () => {
    setRegion(!region);
  };

  // const handlePasswordChange =(evnt)=>{
  //     const passwordInputValue = evnt.target.value.trim();
  //     const passwordInputFieldName = evnt.target.name;
  //     const NewPasswordInput = {...passwordInput,[passwordInputFieldName]:passwordInputValue}
  //     setPasswordInput(NewPasswordInput);

  // }

  // const handleValidation= (evnt)=>{
  //     const passwordInputValue = evnt.target.value.trim();
  //     const passwordInputFieldName = evnt.target.name;
  //       //for password
  //       if(passwordInputFieldName==='password'){
  //         const uppercaseRegExp   = /(?=.*?[A-Z])/;
  //         const lowercaseRegExp   = /(?=.*?[a-z])/;
  //         const digitsRegExp      = /(?=.*?[0-9])/;
  //         const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
  //         const minLengthRegExp   = /.{8,}/;
  //         const passwordLength =      passwordInputValue.length;
  //         const uppercasePassword =   uppercaseRegExp.test(passwordInputValue);
  //         const lowercasePassword =   lowercaseRegExp.test(passwordInputValue);
  //         const digitsPassword =      digitsRegExp.test(passwordInputValue);
  //         const specialCharPassword = specialCharRegExp.test(passwordInputValue);
  //         const minLengthPassword =   minLengthRegExp.test(passwordInputValue);
  //         let errMsg ="";
  //         if(passwordLength===0){
  //                 errMsg="Password is empty";
  //         }else if(!uppercasePassword){
  //                 errMsg="At least one Uppercase";
  //         }else if(!lowercasePassword){
  //                 errMsg="At least one Lowercase";
  //         }else if(!digitsPassword){
  //                 errMsg="At least one digit";
  //         }else if(!specialCharPassword){
  //                 errMsg="At least one Special Characters";
  //         }else if(!minLengthPassword){
  //                 errMsg="At least minumum 8 characters";
  //         }else{
  //             errMsg="";
  //         }
  //         setPasswordErr(errMsg);
  //     }

  //     // for confirm password
  //     if(passwordInputFieldName=== "confirmPassword" || (passwordInputFieldName==="password" && passwordInput.confirmPassword.length>0) ){
  //       if(passwordInput.confirmPassword!==passwordInput.password)
  //         {
  //           setConfirmPasswordError("Confirm password is not matched");
  //         }else{
  //           setConfirmPasswordError("");
  //         }
  //     }
  // }

  const handleDrpdwnClick = (e, incoming) => {
    // // console.log(incoming)
    setRegionCode(incoming);
    setRegion(false);
  };

  return (
    <div className="main-add-dir-container">
      <div className="bnw-addirector-pg">
        <form className="bnw-addirector-contain" onSubmit={handleSubmit}>
          <div className="bnw-direct-heading">
            <span>Add New Directors</span>
            <CgCloseO id="bnw-close-icon" onClick={closeConfirm} />
          </div>
          <div className="bnw-dirname-container">
            <input
              id="bnw-firname-box"
              placeholder="First Name"
              name="fname"
              value={firstname}
              onChange={handleChange}
              type="text"
            />
            <input
              id="bnw-firname-box"
              placeholder="Last Name"
              name="lname"
              value={lastname}
              onChange={handleChange}
              type="text"
            />
          </div>

          <div className="bnw-dirname-container">
            {/* <Select onChange={setTofield} options={ Regionsjson } className='bnw-regionfield' placeholder='Regions'/> */}
            <input
              value={regionCode.rn}
              onFocus={handleRegionFocus}
              placeholder="Regions"
              id="bnw-firname-box"
            />
            <FaChevronDown
              onClick={handleRegionFocus}
              className="bnw-chevrondown"
            />
            {region && (
              <div className="bnw-region-drpdwn">
                {regionsjson.map((item, key) => (
                  <span
                    onClick={(e) =>
                      handleDrpdwnClick(e, {
                        rc: item.region_code,
                        rn: item.name,
                      })
                    }
                    key={key}
                  >
                    {item.name}
                  </span>
                ))}
              </div>
            )}
          </div>

          <div className="bnw-dirname-container">
            <input
              id="bnw-firname-box"
              placeholder="Email"
              name="email"
              value={eemail}
              onChange={handleChange}
              type="email"
            />
          </div>

          {/* <div className='bnw-dirname-container'>            
              <PasswordInputField 
              handlePasswordChange={handlePasswordChange} 
              handleValidation={handleValidation} 
              passwordValue={passwordInput.password} 
              passwordError={passwordError}/>

              <ConfirmPasswordInputField 
              handlePasswordChange={handlePasswordChange} 
              handleValidation={handleValidation} 
              confirmPasswordValue={passwordInput.confirmPassword} 
              confirmPasswordError={confirmPasswordError}/>
            </div> */}

          <button id="bnw-submit-butn">SUBMIT</button>
        </form>
        {confirmload && <Loader type="spin" text="Sending Data..." />}
        {confirmresponse === 1 ? (
          <Error
            closeOverlay={handleClose}
            message={responses}
            icon="1"
            isTimeout={false}
          />
        ) : confirmresponse === 2 ? (
          <Error
            closeOverlay={handleClose}
            message={errrespnse}
            isTimeout={false}
            icon="2"
          />
        ) : confirmresponse === 3 ? (
          expiration ? (
            <Error
              message={"Session Expired Re-Login"}
              isTimeout={true}
              icon="3"
            />
          ) : (
            <Error
              closeOverlay={handleClose}
              message={"Connection Error"}
              isTimeout={false}
              icon="2"
            />
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default AddDirectors;
