// reducers.js
import { createAction, createReducer } from "@reduxjs/toolkit";

const fetchAgenciesRequest = createAction("fetchAgenciesRequest");
const fetchAgenciesSuccess = createAction("fetchAgenciesSuccess");
const fetchAgenciesFail = createAction("fetchAgenciesFail");

const initialState = {
  agenciesdata: [],
  isLoading: false,
  isAuthenticated: false,
};

export const agenciesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchAgenciesRequest, (state, action) => {
      state.isLoading = true;
    })

    .addCase(fetchAgenciesSuccess, (state, action) => {
      state.isAuthenticated = true;
      state.isLoading = false;
      state.agenciesdata = action.payload;
    })

    .addCase(fetchAgenciesFail, (state, action) => {
      state.isAuthenticated = false;
      state.isLoading = false;
      state.error = action.payload;
    });
});
