import React, { useState, useEffect } from "react";
import axios from "axios";
import { NodeServer_API } from "../server";
import { toast } from "react-toastify";
import { Store } from "../Redux/store";
import { loadingData } from "../Redux/Actions/buttonClick";
import ButtonPagenate from "./ButtonPagenate/ButtonPagenate";
import { useSelector } from "react-redux";
import { getAllProviders } from "../Redux/Actions/dataResponse";
import { useCookies } from "react-cookie";
// import Select from "react-select";

const Paymentprovider = ({ setloading }) => {
  const [cookies] = useCookies(["Token"]);
  const { privileges } = useSelector((state) => state.rolesData);

  const privilegeOptions = privileges?.map((privilege) => ({
    value: privilege.id,
    label: privilege.privilege_name,
  }));

  useEffect(() => {
    Store.dispatch(
      loadingData({
        status: false,
        text: "",
        type: "spin",
      })
    );
  }, []);

  // const [selectedPrivileges, setSelectedPrivileges] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const { providerlist } = useSelector((state) => state.datareponse);

  const newPagenum = (num) => {
    setPageNumber(num);
  };

  const itemsPerpage = 20; //Number of items to be displayed per page
  const pagesVisited = pageNumber * itemsPerpage;
  const pageCount = Math.ceil(
    (providerlist === null ? 0 : providerlist.length) / itemsPerpage
  );

  const handlePrivilegeChange = (e) => {
    setSelectedProvider(e.target.value);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    Store.dispatch(
      loadingData({
        status: true,
        text: "Creating Provider...",
        type: "spin",
      })
    );

    // Construct the payload
    const payload = {
      provider_name: selectedProvider,
    };

    try {
      var config = {
        method: "POST",
        url: `${NodeServer_API}/payment/provider`,
        data: payload,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      // Send POST request to update the user

      await axios(config).then((response) => {
        if (response.data.success === true) {
          Store.dispatch(
            loadingData({
              status: false,
              text: "",
              type: "spin",
            })
          );

          toast.success(response.data.message);
          Store.dispatch(getAllProviders(response.data.data));
        }
      });
    } catch (error) {
      Store.dispatch(
        loadingData({
          status: false,
          text: "",
          type: "spin",
        })
      );
      console.error("Error saving provider :", error.response.data);
    }
  };

  return (
    <div className="flex-1 flex-col flex gap-3">
      <form
        onSubmit={handleSave}
        className="flex-1 bg-[white] p-4 flex flex-col gap-3"
      >
        <div className="flex justify-center items-center">
          <h2 className="text-l font-bold mb-2">CREATE PAYMENT PROVIDER</h2>
        </div>

        <div className="flex-1 flex-wrap flex gap-2 items-center justify-between">
          <input
            name="Privilege Label"
            onChange={handlePrivilegeChange}
            className="text-[12px] border border-gray-300 py-2 px-3 w-[30%] min-w-[180px] outline-none hover:border-gray-500 focus:border-blue-500 rounded-sm grow"
            value={selectedProvider}
            type="text"
            required
            placeholder="Payment Provider"
          />
        </div>

        {/* <div className="flex-1 flex-wrap flex gap-2 items-center">
          <h3 className="text-[12px] font-medium w-[120px]">
            Assign Privileges :
          </h3>
          <Select
            options={bankingOptions}
            isMulti
            value={selectedPrivileges}
            onChange={handlePrivilegeChange}
            placeholder="Select MOMO"
            className="w-[30%] min-w-[150px] text-[12px] rounded-sm grow"
          />
        </div> */}

        <input
          disabled={
            privilegeOptions.find(
              (privilege) => privilege.label === "Create User"
            )?.label === "Create Provider"
              ? false
              : true
          }
          type="submit"
          className="btn btn-primary mt-4 text-[14px] font-semibold text-[white] py-2 rounded-sm bg-[#0743077d] hover:bg-[#074307f7] cursor-pointer"
          value={"SAVE CHANGES"}
        />
      </form>

      <div className="flex-1 bg-[white] p-3 flex flex-col gap-2">
        <table className="min-w-full border-collapse">
          <thead>
            <tr>
              <th className="text-[11px] whitespace-normal break-words border px-2 py-1">
                #SN
              </th>
              <th className="text-[11px] whitespace-normal break-words border px-2 py-1">
                Provider
              </th>
            </tr>
          </thead>

          <tbody>
            {providerlist
              .slice(pagesVisited, pagesVisited + itemsPerpage)
              .map((tdata, index) => (
                <tr className="body-tab-row cursor-pointer" key={tdata.id}>
                  <td
                    data-label="SN"
                    className="text-[11px] whitespace-normal break-words border px-2 py-1"
                  >
                    {index + 1}
                  </td>

                  <td
                    data-label="Provider"
                    className="text-[11px] whitespace-normal break-words border px-2 py-1"
                  >
                    {tdata.payment_provider}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        <div className="bnw-table-pgnate-contain">
          <ButtonPagenate
            updatedPageNum={newPagenum}
            pageCount={pageCount}
            pageNumber={pageNumber}
            previousLabel="Prev"
            nextLabel="Next"
          />
        </div>
      </div>
    </div>
  );
};

export default Paymentprovider;
